// types
import { createSlice } from '@reduxjs/toolkit'
// import { useSelector } from "react-redux";

// initial state
const initialState = {
  countriesList: [],
  statesList: [],
  category: '',
  findACouchFormDetails: {},
  globalSearchSelectValue: '',
}

// ==============================|| SLICE - Followers ||============================== //

const genericData = createSlice({
  name: 'genericData',
  initialState,
  reducers: {
    reset: () => initialState,
    setCountriesList(state, action) {
      state.countriesList = action.payload
    },
    setStatesList(state, action) {
      state.statesList = action.payload
    },

    getcategory(state, action) {
      // state.followersList = action.payload.followersList;
      return state.category
    },

    setcatgory(state, action) {
      console.log('Action palylod', action.payload)
      state.category = action.payload.category
    },
    setFindACouch(state, action) {
      state.findACouchFormDetails = action.payload
    },
    setGlobalSearchSelectValue(state, action) {
      state.globalSearchSelectValue = action.payload
    },
  },
})
// export const userData = useSelector((state)=>state.Users)
export default genericData.reducer

export const {
  setFindACouch,
  getcategory,
  setcatgory,
  setCountriesList,
  setStatesList,
  setGlobalSearchSelectValue,
  reset: resetGeneralData,
} = genericData.actions
