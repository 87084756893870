import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import InputField from '../../../../Components/inputField'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import { FormHelperText } from '@mui/material'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetShippingAddressFormik = ({
  setSaving,
  setSelection,

  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  const { countriesList, statesList } = useSelector(
    (state) => state.GeneralData
  )
  return (
    <Formik
      initialValues={{
        shippingAddressLine1Address: frProUser?.updateProfileForm
          ? frProUser?.updateProfileForm?.shippingAddress?.address
          : '',
        shippingAddressLine2Address:
          frProUser?.updateProfileForm?.shippingAddress?.address,
        country: frProUser?.updateProfileForm?.shippingAddress?.country,

        state: frProUser?.updateProfileForm?.shippingAddress?.state,

        city: frProUser?.updateProfileForm?.shippingAddress?.city,
        zip: frProUser?.updateProfileForm?.shippingAddress?.postalCode,
      }}
      validationSchema={Yup.object().shape({
        shippingAddressLine1Address: Yup.string().required(
          'Adress Line 1 is required'
        ),
        shippingAddressLine2Address: Yup.string().required(
          'Adress Line 2 is required'
        ),
        country: Yup.string().required('country is required'),
        state: Yup.string().required('state is required'),
        city: Yup.string().required('city is required'),
        zip: Yup.string().required('zip is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const shippingAddress = {
          shippingAddress: {
            ownerId: frProUser?.fre8ProUserId,
            profileId: frProUser?.fre8ProUserId,
            addressId: values?.shippingAddressLine1Address,
            address: values?.shippingAddressLine1Address,
            city: values?.city,
            coordinates: {
              lat: '',
              lng: '',
            },
            postalCode: values?.zip,
            state: values?.state,
            country: values?.country,
          },
        }

        try {
          const data = {
            ...frProUser?.updateProfileForm,
            fre8ProAccessToken: frProUser?.fre8ProAccessToken,
            ...shippingAddress,
          }
          console.log('data..........', data)
          ProfileApis.updateFleetOwnerUserProfile(data).then(() => {
            dispatch(
              setFrProUser({
                frProUser: {
                  ...frProUser,
                  updateProfileForm: {
                    ...frProUser?.updateProfileForm,
                    ...shippingAddress,
                  },
                },
              })
            )
          })
          setSaving(true)
        } catch (err) {
          console.log(err)
          let customMessage = 'Updating Shipping Address failed'
          let type = 'User Profile'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
        }
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="col-md-12">
            <label className="form-label">
              Address line 1 <span style={{ color: 'red' }}>*</span>
            </label>
            <InputField
              type={'text'}
              values={values}
              name={'shippingAddressLine1Address'}
              placeholder={'Plot no / Flat no / Apartment no/ House no'}
              touched={touched}
              errors={errors}
            />

            <div className="col-md-12">
              <label className="form-label">
                Address line 2 <span style={{ color: 'red' }}>*</span>
              </label>
              <InputField
                type={'text'}
                values={values}
                name={'shippingAddressLine2Address'}
                placeholder={'Plot no / Flat no / Apartment no/ House no'}
                touched={touched}
                errors={errors}
              />
            </div>
          </div>
          <div className="col-md-12">
            <label className="form-label">
              Country <span style={{ color: 'red' }}>*</span>
            </label>

            <select
              id="country"
              value={values?.country}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setFieldValue('country', e?.currentTarget?.value, {
                  shouldValidate: true,
                })
              }}
            >
              {!values?.country && (
                <option defaultValue="selected">Select menu</option>
              )}
              {countriesList?.map((country) => {
                return (
                  country?.countryName !== 'Mexico' && (
                    <option
                      key={country?.countryId}
                      value={country?.countryName}
                    >
                      {country?.countryName}
                    </option>
                  )
                )
              })}
            </select>

            {touched.country && errors.country && (
              <FormHelperText error id="helper-text-country">
                {errors.country}
              </FormHelperText>
            )}
          </div>
          <div className="col-md-12">
            <label className="form-label">
              State/Province <span style={{ color: 'red' }}>*</span>
            </label>

            <select
              id="state"
              value={values?.state}
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setFieldValue('state', e?.currentTarget?.value, {
                  shouldValidate: true,
                })
              }}
            >
              {!values?.state && (
                <option defaultValue="selected">Select country</option>
              )}
              {statesList[values?.country]?.map((state) => (
                <option key={state?.stateId} value={state?.stateName}>
                  {state?.stateName}
                </option>
              ))}
            </select>

            {touched.state && errors.state && (
              <FormHelperText error id="helper-text-state">
                {errors.state}
              </FormHelperText>
            )}
          </div>

          <div className="col-md-12">
            <label className="form-label">
              City <span style={{ color: 'red' }}>*</span>
            </label>

            <InputField
              tag={'select'}
              values={values}
              name={'city'}
              touched={touched}
              errors={errors}
            >
              {values.country ? '' : <option>Please select</option>}
              <option value={'Charlottetown'}>Charlottetown</option>
              <option value={'Edmonton'}>Edmonton</option>
              <option value={'Halifax'}>Halifax</option>
              <option value={'Iqaluit'}>Iqaluit</option>
              <option value={'Ottawa'}>Ottawa</option>
              <option value={'Quebec'}>Quebec City</option>
            </InputField>
          </div>

          <div className="col-md-12">
            <label className="form-label">
              ZIP <span style={{ color: 'red' }}>*</span>
            </label>
            <InputField
              tag={'select'}
              values={values}
              name={'zip'}
              touched={touched}
              errors={errors}
            >
              {values.nativeZone ? '' : <option>Please select</option>}
              <option value="C0A">C0A</option>
              <option value="T5J">T5J</option>
              <option value="E3A">E3A</option>
              <option value="E5R">E5R</option>
              <option value="S3A">S3A</option>
              <option value="H5E">H5E</option>
            </InputField>
            <div className="mt-4">
              <button type="submit" className="btn btn-theme me-2">
                Save
              </button>
              <button
                onClick={() =>
                  frProUser?.updateProfileForm?.shippingAddress?.address
                    ? setSaving(true)
                    : setSelection('')
                }
                type="button"
                className="btn btn-border"
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FleetShippingAddressFormik
FleetShippingAddressFormik.propTypes = {
  setSelection: PropTypes.func,
  setSaving: PropTypes.func,
  setToasts: PropTypes.func,
  selectedState: PropTypes.func,
  selectedCountry: PropTypes.func,
  states: PropTypes.array,
  handleCountryChange: PropTypes.func,
  handleStateChange: PropTypes.func,
  countries: PropTypes.array,
}
