import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const UsefulLinks = (props) => {
  const { t } = useTranslation()
  const { frProUser } = useSelector((state) => state.Users)

  return (
    <>
      <div
        className={props?.loading ? 'loading-skeleton col-lg-12' : 'col-lg-12'}
      >
        <div className="l-link-section ">
          <div className="lLink ">
            {/* <li style={{ display: "flex" }}>
              <Typography
                component={Link}
                to="/profile/Buddies"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                <i className="bi bi-person-plus"></i> {t("buddies")}{" "}
                <span className="badge text-bg-success ms-2">100</span>
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/profile/Followers"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                <i className="bi bi-person"></i> {t("followers")}{" "}
                <span className="badge text-bg-success ms-2">105</span>
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/profile/Following"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                <i className="bi bi-people"></i> {t("following")}{" "}
                <span className="badge text-bg-success ms-2">70</span>
              </Typography>
            </li>
            <li>
              <Typography
                component={Link}
                to="/profile/Messages"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                <i className="bi bi-chat-left-text"></i> {t("messages")}{" "}
                <span className="badge text-bg-success ms-2">05</span>
              </Typography>
            </li> 
             {<li>
              <Typography
                component={Link}
                to="/profile/meetup"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
                disabled={frProUser?.fre8ProStatus === "Standard" ? true : false}
              >
                <i className="bi bi-file-earmark-richtext"></i>{" "}
                {t("create_page")}{" "}
                <span className="badge text-bg-success ms-2">105</span> 
              </Typography>
            </li>
            */}
            {frProUser?.fre8ProStatus === 'Standard' && (
              <li>
                <Typography
                  component={Link}
                  className={
                    frProUser?.fre8ProStatus === 'Standard' ? 'disabled' : ' '
                  }
                  to="/profile/meetup"
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: 'none' }}
                >
                  <i className="bi bi-file-earmark-richtext"></i>
                  {t('create_page')}
                </Typography>
              </li>
            )}
            {/* <li>
              <Typography
                component={Link}
                to="/profile/Help"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: "none" }}
              >
                <i className="bi bi-info-circle"></i> {t("help")}{" "}
                <span className="badge text-bg-success ms-2"></span>
              </Typography>
            </li> */}
          </div>
        </div>
      </div>
    </>
  )
}

UsefulLinks.propTypes = {
  loading: PropTypes.bool,
}

export default UsefulLinks
