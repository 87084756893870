import PropTypes from 'prop-types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export default function PublishAPost(props) {
  const { t } = useTranslation()
  return (
    <div
      className={props?.componentVal === 'profile' ? 'col-lg-12' : 'col-lg-6'}
    >
      <div
        className={
          props?.loading
            ? 'loading-skeleton publish-section'
            : 'publish-section'
        }
      >
        <div className="top">
          <div className="c1">
            <li>
              <a href="">
                <i className="bi bi-image"></i>
                {t('photo')}
              </a>
            </li>
            <li>
              <a href="">
                <i className="bi bi-film"></i>
                {t('video')}
              </a>
            </li>
          </div>
          <div className="c2">
            <img src={props?.user?.image} alt="" />
          </div>
          <div className="c3">
            <li>
              <a href="">
                <i className="bi bi-calendar-week"></i>
                {t('event')}
              </a>
            </li>
            <li>
              <a href="">
                <i className="bi bi-emoji-smile"></i>
                {t('feeling')}
              </a>
            </li>
          </div>
        </div>
        <div className="bottom">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={t('share_your_thoughts')}
            />
            <button className="btn btn-publish">{t('publish')}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

PublishAPost.propTypes = {
  componentVal: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.object,
}
