// types
import { createSlice } from '@reduxjs/toolkit'

// initial state
const initialState = {
  followersList: null,
}

// ==============================|| SLICE - Followers ||============================== //

const followers = createSlice({
  name: 'followers',
  initialState,
  reducers: {
    reset: () => initialState,
    getFollowersList(state, action) {
      // state.followersList = action.payload.followersList;
      return state.followersList
    },
    setFollowersList(state, action) {
      state.followersList = action.payload.followersList
    },
  },
})

export default followers.reducer

export const {
  setFollowersList,
  getFollowersList,
  reset: resetFollowers,
} = followers.actions
