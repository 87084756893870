import React, { lazy } from 'react'

// project import
import Loadable from '../Components/Loadable'
import BusinessHomepage from '../Pages/business/businessHome'

const BusinessSignIn = Loadable(
  lazy(() => import('../Pages/authentication/businessSignIn'))
)

const BusinessSignUp = Loadable(
  lazy(() => import('../Pages/authentication/businessSignUp'))
)

const BusinessBuildProfilePage2 = Loadable(
  lazy(() => import('../Pages/business/businessBuildProfilePage2'))
)

const BusinessBuildProfilePage1 = Loadable(
  lazy(() => import('../Pages/business/businessBuildProfilePage1'))
)

const Business = Loadable(lazy(() => import('../Pages/business')))

const LoginLayout = Loadable(lazy(() => import('../layout/LoginLayout')))

const Verification = Loadable(lazy(() => import('../Pages/verification')))

const OTPScreenWrapper = Loadable(lazy(() => import('../Pages/otpScreen')))

const FirstTimeUser = Loadable(lazy(() => import('../Pages/firstTimeUser')))

const ResetPasswordVerificationCode = Loadable(
  lazy(() => import('../Pages/changePassword/resetPasswordVerificationCode'))
)

const ResetPassword = Loadable(
  lazy(() => import('../Pages/authentication/ResetPassword'))
)

const ForgotPassword = Loadable(
  lazy(() => import('../Pages/authentication/ForgotPassword'))
)

const BusinessCreatePassword = Loadable(
  lazy(() => import('../Pages/changePassword/businessCreatePassword'))
)

const CreatePasswordWrapper = Loadable(
  lazy(() => import('../Pages/changePassword'))
)

// ==============================|| AUTH ROUTING ||============================== //

const UnRegisteredUserRoutes = {
  path: '/',
  element: <LoginLayout />,
  children: [
    {
      path: '/business',
      // element: <AuthRegister useFormik={false}/>
      element: <Business />,
    },
    {
      path: '/firstTimeUser',
      element: <FirstTimeUser />,
    },
    {
      path: '/otpScreen',
      element: <OTPScreenWrapper />,
    },
    {
      path: '/createpassword',
      element: <CreatePasswordWrapper />,
    },
    {
      path: '/forgotpassword',
      element: <ForgotPassword />,
    },
    {
      path: '/resetpassword',
      element: <ResetPassword />,
    },
    {
      path: '/resetpassword/:id',
      element: <ResetPassword />,
    },
    {
      path: '/resetpasswordVerificationCode',
      element: <ResetPasswordVerificationCode />,
    },

    {
      path: '/businesscreatepassword',
      element: <BusinessCreatePassword />,
    },

    {
      path: '/businesssignin',
      element: <BusinessSignIn />,
    },
    {
      path: '/businessHome',
      element: <BusinessHomepage />,
    },
    {
      path: '/businesssignup',
      element: <BusinessSignUp />,
    },
    {
      path: '/businessbuildprofilepage1',
      element: <BusinessBuildProfilePage1 />,
    },
    {
      path: '/businessbuildprofilepage2',
      element: <BusinessBuildProfilePage2 />,
    },
    {
      path: '/verificationcode',
      element: <Verification />,
    },
    {
      path: '/resetpasswordverificationcode',
      element: <ResetPasswordVerificationCode />,
    },
  ],
}

export default UnRegisteredUserRoutes
