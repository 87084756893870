import { useEffect } from 'react'
import ErrorHandle from '../Components/ErrorHandle'
import { useDispatch, useSelector } from 'react-redux'
import { LoginApis } from '../API/Login/LoginApi'
import {
  setCountriesList,
  setStatesList,
} from '../store/reducers/GenericData/generalData'

const useCountryAndStatesHook = (setToasts) => {
  const { countriesList, statesList } = useSelector(
    (state) => state.GeneralData
  )
  const dispatch = useDispatch()

  const getCountries = () => {
    LoginApis.getCountries()
      .then((res) => {
        // setcountries(res?.data)

        dispatch(setCountriesList(res.data))
        // setisCountriesLoaded(true)
      })
      .catch((err) => {
        console.log(':VAlues from getcountries', err)
        let customMessage = 'countries load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  const getStateList = async () => {
    if (!countriesList?.length) return
    const stateListObject = {}
    const fetchPromises = countriesList?.map(async (country) => {
      try {
        const res = await LoginApis.getStates(country?.countryId)
        return { countryName: country?.countryName, states: res?.data }
      } catch (err) {
        ErrorHandle(
          err,
          setToasts,
          'States load failed',
          'User Information',
          'red'
        )
      }
    })

    const results = await Promise.all(fetchPromises)
    results.forEach((result) => {
      if (result) {
        stateListObject[result.countryName] = result.states
      }
    })

    dispatch(setStatesList(stateListObject))
  }
  useEffect(() => {
    if (statesList?.length !== 0) return
    if (countriesList?.length) {
      getStateList()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesList?.length])

  useEffect(() => {
    if (countriesList?.length !== 0) return
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return
}

export default useCountryAndStatesHook
