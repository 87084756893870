import React from 'react'
import PropTypes from 'prop-types'

const FleetPlusButtonCode = ({ setSelection, data }) => {
  const obj = {
    FleetBusinessSummary: [
      'Business summary',
      'Add business name, location and whether you are a independent owner',
    ],
    FleetContact: ['Contact', 'Add contact information for your business'],
    FleetBusinessOwnerShip: [
      'Business ownership',
      'Add business owner or best contact if you’re not the business owner',
    ],
    FleetDocumentation: [
      'Documentation',
      'Add documentation relating to your business',
    ],
    FleetServices: [
      'Services offered',
      'Add information relating to the services that you offer',
    ],
    FleetShippingAdress: [
      'Shipping address',
      'Add information relating to shipping address and billing address',
    ],
  }

  return (
    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
      <h5 className="fw-bold mb-3">{obj[data][0]}</h5>

      <div className="text-center">
        <p className="text-muted">{obj[data][1]}</p>
        <button onClick={() => setSelection(data)} className="btn-upload ">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 5V19M5 12H19"
              stroke="#005A70"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default FleetPlusButtonCode
FleetPlusButtonCode.propTypes = {
  setSelection: PropTypes.func,
  data: PropTypes.string,
}
