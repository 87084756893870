import axios from 'axios'

// let window;
export const api = axios.create({
  baseURL: 'https://test-fre8pro-backend.fre8prodemo.com/fre8pro-backend/',
  // baseURL: 'https://dev-fre8pro-backend.varahisoft.in/fre8pro-backend/',
  // baseURL: 'https://dev-fre8pro-backend.varahisoft.in/',
})
// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate()
// defining a custom error handler for all APIs

const errorHandler = (error) => {
  const statusCode = error.response?.status

  if (
    (error?.response?.data?.errorDetail?.status === 500 ||
      error?.response?.data?.httpStatusCode === 500 ||
      error?.response?.status === 500) &&
    (error?.response?.data?.errorDetail?.developerMessage ===
      'getLastAccessedTime: Session already invalidated' ||
      error?.response?.data?.errorDetail?.developerMessage ===
      'Session object not found ')
  ) {
    window.location.href = '/'
  }

  if (error.code === 'ERR_CANCELED') {
    console.error('API canceled!')
    return Promise.resolve()
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  if (error && error?.code === 'ERR_BAD_REQUEST') {
    let absc = error?.response?.data?.errorDetail?.developerMessage?.includes(
      'Session already invalidated'
    )
    if (absc) {
      // navigate("/error");
      sessionStorage.setItem('userSession', JSON.stringify('Invalid Session'))
      // document?.location?.url("/error")
    }
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})
