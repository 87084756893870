import React from 'react'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import propTypes from 'prop-types'
import CustomPencilSvg from '../../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetContactView = ({
  setSaving,
  setSelection,
  deleteFleetContactData,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)

  return (
    <div className="mb-4  shadow-sm rounded-4 bg-white">
      <div className="d-flex justify-content-between px-4 pt-4">
        <h5 className="fw-bold">Contact</h5>
        <div>
          <button onClick={() => setSaving(false)} className=" p-0">
            <CustomPencilSvg />
          </button>
          <button
            onClick={() => {
              ProfileApis.updateFleetOwnerUserProfile(deleteFleetContactData)
                .then(() => {
                  dispatch(
                    setFrProUser({
                      frProUser: {
                        ...frProUser,
                        updateProfileForm: {
                          ...frProUser?.updateProfileForm,
                          ...deleteFleetContactData,
                        },
                      },
                    })
                  )
                })
                .catch((err) => {
                  console.log(err)
                  let customMessage = 'Deleting Fleet contact failed'
                  let type = 'User Profile'
                  let color = 'red'
                  ErrorHandle(err, setToasts, customMessage, type, color)
                })
              setSelection('')
            }}
          >
            <CustomDeleteSvg />
          </button>
        </div>
      </div>
      <div className="p-4 pt-0">
        <dl className="row business_list mb-0 gy-2">
          <dt className="col-sm-3">Primary email</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.primaryEmail
              ? frProUser?.updateProfileForm?.primaryEmail
              : ''}
          </dd>
          <dt className="col-sm-3">Primary mobile number</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.primaryMobileNumber
              ? frProUser?.updateProfileForm?.primaryMobileNumber
              : ''}
          </dd>
          <dt className="col-sm-3">Secondary mobile number</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.secondaryMobileNumber
              ? frProUser?.updateProfileForm?.secondaryMobileNumber
              : ''}
          </dd>
          <dt className="col-sm-3">Website</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.website
              ? frProUser?.updateProfileForm?.website
              : ''}
          </dd>
          <dt className="col-sm-3">Country</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.billingAddress?.country
              ? frProUser?.updateProfileForm?.billingAddress?.country
              : ''}
          </dd>
          <dt className="col-sm-3">State</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.billingAddress?.state
              ? frProUser?.updateProfileForm?.billingAddress?.state
              : ''}
          </dd>
          <dt className="col-sm-3">City</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.billingAddress?.city
              ? frProUser?.updateProfileForm?.billingAddress?.city
              : ''}
          </dd>
          <dt className="col-sm-3">Zip</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.billingAddress?.postalCode
              ? frProUser?.updateProfileForm?.billingAddress?.postalCode
              : ''}
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default FleetContactView
FleetContactView.propTypes = {
  setSelection: propTypes.func,
  setSaving: propTypes.func,
  deleteFleetContactData: propTypes.object,
  setToasts: propTypes.func,
}
