// types
import { createSlice } from '@reduxjs/toolkit'

// initial state
const initialState = {
  buddiesList: null,
}

// ==============================|| SLICE - Followers ||============================== //

const buddies = createSlice({
  name: 'buddies',
  initialState,
  reducers: {
    reset: () => initialState,
    setBuddiesList(state, action) {
      state.buddiesList = action.payload.buddiesList
    },
  },
})

export default buddies.reducer

export const { setBuddiesList, reset: resetBuddies } = buddies.actions
