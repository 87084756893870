// import { Box, Container } from "@mui/material";
import PropTypes from 'prop-types'
import React from 'react'
import StandardImaCustomMessagesList from './CustomMessages'
// import { useTranslation } from "react-i18next";
// import ProfilepageTabs from "../ProfilePage_new";
// import CustomMessages from "./CustomMessages";

const CustomMessagesWrapper = (props) => {
  // const { t } = useTranslation();
  return (
    <>
      {/* <Container item>
        {props?.showProfileHeader && <ProfilepageTabs />}
        <Box
          className="photos row l-photo-section"
          style={{ display: "flex", flexDirection: "column", padding: 12 }}
        >
          <div className="heading">
            <div className="title">{t("photos")}</div>
            <a href="">{t("see_all_photos")}</a>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
            <CustomMessages />
          </div>
        </Box>
      </Container> */}
      {/* <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="notification">
              <div className="title">
                Messages <span className="badge bg-orange ms-2">5 new</span>
              </div>
              <hr />
              <div className="nBar mb-3">
                <div className="img">
                  <img src="images/user-img1.jpg" alt="" />
                </div>
                <div>
                  <div className="name">
                    <b>Ompreet Khosla</b>
                  </div>
                  <p>Hello! How are you.</p>
                </div>
                <div className="time ms-auto">1hrs</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <StandardImaCustomMessagesList />
    </>
  )
}

CustomMessagesWrapper.propTypes = {
  showProfileHeader: PropTypes.bool,
}

export default CustomMessagesWrapper
