import { api } from '../Configs/AxiosConfigs'
import { defineCancelApiObject } from '../Configs/AxiosUtils'

export const UsersApis = {
  userBuddyRequestReceivedBuddyLive: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/receive/buddylive`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  userBuddyRequestReceived: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/receive`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  userBuddyRequestApproved: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/approve`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userBuddyRequestSend: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/send`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userBuddyRequestDenied: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/deny`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userBuddyRequestUnBuddy: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/unbuddy`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userBuddyRequestBlocked: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/buddy/request/block`,
      method: 'PUT',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userGetBuddy: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/buddy`,
      method: 'GET',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  userGetNotification: async function (userObj, cancel = false) {
    const response = await api.request({
      url: `/v1/notification`,
      method: 'POST',
      headers: {},
      data: userObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },

  getUserProfileOfOtherUser: async function (userObj, cancel = false) {
    let accesToken = {
      fre8ProAccessToken: userObj?.fre8ProAccessToken,
    }
    const response = await api.request({
      url: `/v1/profile/${userObj?.userId}`,
      method: 'POST',
      headers: {},
      data: accesToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    
    return response.data
  },
}
// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UsersApis)
