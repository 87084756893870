import React, { lazy } from 'react'

// project import
import Checking from '../Components/Custom Checking/Checking'
import Loadable from '../Components/Loadable'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Messages from '../Pages/Dashboard/Messages'
import Notifications from '../Pages/Dashboard/Notifications'
// import SearchResults from "../Pages/Dashboard/SearchResults";
import SearchResults from '../Pages/Dashboard/SearchResults'
import Error from '../Pages/Error/Error'
import CustomMessagesWrapper from '../Pages/Messages/CustomMessagesWrapper'
import CustomNotificationsWrapper from '../Pages/Notifications/CustomNotificationsWrapper'
import BusinessProfilePage from '../Pages/ProfilePage_new/BusinessProfile'
import BusinessUpdateProfile from '../Pages/ProfilePage_new/BusinessUpdateProfile'
// import Driverbuddyprofile from "../Pages/ProfilePage_new/Driverbuddyprofile";
// import FleetOwnerBuddyProfile from "../Pages/ProfilePage_new/FleetOwnerProfile/FleetOwnerBuddyProfile";
// import OwnerOperatorBuddiedProfile from "../Pages/ProfilePage_new/OwnerOperatorBuddiesProfile";
import CustomSearchFriendWrapper from '../Pages/SearchFriend/CustomSearchFriendWrapper'

// import Checking from "../Components/Custom Checking/Checking";

const MainLayout = Loadable(lazy(() => import('../layout/MainLayout')))

const Feed = Loadable(lazy(() => import('../Pages/Feed')))

const FleetOwnerBuddyProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/FleetOwnerProfile/FleetOwnerBuddyProfile'))
)
const Driverbuddyprofile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/Driverbuddyprofile'))
)
const OwnerOperatorBuddiedProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OwnerOperatorBuddiesProfile'))
)
const UpdateProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/updateProfile'))
)
const OnwerUpdateProfile = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OoupdateProfile.jsx'))
)
const Profilepage = Loadable(lazy(() => import('../Pages/ProfilePage_new/Profile')))
const OwnerProfilepage = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/OoProfile.jsx'))
)
const CustomGalleryWrapper = Loadable(
  lazy(() => import('../Components/Custom Image Gallery/CustomGalleryWrapper'))
)
const BuddiesWrapper = Loadable(lazy(() => import('../Pages/Buddies/BuddiesWrapper')))
const FollowersWrapper = Loadable(
  lazy(() => import('../Pages/Followers/FollowersWrapper'))
)
const FollowingWrapper = Loadable(
  lazy(() => import('../Pages/Following/FollowingWrapper'))
)
const MeetupWrapper = Loadable(lazy(() => import('../Pages/Meetup')))
const NewBuddiesPage = Loadable(lazy(() => import('../Pages/Dashboard/NewBuddiesPage')))

const AccountSettingsPage = Loadable(
  lazy(() => import('../Pages/ProfilePage_new/AccountSettings.jsx'))
)

const MembershipPage = Loadable(lazy(() => import('../Pages/frightUI/Membership.js')))
const FreightJobsPage = Loadable(lazy(() => import('../Pages/jobs/index.jsx')))
const FreightEventsPage = Loadable(
  lazy(() => import('../Pages/frightUI/FreightEvents.js'))
)

const FreightEventsDetailPage = Loadable(
  lazy(() => import('../Pages/frightUI/FreightEventsDetail.js'))
)

const CreateFreightEventPage = Loadable(
  lazy(() => import('../Pages/frightUI/createFreightEvent.js'))
)

const EditFreightEventPage = Loadable(
  lazy(() => import('../Pages/frightUI/createFreightEvent.js'))
)

const CouchServicesHostPage = Loadable(
  lazy(() => import('../Pages/couchServicesHost/index.jsx'))
)

const create = 'create'
const edit = 'edit'
const ProfileRoutes = {
  // path: "/",
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/feed',
      element: <Feed />,
    },
    {
      path: '/feed/view/:encryptedlink',
      element: <Feed />,
    },
    {
      path: '/searchresultspage',
      element: <SearchResults />,
    },
    {
      path: '/notificationspage',
      element: <Notifications />,
    },
    {
      path: '/messagespage',
      element: <Messages />,
    },
    {
      path: '/buddiespage',
      element: <NewBuddiesPage />,
    },
    {
      path: '/profile',
      element: <Profilepage />,
    },
    {
      path: '/ownerprofile',
      element: <OwnerProfilepage />,
    },
    {
      path: '/viewprofile',
      element: <Profilepage />,
    },
    {
      path: '/error',
      element: <Error />,
    },
    {
      path: '/businessprofile',
      element: <BusinessProfilePage />,
    },
    {
      path: '/updateprofile',
      element: <UpdateProfile />,
    },
    {
      path: '/ownerupdateprofile',
      element: <OnwerUpdateProfile />,
    },
    {
      path: '/businessupdateprofile',
      element: <BusinessUpdateProfile />,
    },
    {
      path: '/fleetownerupdateprofile',
      element: <fleetownerupdateprofile />,
    },
    {
      path: '/profile/buddies',
      element: <BuddiesWrapper />,
    },
    {
      path: '/profile/gallery',
      element: <CustomGalleryWrapper showProfileHeader={true} />,
    },
    {
      path: '/profile/followers',
      element: <FollowersWrapper />,
    },
    {
      path: '/profile/following',
      element: <FollowingWrapper />,
    },
    {
      path: '/profile/meetup',
      element: <MeetupWrapper />,
    },
    {
      path: '/profile/messages',
      element: <CustomMessagesWrapper />,
    },
    {
      path: '/profile/Notifications',
      element: <CustomNotificationsWrapper />,
    },
    {
      path: '/profile/Notifications',
      element: <CustomNotificationsWrapper />,
    },
    {
      path: '/profile/SearchFriend',
      element: <CustomSearchFriendWrapper />,
    },
    // {
    //   path: "/fleetownerprofile",
    //   element: <FleetOwnerProfile />,
    // },
    {
      path: `/owneroperatorbuddiesprofile/:id`,
      element: <OwnerOperatorBuddiedProfile />,
    },
    {
      path: `/driverbuddyprofile/:id`,
      element: <Driverbuddyprofile />,
    },
    {
      path: `/fleetownerbuddyprofile/:id`,
      element: <FleetOwnerBuddyProfile />,
    },
    {
      path: '/checking',
      element: <Checking />,
    },
    {
      path: '/accountsettings',
      element: <AccountSettingsPage />,
    },
    {
      path: '/membership',
      element: <MembershipPage />,
    },

    {
      path: '/jobs',
      element: <FreightJobsPage />,
    },
    {
      path: '/jobs/:currentTab',
      element: <FreightJobsPage />,
    },
    {
      path: '/FreightEvents',
      element: <FreightEventsPage />,
    },
    {
      path: '/FreightEventsDetail',
      element: <FreightEventsDetailPage />,
    },

    {
      path: '/createFreightEvent',
      element: <CreateFreightEventPage mode={create} />,
    },

    {
      path: '/editFreightEvent',
      element: <EditFreightEventPage mode={edit} />,
    },
    {
      path: '/couchServicesHost/:currentTab',
      element: <CouchServicesHostPage />,
    },
    {
      path: '/couchServicesHost',
      element: <CouchServicesHostPage />,
    },
  ],
}

export default ProfileRoutes
