import React from 'react'
import { useTranslation } from 'react-i18next'
// import { CellMeasurerCache } from 'react-virtualized'
import PostsCard from '../cards/differentCards/PostsCard'

const CustomCommentsCardWrapper = (props) => {
  // const cache = React.useRef(
  //   // eslint-disable-line
  //   new CellMeasurerCache({
  //     fixedWidth: true,
  //     // defaultHeight: 300
  //   })
  // ) // eslint-disable-line
  let virtual = false // eslint-disable-line
  let posts = null
  let item = undefined
  const { t } = useTranslation()

  const loader = (
    <>
      <div className={'loading-skeleton '} key={props?.key}>
        {/* <!-- Post section start --> */}
        <div className="col-lg-12">
          <div className="post-section">
            <div className="p-header">
              <div className="left">
                <img
                  src={`${item?.localPath}?w=96&h=96&fit=crop&auto=format&dpr=2 2x`}
                  alt=""
                />
                <span>{item?.userName}</span>
              </div>
              <div className="right">
                <li className="text"></li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-three-dots"></i>
                  </a>
                </li>
              </div>
            </div>
            <p>{item?.body}</p>
            <div className="p-body">
              <div className="row g-0">
                <div className="col-lg-10">
                  {(item?.link === false || item?.id !== 2) && (
                    <img src={item?.localPath} className="img-fluid" alt="" />
                  )}
                  {(item?.link === true || item?.id === 2) && (
                    <iframe
                      width="100%"
                      height="480px"
                      src="https://www.youtube.com/embed/v0VGNi0r9Mo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
                <div className="col-lg-2">
                  <div className="content">
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-heart"></i>
                      </a>
                      <span>
                        {/* {t("like")}{" "} */}
                        {item?.reactions && item?.reactions[0]?.like}
                      </span>
                    </li>
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-chat"></i>
                      </a>
                      <span>
                        {/* {t("comment")}{" "} */}
                        {item?.reactions && item?.reactions[1]?.comments}
                      </span>
                    </li>
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-share"></i>
                      </a>
                      <span>
                        {/* {t("share")}{" "} */}
                        {item?.reactions && item?.reactions[2]?.share}
                      </span>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-footer">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  // placeholder={t("comment_here")}
                />
                <button className="btn btn-post">{t('post')}</button>
              </div>
              <div className="icon">
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-emoji-smile"></i>
                    {t('emoji')}
                  </a>
                </li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-heart"></i>
                    {t('like')}
                  </a>
                </li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-share"></i>
                    {t('share')}
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Post section end --> */}
      </div>
    </>
  )

  posts =
    props?.postsData?.length > 0
      ? props?.postsData?.map((post, index) => {
          return <PostsCard key={post?.id + '_' + index} postsData={post} />
        })
      : loader

  return posts

  // TODO: Do'nt delete
  //  Virtualized approach
  // return (<div style={{ width: "100%", height: "1600px" }}>
  //   <AutoSizer>
  //     {({ width, height }) => (
  //       < List
  //         width={width}
  //         height={height}
  //         rowHeight={cache.current.rowHeight}
  //         deferredMeasurementCache={cache.current}
  //         rowCount={props?.postsData?.length}
  //         rowRenderer={(postObj, index, style, parent, isScrolling) => {
  //           const post = props?.postsData[postObj?.index]
  //           console.log("testUser react virtualized", props?.postsData?.length, "key", postObj, "style", style, "index", index, "parent", parent)
  //           return <CellMeasurer key={postObj?.key} cache={cache?.current} parent={postObj?.parent} columnIndex={0} rowIndex={postObj?.index}><PostsCard postsData={post} style={postObj?.style} /></CellMeasurer>
  //         }}
  //       />)}
  //   </AutoSizer>

  // </div>)
  // const rowRendererFunc = ({ index, isScrolling, key, style, parent }) => {
  //   let post = props?.postsData?.posts[index]
  //   console.log("testUser::::postobj", post, index, isScrolling, key, style)
  //   return (
  //     <div className='infinite-scroll-row' key={key} style={style}>
  //       <CellMeasurer key={key} cache={cache?.current} parent={parent} columnIndex={0} rowIndex={index}><PostsCard postsData={post} style={style} /></CellMeasurer>
  //     </div>
  //   );
  // };
  // return (
  //   <AutoSizer>
  //     {({ width, height }) => (
  //       <List className='infinite-scroll-list' width={width} height={height}
  //         rowCount={this.list.length} rowHeight={100}
  //         rowRenderer={rowRendererFunc}
  //       />)}
  //   </AutoSizer>
  // );
}

export default CustomCommentsCardWrapper
