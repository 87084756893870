// types
import { createSlice } from '@reduxjs/toolkit'

// initial state
const initialState = {
  postsList: null,
}

// ==============================|| SLICE - Followers ||============================== //

const posts = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    reset: () => initialState,
    getPostsList(state, action) {
      // state.followersList = action.payload.followersList;
      return state.postsList
    },
    setPostsList(state, action) {
      console.log('Action palylod', action.payload)
      state.postsList = action.payload.postsList
    },
  },
})

export default posts.reducer

export const { setPostsList, getPostsList, reset: resetPosts } = posts.actions
