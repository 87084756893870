import React from 'react'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import propTypes from 'prop-types'
import CustomPencilSvg from '../../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetBusinessOwnershipView = ({
  setSaving,
  setSelection,
  deleteFleetOwnerShipData,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)

  return (
    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
      <div className="d-flex justify-content-between">
        <h5 className="fw-bold mb-3">Business ownership</h5>
        <div>
          <button onClick={() => setSaving(false)} className=" p-0">
            <CustomPencilSvg />
          </button>
          <button
            onClick={() => {
              ProfileApis.updateFleetOwnerUserProfile(deleteFleetOwnerShipData)
                .then(() => {
                  dispatch(
                    setFrProUser({
                      frProUser: {
                        ...frProUser,
                        updateProfileForm: {
                          ...frProUser?.updateProfileForm,
                          ...deleteFleetOwnerShipData,
                        },
                      },
                    })
                  )
                })
                .catch((err) => {
                  console.log(err)
                  let customMessage = 'User  Fleet contact delete failed'
                  let type = 'User Profile'
                  let color = 'red'
                  ErrorHandle(err, setToasts, customMessage, type, color)
                })

              setSelection('')
            }}
          >
            <CustomDeleteSvg />
          </button>
        </div>
      </div>

      <dl className="row business_list mb-0 gy-2">
        <dt className="col-sm-3">Owner </dt>
        <dd className="col-sm-9">
          {frProUser?.updateProfileForm?.youOwnerOfTheFleet === 'Yes'
            ? frProUser?.updateProfileForm?.firstName
            : 'No Shared'}
        </dd>
        <dt className="col-sm-3">Independent owner?</dt>
        <dd className="col-sm-9">
          {frProUser?.updateProfileForm?.youOwnerOfTheFleet}
        </dd>
      </dl>
      <h6 className="fw-bold mb-3 py-3">
        {frProUser?.updateProfileForm?.youOwnerOfTheFleet === 'Yes'
          ? 'Owner personale details'
          : 'Non-owner personale details'}
      </h6>

      <dl className="row business_list mb-0 gy-2">
        <dt className="col-sm-3">Name </dt>
        <dd className="col-sm-9">
          {frProUser?.updateProfileForm?.firstName}{' '}
          {frProUser?.updateProfileForm?.lasttName}
        </dd>
        <dt className="col-sm-3">Designation</dt>
        <dd className="col-sm-9">
          {frProUser?.updateProfileForm?.designation}
        </dd>
      </dl>
    </div>
  )
}

export default FleetBusinessOwnershipView

FleetBusinessOwnershipView.propTypes = {
  setSelection: propTypes.func,
  setSaving: propTypes.func,
  deleteFleetOwnerShipData: propTypes.object,
  setToasts: propTypes.func,
}
