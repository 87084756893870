import { persiststore } from '../../store//reducers/persist'
import { api } from '../Configs/AxiosConfigs'
import { defineCancelApiObject } from '../Configs/AxiosUtils'

const state = persiststore.getState()

export const LoginApis = {
  signIn: async function (username, password, cancel = false) {
    console.debug(`/v1/signin`, api, password, username)
    const response = await api.request({
      url: `/v1/signin`,
      method: 'POST',
      headers: {},
      data: {
        userName: username, // This is the body part
        password: password,
        // "fre8ProAccessToken": token
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'response',
      response,
      document.cookie,
      ':::Fre8Pro-Login:::',
      response?.headers?.get('Fre8Pro-Login')
    )

    return response.data
  },
  businessSignIn: async function (username, password, cancel = false) {
    console.debug(`/v1/signin/business`, api, password, username)
    const response = await api.request({
      url: `/v1/signin/business`,
      method: 'POST',
      headers: {},
      data: {
        userName: username, // This is the body part
        password: password,
        // "fre8ProAccessToken": token
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'response',
      response,
      document.cookie,
      ':::Fre8Pro-Login:::',
      response?.headers?.get('Fre8Pro-Login')
    )

    return response.data
  },
  updateUserEmail: async function (emailObj, cancel = false) {
    console.debug('update useremail', state?.Users)
    const response = await api.request({
      url: `/v1/user/email`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  updateUserMobileNumber: async function (numberObj, cancel = false) {
    console.debug('update usermobile', state?.Users, 'Updated objecty   ', {
      ...numberObj,
      fre8ProAccessToken: state?.Users?.authorizationToken,
    })
    const response = await api.request({
      url: `/v1/user/mobile`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: numberObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  userIsAvailable: async function (username, token, cancel = false) {
    const response = await api.request({
      url: `/v1/availableUser`,
      method: 'POST',
      headers: {},
      data: {
        mobileNumber: username?.mobileNumber,
        email: username?.email,
        fre8ProUserId: username?.fre8ProUserId,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  emailIsAvailable: async function (emailObj, cancel = false) {
    const response = await api.request({
      url: `/v1/availableEmail`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },

  mobileNumberIsAvailable: async function (emailObj, cancel = false) {
    const response = await api.request({
      url: `/v1/availableMobile`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  createUser: async function (objectUser, token, cancel = false) {
    const response = await api.request({
      url: `/v1/user`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: objectUser,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'createUser',
      response,
      document.cookie,
      '::: Fre8Pro - Login::: ',
      response?.headers?.get('Fre8Pro-Login')
    )
    return response.data
  },
  createFleetBusinessUser: async function (objectUser, token, cancel = false) {
    const response = await api.request({
      url: `/v1/user/business`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: objectUser,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // console.debug("response", response)
    console.debug(
      'createUser',
      response,
      document.cookie,
      '::: Fre8Pro - Login::: ',
      response?.headers?.get('Fre8Pro-Login')
    )
    return response.data
  },
  logout: async function (logoutData, cancel = false) {
    const response = await api.request({
      url: `/v1/signout`,
      method: 'POST',
      headers: {},
      data: logoutData,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getCountries: async function (cancel = false) {
    const response = await api.request({
      url: `/v1/countries`,
      method: 'GET',
      headers: {},
      data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getStates: async function (countryId, cancel = false) {
    const response = await api.request({
      url: `/v1/states/${countryId}`,
      method: 'GET',
      headers: { Accept: 'application/json, text/plain, */*' },
      data: {
        countryId: countryId,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response', response)

    return response.data
  },
  getCaptcha: async function (token, cancel = false) {
    console.debug('testUser:::Token:: get captch', token)
    const response = await api.request({
      url: `/v1/captcha/image`,
      method: 'POST',
      headers: {},
      // headers: { "Authorization": `JSESSIONID:` + token },
      // headers: { "JSESSIONID": token },
      data: { fre8ProAccessToken: token },
      // data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response captcha', response, document.cookie, token)

    return response.data
  },
  refreshCaptcha: async function (token, cancel = false) {
    console.debug('testUser:::Token rfefresh captch::', token)
    const response = await api.request({
      url: `/v1/captcha/image/refresh`,
      method: 'POST',
      headers: { Accept: 'image/jpeg, application/json, text/plain, */*' },
      // data: {},
      data: { fre8ProAccessToken: token },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug(
      'refresh captcha',
      response,
      document.cookie,
      ':::Fre8Pro-token:::',
      token
    )
    // for (let header of response.headers) {
    //     console.debug('refresh captcha for loop', header);
    // }
    return response.data
  },
  forgotPassword: async function (passwordObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/forgotpassword`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: passwordObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('response captcha', response)

    return response.data
  },
  getToken: async function (cancel = false) {
    const response = await api.request({
      url: `/v1/token`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      data: {},
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('Get Token', response)

    return response.data
  },
  searchFriend: async function (searchObj, cancel = false) {
    console.debug('::searchFriend:: searchObj ', searchObj)
    const response = await api.request({
      url: `/v1/user/search`,
      method: 'POST',
      headers: {},
      data: searchObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    // fetchsearchFriendImage(response?.data?.data?.userList)

    // await Promise.all(response?.data?.data?.userList?.map(friend => loadImage(friend, searchObj)))
    //     .then(() => { console.debug("loaded images") })
    //     .catch(err => console.debug("Failed to load images", err))

    console.debug(' searchFriend Data', response)
    return response.data
  },
  fetchsearchFriendImage: async function (searchObj, cancel = false) {
    const response = await api.request({
      url: searchObj?.url,
      method: 'POST',
      headers: {},
      data: searchObj?.accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    console.debug('fetchsearchFriendImage ', response)

    return response.data
  },
}

// const loadImage = (friend, searchObj) => {
//     return new Promise((resolve, reject) => {

//         let objSearchImg = {
//             url: friend?.userProfileImgUrl?.split('/fre8pro-backend')[1],
//             accessToken: {
//                 "fre8ProAccessToken": searchObj?.fre8ProAccessToken,
//             }
//         }
//         LoginApis.fetchsearchFriendImage(objSearchImg).then((res) => {
//             // console.debug('load image search', res)
//             friend.userProfileImgUrlData = res || ''
//             console.debug('updatedg user data', friend)

//             setTimeout(() => {
//                 resolve(friend?.userProfileImgUrlData)
//             }, 100)
//         }).catch((err) => {
//             resolve(friend?.userProfileImgUrlData)
//         })

//     })
// }

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(LoginApis)
