import React, { lazy } from 'react'

// project import
import Loadable from '../Components/Loadable'
// import FleetDashboard from "../Pages/authentication/FleetDashboard";
import Error from '../Pages/Error/Error'

// const Profilepage = Loadable(
//   lazy(() => import("../Pages/ProfilePage_new/Profile"))
// );
// const HomepageNew = Loadable(lazy(() => import("../Pages/Homepage_new")));

// const MembershipPlan = Loadable(lazy(() => import("../Pages/membershipPlan")));

const MainLayout = Loadable(lazy(() => import('../layout/MainLayout')))

// ==============================|| MAIN ROUTING ||============================== //

const ErrorRoutes = {
  // path: "/",
  element: <MainLayout />,
  children: [
    {
      path: '/error',
      element: <Error />,
    },
  ],
}

export default ErrorRoutes
