import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
import CustomLiveUserCardWrapper from '../../Components/Custom User Card/CustomLiveUserCardWrapper'
import CustomCommentsCardWrapper from '../../Components/CustomCommentsCard/CustomCommentsCardWrapper'
import PublishAPost from '../../Components/Post/publishAPost'
import UsefulLinksWrapper from '../../Components/Useful Links/UsefulLinksWrapper'
// import UserAccountCard from "../../Components/cards/differentCards/userAccountCard";
// import { dispatch } from "../../store/reducers/persist";
// import { useDispatch } from "react-redux";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
import BusinessUserAccountCard from '../../Components/cards/differentCards/BusinessUserAccountCard'
import BusinessProfilePageProgressStatus from '../ProfilePage_new/BusinessProfilePageProgressStatus'
// import GetUserProfile from "../Homepage_new/GetUserProfile";

const Homepage_index = (props) => {
  // const dispatch = useDispatch();
  const { t } = useTranslation()
  const { frProUser } = useSelector((state) => state.Users)

  return (
    <>
      {/* <GetUserProfile/> */}
      <div
        className={props?.loading ? 'loading-skeleton container' : 'container'}
        style={{ marginTop: '1rem' }}
      >
        <div className="row">
          {frProUser?.fre8ProStatus !== 'Standard' && (
            <div className="alert alert-warning" role="alert">
              Please verify your profile, your verification is pending
              <Typography
                component={Link}
                className={'btn btn-default'}
                to="/otpscreen"
                color="textSecondary"
                variant="h6"
                sx={{ textDecoration: 'underline', color: 'black' }}
                // onClick={handleSubmit}
              >
                Verify now
              </Typography>
            </div>
          )}
        </div>
        <div className="row">
          <BusinessUserAccountCard user={frProUser} />
          <PublishAPost user={frProUser} />
        </div>
      </div>
      <div
        className={props?.loading ? 'loading-skeleton container' : 'container'}
      >
        <div className="row">
          <div className="col-lg-4">
            <UsefulLinksWrapper />
            <CustomLiveUserCardWrapper
              cardType="liveUser"
              name={t('buddy_suggestions')}
              usersSuggestions={frProUser?.suggestions}
            />
            <CustomLiveUserCardWrapper
              cardType="buddies"
              name={t('buddies')}
              buddies={frProUser?.buddies}
            />
          </div>
          <div className="col-lg-8">
            <BusinessProfilePageProgressStatus color={'success'} />
            <div className={props?.loading ? 'loading-skeleton row' : 'row'}>
              {/* <!-- My TTs start --> */}
              <div className="col-lg-12">
                <div className="myTts-section">
                  <div className="title">{t('my_tts')}</div>
                  <div className="row row-cols-2 row-cols-md-4 g-2">
                    <div className="col">
                      <div className="img">
                        <img
                          src="/Assets/Images/latest/mytt.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="img-overlay">
                          <a href="">
                            <i className="bi bi-plus"></i>
                          </a>
                          <p className="bfont">{t('my_tts')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="img">
                        <img
                          src="/Assets/Images/latest/tt1.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="img-overlay">
                          <img
                            src="/Assets/Images/latest/user-img1.jpg"
                            alt=""
                          />
                          <p>Harbhajan Singh</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="img">
                        <img
                          src="/Assets/Images/latest/tt2.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="img-overlay">
                          <img
                            src="/Assets/Images/latest/user-img5.jpg"
                            alt=""
                          />
                          <p>Rinku Singh</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="img">
                        <img
                          src="/Assets/Images/latest/tt3.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="img-overlay">
                          <img
                            src="/Assets/Images/latest/user-img6.jpg"
                            alt=""
                          />
                          <p>Ranveer Singh</p>
                        </div>
                        <button>
                          <i className="bi bi-arrow-right-short"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div><!-- My TTs end --> */}
            </div>
            <CustomCommentsCardWrapper
              postsData={frProUser?.postsData?.posts}
            />
          </div>
        </div>
      </div>
    </>
  )
}

Homepage_index.propTypes = {
  loading: PropTypes.bool,
}

export default Homepage_index
