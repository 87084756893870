import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ProfileApis } from '../../../API/Login/ProfileApi'
import { defaultImage } from '../../../utils/Images'
import { useSelector } from 'react-redux'

const NotificationImg = ({ userId }) => {
  const [userImg, setImg] = useState(null)

  const { frProUser } = useSelector((state) => state.Users)

  const getUserImg = async (userid) => {
    const userObj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      userId: userid,
    }
    try {
      const res = await ProfileApis.buddyProfileImage(userObj)
      setImg(`data:image/jpg;base64, ${res}`)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getUserImg(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <img
      className="img-fluid"
      src={userImg || `data:image/jpg;base64, ${defaultImage}`}
      alt="user img"
      style={{ borderRadius: '50%' }}
    />
  )
}

export default NotificationImg

NotificationImg.propTypes = {
  userId: PropTypes.string,
}
