import PropTypes from 'prop-types'
import React from 'react'

const LiveUserCard = (props) => {
  let item = props.itemData

  return (
    <>
      <li>
        <a href="/">
          <img src={item?.localPath} alt="" />
          {item?.title}
        </a>
      </li>
    </>
  )
}

LiveUserCard.propTypes = {
  itemData: PropTypes.object,
}

LiveUserCard.defaultProps = {
  color: 'primary',
}

export default LiveUserCard
