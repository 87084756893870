import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import InputField from '../../../../Components/inputField'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetBusinessOwnerShipFormik = ({
  setSaving,
  setSelection,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  return (
    <Formik
      initialValues={{
        firstName: frProUser?.updateProfileForm?.firstName,
        lastName: frProUser?.updateProfileForm?.lastName,
        OwnerOfTheCompany: frProUser?.updateProfileForm?.youOwnerOfTheFleet,
        gender: frProUser?.updateProfileForm?.gender,
        designation: frProUser?.updateProfileForm?.designation,
      }}
      validationSchema={Yup.object().shape({
        OwnerOfTheCompany: Yup.string().required('OwnerOfTheCompany required'),
        designation: Yup.string().when('OwnerOfTheCompany', {
          is: 'No',
          then: () => Yup.string().required('Designation is required'),
        }),
        firstName: Yup.string().when('OwnerOfTheCompany', {
          is: 'Yes',
          then: () => Yup.string().required('Firstname required'),
        }),
        lastName: Yup.string().when('OwnerOfTheCompany', {
          is: 'Yes',
          then: () => Yup.string().required('Lastname required'),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const ownershipData = {
          youOwnerOfTheFleet: values?.OwnerOfTheCompany,
          firstName: values?.firstName,
          lastName: values?.lastName,
          gender: values?.gender,
          designation: values?.designation,
        }

        try {
          const data = {
            ...frProUser?.updateProfileForm,
            fre8ProAccessToken: frProUser?.fre8ProAccessToken,
            ...ownershipData,
          }
          console.log('data..........', data)
          ProfileApis.updateFleetOwnerUserProfile(data).then(() => {
            dispatch(
              setFrProUser({
                frProUser: {
                  ...frProUser,
                  updateProfileForm: {
                    ...frProUser?.updateProfileForm,
                    ...ownershipData,
                  },
                },
              })
            )
          })
          setSaving(true)
        } catch (err) {
          console.log(err)
          let customMessage = 'Updating Ownership details failed'
          let type = 'User Profile'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
        }
      }}
    >
      {({ errors, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <div>
            {' '}
            <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
              <div className="row">
                <div className="col-md-4">
                  <h5 className="fw-bold mb-3">Business ownership</h5>
                </div>
                <div className="col-md-7 ms-auto">
                  <div className="row g-4">
                    <div className="col-md-12 col-lg-8">
                      <div className="col-md-12">
                        <label className="form-label">
                          Are you the owner of the company?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>

                        <div className="form-check">
                          <InputField
                            type={'radio'}
                            values={values}
                            name={'OwnerOfTheCompany'}
                            radVal={'Yes'}
                            touched={touched}
                            errors={errors}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault3"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <InputField
                            type={'radio'}
                            values={values}
                            name={'OwnerOfTheCompany'}
                            radVal={'No'}
                            touched={touched}
                            errors={errors}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault4"
                          >
                            No
                          </label>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            marginTop: '1rem',
                          }}
                        >
                          {values?.OwnerOfTheCompany === 'Yes' ? (
                            <div>
                              <div className="col-md-12">
                                <label className="form-label">
                                  First name{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </label>
                                <InputField
                                  type={'text'}
                                  values={values}
                                  name={'firstName'}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>

                              <div className="col-md-12">
                                <label className="form-label">
                                  Last name{' '}
                                  <span style={{ color: 'red' }}>*</span>
                                </label>
                                <InputField
                                  type={'text'}
                                  values={values}
                                  name={'lastName'}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12">
                              <label className="form-label">
                                Designation{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </label>
                              <InputField
                                tag={'select'}
                                values={values}
                                name={'designation'}
                                touched={touched}
                                errors={errors}
                              >
                                <option value="Executive Management">
                                  Designation Dropdown
                                </option>
                                <option value="Executive Management">
                                  Executive Management
                                </option>
                                <option value="Maintenance Management">
                                  Maintenance Management
                                </option>
                                <option value="Operation Management">
                                  Operation Management
                                </option>
                                <option value="Safety Management">
                                  Safety Management
                                </option>
                                <option value="Others">Others</option>
                              </InputField>
                            </div>
                          )}
                          <div className="col-md-12">
                            <label className="form-label">Gender</label>
                            <InputField
                              tag={'select'}
                              values={values}
                              name={'gender'}
                              touched={touched}
                              errors={errors}
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </InputField>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button type="submit" className="btn btn-theme me-2">
                        Save
                      </button>
                      <button
                        onClick={() => {
                          frProUser?.updateProfileForm?.youOwnerOfTheFleet
                            ? setSaving(true)
                            : setSelection('')
                        }}
                        type="button"
                        className="btn btn-border"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default FleetBusinessOwnerShipFormik

FleetBusinessOwnerShipFormik.propTypes = {
  setSelection: PropTypes.func,
  setSaving: PropTypes.func,
  setToasts: PropTypes.func,
}
