import React from 'react'
import { useState } from 'react'

import PropTypes from 'prop-types'
import FleetShippingAddressView from './FleetShippingAddressView'
import FleetShippingAddressFormik from './FleetShippingAddressFormik'
import { useSelector } from 'react-redux'
import Toasts from '../../../../Components/Toasts/Toasts'
import useCountryAndStatesHook from '../../../../customHooks/useCountryAndStatesHook'

const FleetShippingAdress = ({ setSelection }) => {
  const { frProUser } = useSelector((state) => state.Users)
  const [toasts, setToasts] = useState(null)

  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.shippingAddress?.address ? true : false
  )

  useCountryAndStatesHook(setToasts)

  const deleteFleetShippingAddressData = {
    ...frProUser?.updateProfileForm,
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    shippingAddress: {
      ownerId: '',
      profileId: '',
      addressId: '',
      address: '',
      city: '',
      coordinates: {
        lat: '',
        lng: '',
      },
      postalCode: '',
      state: '',
      country: '',
    },
  }

  return (
    //This is a wrapper for FleetShippingAddressView and FleetShippingAddressFormik
    <>
      <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
        <div className="row">
          {saving ? (
            <FleetShippingAddressView
              deleteFleetShippingAddressData={deleteFleetShippingAddressData}
              setSaving={setSaving}
              setSelection={setSelection}
              setToasts={setToasts}
            />
          ) : (
            <>
              <div className="col-md-4">
                <h5 className="fw-bold mb-3">Shipping address</h5>
              </div>
              <div className="col-md-7 ms-auto">
                <div className="row g-4">
                  <FleetShippingAddressFormik
                    setSaving={setSaving}
                    setSelection={setSelection}
                    setToasts={setToasts}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

export default FleetShippingAdress

FleetShippingAdress.propTypes = {
  setSelection: PropTypes.func,
}
