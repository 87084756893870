import PropTypes from 'prop-types'
import React from 'react'

const Loader = (props) => {
  return (
    <div className="loaderWrapper " style={props.style}>
      <div className="loader" style={props.config}></div>
    </div>
  )
}

Loader.propTypes = {
  config: PropTypes.object,

  style: PropTypes.object
}

export default Loader
