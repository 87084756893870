import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
// import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import InputField from '../../../../Components/inputField'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import ErrorHandle from '../../../../Components/ErrorHandle'
import { FormHelperText } from '@mui/material'

const serviceDetails = [
  'Inventory control',
  'Cross docking',
  'Fulfilment and pick & pack services',
  'Transportation and transloading',
]

const relatedServicesArr = [
  'Other fleets',
  'Dealer - trucks',
  'Dealer - Trailers',
  'Dealer - others',
]

const FleetServicesFormik = ({
  setSaving,
  setSelection,
  arrPartOfLogisticsNo,
  arrPartOfLogisticsYes,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  return (
    <Formik
      initialValues={{
        doyouProvideWarehousingServices:
          frProUser?.updateProfileForm?.wareHouseServiceFlag,
        doyouProvideWarehousingServicesDetails:
          frProUser?.updateProfileForm?.serviceOfferred,
        areYouPartOfLogisticsAndTransportationBusiness:
          frProUser?.updateProfileForm?.partOfLogisticsString,
        fleetSegment: frProUser?.updateProfileForm?.fleetSegment,
        fleetType: frProUser?.updateProfileForm?.fleetType,
        relatedServices: frProUser?.updateProfileForm?.serviceList,
      }}
      validationSchema={Yup.object().shape({
        doyouProvideWarehousingServices: Yup.string().required(
          'missing Information'
        ),
        doyouProvideWarehousingServicesDetails: Yup.array().when(
          'doyouProvideWarehousingServices',
          {
            is: 'Yes',
            then: () =>
              Yup.array().min(1, 'At least one service must be offered'),
          }
        ),
        relatedServices: Yup.array().min(
          1,
          'At least one service must be offered'
        ),
        areYouPartOfLogisticsAndTransportationBusiness: Yup.string().required(
          'missing information'
        ),
        fleetSegment: Yup.string().required('missing information'),
        fleetType: Yup.string().required('missing information'),
        // relatedServices: Yup.array().required('missing information'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const fleetServices = {
          wareHouseServiceFlag: values?.doyouProvideWarehousingServices,
          serviceOfferred:
            values?.doyouProvideWarehousingServices === 'No'
              ? []
              : values?.doyouProvideWarehousingServicesDetails,
          partOfLogisticsString:
            values?.areYouPartOfLogisticsAndTransportationBusiness,
          fleetSegment: values?.fleetSegment,
          fleetType: values?.fleetType,
          serviceList: values?.relatedServices,
        }

        try {
          const data = {
            ...frProUser?.updateProfileForm,
            fre8ProAccessToken: frProUser?.fre8ProAccessToken,
            ...fleetServices,
          }
          console.log('data..........', data)
          ProfileApis.updateFleetOwnerUserProfile(data).then(() => {
            dispatch(
              setFrProUser({
                frProUser: {
                  ...frProUser,
                  updateProfileForm: {
                    ...frProUser?.updateProfileForm,
                    ...fleetServices,
                  },
                },
              })
            )
          })
          setSaving(true)
        } catch (err) {
          console.log(err)
          let customMessage = 'Updating Services details failed'
          let type = 'User Profile'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
        }
      }}
    >
      {({ errors, touched, values }) => (
        <Form>
          <div className="col-md-12">
            <label className="form-label">
              Do you provide warehousing services?{' '}
              <span style={{ color: 'red' }}>*</span>
              {touched?.doyouProvideWarehousingServices &&
                errors?.doyouProvideWarehousingServices && (
                  <FormHelperText
                    error
                    id="helper-text-doyouProvideWarehousingServices"
                  >
                    {errors?.doyouProvideWarehousingServices}
                  </FormHelperText>
                )}
            </label>
            <div className="form-check">
              <InputField
                type={'radio'}
                values={values}
                name={'doyouProvideWarehousingServices'}
                touched={touched}
                radVal="Yes"
              />

              <label className="form-check-label" htmlFor="flexRadioDefault6">
                Yes
              </label>
            </div>
            <div className="form-check">
              <InputField
                type={'radio'}
                values={values}
                name={'doyouProvideWarehousingServices'}
                touched={touched}
                radVal="No"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault7">
                No
              </label>
            </div>
          </div>
          {values?.doyouProvideWarehousingServices === 'Yes' && (
            <div className="col-md-12 my-3">
              <label className="form-label">
                Please select all the services offered{' '}
                <span style={{ color: 'red' }}>*</span>
                {touched?.doyouProvideWarehousingServicesDetails &&
                  errors?.doyouProvideWarehousingServicesDetails && (
                    <FormHelperText
                      error
                      id="helper-text-doyouProvideWarehousingServicesDetails"
                    >
                      {errors?.doyouProvideWarehousingServicesDetails}
                    </FormHelperText>
                  )}
              </label>
              {serviceDetails?.map((item, i) => (
                <div key={i} className="form-check">
                  <InputField
                    type={'checkbox'}
                    values={values}
                    name={'doyouProvideWarehousingServicesDetails'}
                    touched={touched}
                    checkVal={item}
                  />
                  <label className="form-check-label" htmlFor="5">
                    {item}
                  </label>
                </div>
              ))}
            </div>
          )}

          <div className="col-md-12">
            <label className="form-label">
              Are you part of logistics and transportation business?{' '}
              <span style={{ color: 'red' }}>*</span>
              {touched?.areYouPartOfLogisticsAndTransportationBusiness &&
                errors?.areYouPartOfLogisticsAndTransportationBusiness && (
                  <FormHelperText
                    error
                    id="helper-text-areYouPartOfLogisticsAndTransportationBusiness"
                  >
                    {errors?.areYouPartOfLogisticsAndTransportationBusiness}
                  </FormHelperText>
                )}
            </label>
            <div className="form-check">
              <InputField
                type={'radio'}
                values={values}
                name={'areYouPartOfLogisticsAndTransportationBusiness'}
                touched={touched}
                radVal="Yes"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault8">
                Yes
              </label>
            </div>
            <div className="form-check">
              <InputField
                type={'radio'}
                values={values}
                name={'areYouPartOfLogisticsAndTransportationBusiness'}
                touched={touched}
                radVal="No"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault9">
                No
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <label className="form-label">
              Fleet Segment <span style={{ color: 'red' }}>*</span>
            </label>
            <InputField
              tag={'select'}
              values={values}
              name={'fleetSegment'}
              touched={touched}
              errors={errors}
            >
              {!values?.fleetSegment && <option>Please Select</option>}
              {values?.areYouPartOfLogisticsAndTransportationBusiness ===
                'Yes' &&
                arrPartOfLogisticsYes?.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
              {values?.areYouPartOfLogisticsAndTransportationBusiness ===
                'No' &&
                arrPartOfLogisticsNo?.map((item, i) => (
                  <option key={i}>{item}</option>
                ))}
            </InputField>
          </div>

          <div className="col-md-12 mt-3">
            <label className="form-label">
              Fleet type <span style={{ color: 'red' }}>*</span>
            </label>
            <InputField
              tag={'select'}
              values={values}
              name={'fleetType'}
              touched={touched}
              errors={errors}
            >
              {!values?.fleetType && <option>Please Select</option>}
              <option value="Corporation">Corporation</option>
              <option value="Sole Propreitorship">Sole Propreitorship</option>
              <option value="Partnership">Partnership</option>
            </InputField>
          </div>
          <div className="col-md-12 mt-3">
            <label className="form-label">
              Related services <span style={{ color: 'red' }}>*</span>
              {touched?.relatedServices && errors?.relatedServices && (
                <FormHelperText error id="helper-text-relatedServices">
                  {errors?.relatedServices}
                </FormHelperText>
              )}
            </label>

            {relatedServicesArr?.map((item, i) => (
              <div key={i} className="form-check">
                <InputField
                  type={'checkbox'}
                  values={values}
                  name={'relatedServices'}
                  touched={touched}
                  checkVal={item}
                />
                <label className="form-check-label" htmlFor="5">
                  {item}
                </label>
              </div>
            ))}

            <div className="mt-4">
              <button type="submit" className="btn btn-theme me-2">
                Save
              </button>
              <button
                type="button"
                className="btn btn-border"
                onClick={() =>
                  frProUser?.updateProfileForm?.wareHouseServiceFlag
                    ? setSaving(true)
                    : setSelection('')
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FleetServicesFormik
FleetServicesFormik.propTypes = {
  setSelection: PropTypes.func,
  setSaving: PropTypes.func,
  arrPartOfLogisticsYes: PropTypes.func,
  arrPartOfLogisticsNo: PropTypes.func,
  setToasts: PropTypes.func,
}
