import { FormHelperText, Typography } from '@mui/material'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { LoginApis } from '../../API/Login/LoginApi'
import '../../css/ddsmoothmenu.css'
import {
  setFrProUser,
  setauthorizationToken,
} from '../../store/reducers/Users/users'
import { formatPhoneNumber } from '../../utils/GeneralUtils'
import Toasts from '../../Components/Toasts/Toasts'
import ErrorHandle from '../../Components/ErrorHandle'

const emailRules = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
// const phoneNumberRules = /^(\+1)$/;

const FleetCreateAccountMoreInfo = () => {
  const { frProUser } = useSelector((state) => state?.Users)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { authorizationToken } = useSelector((state) => state?.Users)

  // const maxDate = new Date()
  // maxDate.setFullYear(maxDate.getFullYear() - 18)
  // const maxDateString = maxDate.toISOString().split('T')[0]

  const [countries, setcountries] = useState([])
  const [states, setstates] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [captchaImage, setCaptchaImage] = useState('')
  const [refreshCaptchaCode, setrefreshCaptchaCode] = useState(false)
  const [date, setDate] = useState('')
  // const [captchaVerification, setCaptchaVerification] = useState("");

  const [toasts, setToasts] = useState(null)

  let captchImageScc = `data:image/jpeg;base64, ${captchaImage}`

  const getCountries = () => {
    LoginApis.getCountries()
      .then((res) => {
        setcountries(res?.data)
      })
      .catch((err) => {
        console.debug(':VAlues from getcountries', err)

        let customMessage = 'countries load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  const getCaptcha = (token) => {
    LoginApis.getCaptcha(token)
      .then((res) => {
        setCaptchaImage(res)
      })
      .catch((err) => {
        console.debug(':VAlues from login', err)

        let customMessage = 'captcha load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  // const refreshCaptcha = () => {
  //   let t = authorizationToken
  //   console.log("Token refreshCaptcha", t)
  //   LoginApis.refreshCaptcha(t).then((res) => {
  //     // dispatch(setUser({ user: res }));
  //     setCaptchaImage(res)
  //   }).catch((err) => {
  //     console.log(":VAlues from login", err)
  //     // navigate("/error", { err: err })
  //   }).finally(() => {
  //     //TODO : test code remove thislater
  //     // navigate("/firstTimeUser", { username: "testUser" })
  //   })
  // }

  const getStates = (countryName) => {
    LoginApis.getStates(countryName)
      .then((res) => {
        setstates(res?.data)
      })
      .catch((err) => {
        console.debug(':VAlues from login', err)

        let customMessage = 'states load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  const handleCountryChange = (event) => {
    const countryName = event?.currentTarget?.value
    const country = countries?.find((c) => c?.countryId === countryName)
    getStates(country?.countryId)
    setSelectedCountry(country?.countryId)
    setSelectedState('')
  }

  const handleStateChange = (event) => {
    setSelectedState(event?.target?.value)
  }

  useEffect(() => {
    LoginApis.getToken()
      .then((res) => {
        dispatch(
          setauthorizationToken({
            authorizationToken: res?.data?.fre8ProAccessToken,
          })
        )
        getCountries()
        getCaptcha(res?.data?.fre8ProAccessToken)
        // refreshCaptcha(res?.data?.fre8ProAccessToken)
      })
      .catch((err) => {
        // navigate("/error", { err: err })
        console.debug('Get Token error', err)

        let customMessage = 'token load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
      .finally(() => {
        // TODO: remove test code
        // navigate("/")
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refreshCaptchaCode === true) {
      let t = authorizationToken
      LoginApis.refreshCaptcha(t)
        .then((res) => {
          setCaptchaImage(res)
        })
        .catch((err) => {
          console.debug(':VAlues from login', err)

          let customMessage = 'chaptcha refresh load failed'
          let type = 'User Information'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
          // navigate("/error", { err: err })
        })
        .finally(() => {
          //TODO : test code remove thislater
          // navigate("/firstTimeUser", { username: "testUser" })
        })
    }
  }, [refreshCaptchaCode]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMobileChecker = async (values) => {
    const obj = {
      mobileNumber:
        values?.phoneNumber?.length === 11 || values?.phoneNumber?.length === 10
          ? formatPhoneNumber(values?.phoneNumber)
          : values?.phoneNumber,
      email: values?.email,
      fre8ProUserId: values?.fre8ProUserId,
    }
    try {
      const res = await LoginApis.mobileNumberIsAvailable(obj)
      if (res?.data?.available) {
        return false
      }
      return true
    } catch (err) {
      console.log(err)
      let customMessage = 'Mobile availble API failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          businessName: '',
          country: '',
          state: '',
          dob: '',
          phoneNumber: '',
          email: frProUser?.fre8ProUserId,
          firstname: '',
          captchaCode: '',
        }}
        // validate={(value) => {
        //   const error = {};
        //   if (new Date(value?.dob) > maxDate) {
        //     error.dob = `You must be 14 or older to register`;
        //   }
        //   return error;
        // }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .required(
              'Invalid phone number format. Please provide phone number in this format: +1 (555) 000-0000'
            )
            .test(
              'checkPhoneNumberExists',
              'Mobile number already exists in the system.',
              async function () {
                let values = this.parent
                const isAvailable = await handleMobileChecker(values)

                return isAvailable
              }
            ),
          email: Yup.string()
            .required('Missing Information')
            .matches(emailRules, 'Incorrect email format'),
          businessName: Yup.string().required('Missing Information').max(255),
          dob: Yup.date().required('Missing Information'),
          country: Yup.string().required('Missing Information'),
          state: Yup.string().required('Missing Information'),
          captchaCode: Yup.string().required('Missing Information'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setStatus({ success: false })
            setSubmitting(false)

            let userObj = {
              fre8ProAccessToken: authorizationToken,
              fre8ProUserId: values?.email,
              userName: values?.email,
              password: frProUser?.password,
              fre8ProLanguage: 'eng',
              email: values?.email,
              mobileNumber: values?.phoneNumber,
              userProfessionCategory: frProUser?.userCategory,
              dateOfBirth: values?.dob,
              country: values?.country,
              state: values?.state,
              getiAgreeTime: '',
              getiAgreeIpAddress: '',
              userAgent: '',
              businessName: values?.businessName,
              captcha: values?.captchaCode,
            }
            LoginApis.createFleetBusinessUser(userObj, authorizationToken)
              .then((res) => {
                dispatch(
                  setFrProUser({
                    frProUser: { ...res?.data, country: userObj?.country },
                  })
                )
                // dispatch(
                //   setTempValToLocalStorage({
                //     pushTempValToLocalStorage: res?.data,
                //   })
                // );
                // let userList = JSON.parse(localStorage.getItem("usersList"));
                // // userList = []
                // userList.push(res?.data);
                // localStorage.setItem("usersList", JSON.stringify(userList));
                // localStorage.setItem("user", JSON.stringify(res?.data));
                dispatch(
                  setauthorizationToken({
                    authorizationToken: res?.data?.fre8ProAccessToken,
                  })
                )
                // if (res?.fre8ProStatus === "standard") {
                //   navigate("/profile", { username: "testUser" });
                // } else {
                //   navigate("/dashboard", { username: "testUser" });
                // }
                navigate('/verificationmobile')
                // setCaptchaVerification(false);
              })
              .catch((err) => {
                // setCaptchaVerification(true);
                console.debug(':VAlues from login', values, err)
                setStatus({ success: false })
                setErrors({ submit: err?.message })
                setSubmitting(false)

                let customMessage = 'somthing went wrong'
                let type = 'User Information'
                let color = 'red'
                ErrorHandle(err, setToasts, customMessage, type, color)

                // navigate("/error", { err: err })
              })
              .finally((res) => {
                //TODO : test code remove thislater
                // navigate("/firstTimeUser", { username: "testUser" })
                //loadChatClient()
              })
            // navigate("/otpscreen", { username: "testUser" })
          } catch (err) {
            setStatus({ success: false })
            setErrors({ submit: err?.message })
            setSubmitting(false)
          }
        }}
        validateOnMount
        validateOnBlur
        validateOnChange={false}
      >
        {({
          errors,
          handleSubmit,
          touched,
          setFieldValue,
          values,

          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <section className="py-5">
              <div className="container py-lg-4">
                <div className="row">
                  <div className="title text-center mb-4">
                    <span className="custom-bages fleet-button">
                      Fleet Business
                    </span>
                    <h1 className="mt-2 color-700">Create account</h1>
                  </div>
                  <div className="col-lg-4 col-md-6 mx-auto">
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700">
                        Business name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        autoComplete
                        type="text"
                        className="form-control"
                        maxLength={50}
                        placeholder="Please business name"
                        onChange={(e) =>
                          setFieldValue(
                            'businessName',
                            e?.currentTarget?.value,
                            {
                              shouldValidate: true,
                            }
                          )
                        }
                      />
                      {touched?.businessName && errors?.businessName && (
                        <FormHelperText error id="helper-text-businessName">
                          {errors?.businessName}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700">
                        Country <span style={{ color: 'red' }}>*</span>
                      </label>
                      <select
                        id="country"
                        value={selectedCountry}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setFieldValue('country', e?.currentTarget?.value, {
                            shouldValidate: true,
                          })
                          //alert(e?.currentTarget?.value)
                          handleCountryChange(e)
                        }}
                      >
                        <option defaultValue="selected">Select menu</option>
                        {countries?.map((country) => {
                          return (
                            country?.countryName !== 'Mexico' && (
                              <option
                                key={country?.countryId}
                                value={country?.countryId}
                              >
                                {country?.countryName}
                              </option>
                            )
                          )
                        })}
                      </select>

                      {touched?.country && errors?.country && (
                        <FormHelperText error id="helper-text-country">
                          {errors?.country}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700">
                        State <span style={{ color: 'red' }}>*</span>
                      </label>
                      <select
                        id="state"
                        value={selectedState}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setFieldValue('state', e?.currentTarget?.value, {
                            shouldValidate: true,
                          })
                          handleStateChange(e)
                        }}
                      >
                        <option defaultValue="selected">Select a state</option>
                        {/* {countries?.find(c => c.name === selectedCountry)?.states?.map(state => ( */}
                        {states?.map((state) => (
                          <option key={state?.stateId} value={state?.stateId}>
                            {state?.stateName}
                          </option>
                        ))}
                      </select>

                      {touched?.state && errors?.state && (
                        <FormHelperText error id="helper-text-state">
                          {errors?.state}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700 gap">
                        Date of Commencement{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <Field
                        // min={minDateString}
                        name="dob"
                        // max={maxDateString}
                        autoComplete
                        onChange={(e) => setDate(e?.target?.value)}
                        value={date}
                        type="date"
                        className="form-control"
                        placeholder="Please provide  Date of Commencement"
                        onBlur={(e) =>
                          setFieldValue('dob', e?.currentTarget?.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {touched?.dob && errors?.dob && (
                        <FormHelperText error id="helper-text-dob">
                          {errors?.dob}
                        </FormHelperText>
                      )}
                      {/* <div className="d-flex w-75 gap">
                        <input
                          type="text"
                          className="form-control text-center"
                          placeholder="DD"
                          id="for_disabled"
                        />
                        <input
                          type="text"
                          className="form-control text-center"
                          placeholder="MM"
                          id="for_disabled"
                        />
                        <input
                          type="text"
                          className="form-control text-center"
                          placeholder="YYYY"
                          id="for_disabled"
                        />
                      </div> */}
                    </div>
                    <div className="mb-3">
                      <div className="phone-list">
                        <label className="form-label f-w-500 color-700">
                          Phone number <span style={{ color: 'red' }}>*</span>
                        </label>
                        <div className="input-group phone-input">
                          <span className="input-group-btn">
                            <select className="form-control rounded-end-0 h-100">
                              {!values?.country ? (
                                <option>CN</option>
                              ) : (
                                <option>{values?.country}</option>
                              )}
                            </select>
                          </span>

                          <Field
                            className="form-control"
                            type="text"
                            name="phoneNumber"
                            maxLength={14}
                            placeholder="+1 (555) 000-0000"
                            onBlur={(event) => {
                              let number = formatPhoneNumber(
                                event?.currentTarget?.value
                              )
                              setFieldValue('phoneNumber', number, {
                                shouldValidate: true,
                              })
                              setFieldTouched('phoneNumber', true, false)
                              console.log(
                                'set phoneNumber:',
                                touched?.phoneNumber,
                                errors?.phoneNumber
                              )
                            }}
                          />
                        </div>
                        {touched?.phoneNumber && errors?.phoneNumber && (
                          <FormHelperText error id="helper-text-phoneNumber">
                            {errors?.phoneNumber}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700">
                        Email <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        autoComplete
                        type="text"
                        maxLength={50}
                        className="form-control"
                        value={values?.email}
                        placeholder="Please provide email id"
                        onChange={(e) =>
                          setFieldValue('email', e?.currentTarget?.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {touched?.email && errors?.email && (
                        <FormHelperText error id="helper-text-email">
                          {errors?.email}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label f-w-500 color-700">
                        Captcha <span style={{ color: 'red' }}>*</span>
                      </label>
                      <div className="captch-align d-flex flex-column w-50">
                        <img src={captchImageScc} alt="" />

                        <a
                          onClick={() => {
                            setrefreshCaptchaCode(!refreshCaptchaCode)
                          }}
                          className="btn-link"
                        >
                          <i className="bi bi-arrow-clockwise"></i>
                        </a>
                      </div>
                      <input
                        autoComplete
                        type="text"
                        maxLength={6}
                        className="form-control"
                        placeholder="Type the text here displayed above"
                        onChange={(e) =>
                          setFieldValue(
                            'captchaCode',
                            e?.currentTarget?.value,
                            {
                              shouldValidate: true,
                            }
                          )
                        }
                      />
                      {touched?.captchaCode && errors?.captchaCode && (
                        <FormHelperText error id="helper-text-captchacode">
                          {errors?.captchaCode}
                        </FormHelperText>
                      )}
                      {/* {
                        <FormHelperText error id="helper-text-captchacode">
                          {captchaVerification
                            ? "please check Captcha and hit on Create account button"
                            : ""}
                        </FormHelperText>
                      } */}
                      {/* <p>
                        <img
                          alt=""
                          src="images/captcha.png"
                          className="w-100"
                        />
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type the text above"
                      /> */}
                    </div>
                    {/* <div className="mb-3">
                    <button className="verifybutton px-3" type="button">
                      Verify
                    </button>
                    <button className="refreshbtm" type="button">
                      <img alt="" src="images/refresh.svg" /> Refresh
                    </button>
                  </div> */}
                    {/* <!--Verified  row html=========================================--> */}
                    {/* <!--UNVerified  row html=========================================--> */}
                    <div className="my-4">
                      <Typography>
                        <button
                          type="submit"
                          // className={`btn ${
                          //   isValid ? "" : "disabled"
                          // } custom-button w-100 noUnderline oneRem`}
                          // disabled={!isValid}
                          className="btn custom-button w-100 noUnderline oneRem"
                        >
                          Create Account
                        </button>
                      </Typography>
                    </div>
                    <div
                      className="text-center d-flex align-items-center justify-content-center"
                      onClick={() => navigate(-1)}
                    >
                      <a href="#" className="f-w-700 color-400">
                        <img alt="" src="images/back.svg" className="me-3" />
                        Go back
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        )}
      </Formik>
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

export default FleetCreateAccountMoreInfo
