import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import fleetBusinessProfileImage from '../../css/images/Fleet-business-profile.svg'
import profileImage from '../../css/images/profile-image.svg'
import FleetBuisinessSummary from './BusinessUpdateProfileSubSections/FleetBusinessSummary/FleetBuisinessSummary'
import FleetBusinessOwnerShip from './BusinessUpdateProfileSubSections/FleetBusinessOwnerShip/FleetBusinessOwnerShip'
import FleetContact from './BusinessUpdateProfileSubSections/FleetBusinessContact/FleetContact'
import FleetDocumentation from './BusinessUpdateProfileSubSections/FleetDocumentation'
import FleetServices from './BusinessUpdateProfileSubSections/FleetServicesOffered/FleetServices'
import FleetShippingAdress from './BusinessUpdateProfileSubSections/FleetShippingAddress/FleetShippingAdress'
import { ProfileApis } from '../../API/Login/ProfileApi'
import UploadProfileImage from '../../Components/UploadProfileImage/UploadProfileImage'
import ProfileTagLine from './updateProfileSubSections/profileTagLine'
import Standard from '../../../src/css/images/Standard.svg'
import unverified from '../../../src/css/images/unverified.svg'
import FleetPlusButtonCode from './BusinessUpdateProfileSubSections/FleetPlusButtonCode/FleetPlusButtonCode'
import FleetTerminalsAndWareHouses from './BusinessUpdateProfileSubSections/FleetTerminalsAndWareHouses/FleetTerminalsAndWareHouses'
import Toasts from '../../Components/Toasts/Toasts'
import ErrorHandle from '../../Components/ErrorHandle'
// import ProfilepageTabs from ".";
// import EssentialsBusinessInformation from "./BusinessUpdateProfileSubSections/essentialsBusinessInformation";
// import AboutBusinessInformation from "./BusinessUpdateProfileSubSections/aboutBusinessInformation";

export default function BusinessUpdateProfile(props) {
  const { frProUser, followers, following, emailVerified, numberVerified } =
    useSelector((state) => state.Users)

  const [select, setSelection] = React.useState('')
  const [changeTabs, setChangeTabs] = React.useState('FleetBusinessProfile')
  const [openModal, setOpenModal] = React.useState(false)
  const [profileImg, setProfileImg] = React.useState('')
  const [toasts, setToasts] = React.useState(null)

  React.useEffect(() => {
    getUserProfileImage()
    // eslint-disable-next-line
  }, [])

  const getUserProfileImage = () => {
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    let token = {
      fre8ProAccessToken:
        frProUser?.fre8ProAccessToken || loogedInUser?.fre8ProAccessToken,
    }
    ProfileApis.userProfileImage(token)
      .then((res) => {
        console.log('image', res)
        setProfileImg(`data:image/jpg;base64, ${res}`)
      })
      .catch((err) => {
        console.log('error', err)
        let customMessage = 'User image load failed'
        let type = 'User Profile'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  return (
    <>
      <main className="main users chart-page p-0" id="skip-target">
        <div className="profile-bg">
          <div className="cover-image position-relative">
            <button
              className="btn-upload position-absolute start-50 top-50 translate-middle"
              data-bs-toggle="modal"
              data-bs-target="#coverPhotoModal"
            >
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 5V19M5 12H19"
                  stroke="#005A70"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>

          <div className="container-fluid py-3 ">
            <div className="align-items-start d-flex justify-content-between mb-5 flex-wrap">
              <div>
                <div className="d-flex flex-wrap">
                  <div className="image-holder-main position-relative">
                    <div className="image-holder overflow-hidden position-relative">
                      <img
                        src={profileImg || profileImage}
                        alt="profileImage"
                        height={160}
                        width={160}
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                    <button
                      className="btn-upload position-absolute start-50 translate-middle-x"
                      style={{ bottom: '20px' }}
                      onClick={() => setOpenModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 5V19M5 12H19"
                          stroke="#005A70"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      {/* </label> */}
                    </button>
                  </div>
                  <div className="px-4 my-4 my-lg-0">
                    <img alt="" src={fleetBusinessProfileImage} />
                    {emailVerified && numberVerified ? (
                      <button className="verifedbutton" type="button">
                        <img src={Standard} alt="" />
                      </button>
                    ) : (
                      <button type="button">
                        <img src={unverified} alt="" />
                      </button>
                    )}

                    <h4 className="mt-2">
                      {frProUser?.updateProfileForm?.firstName
                        ? frProUser?.updateProfileForm?.firstName
                        : frProUser?.updateProfileForm?.personalInfo?.firstName
                          ? frProUser?.updateProfileForm?.personalInfo?.firstName
                          : frProUser?.updateProfileForm?.businessName}

                      {'  '}
                      {frProUser?.updateProfileForm?.lastName
                        ? frProUser?.updateProfileForm?.lastName
                        : frProUser?.updateProfileForm?.personalInfo?.lastName}
                    </h4>
                    {select === 'ProfileTagLine' ||
                      frProUser?.updateProfileForm?.profileTagForm?.profileTag ? (
                      <ProfileTagLine setSelection={setSelection} />
                    ) : (
                      <p className="mb-0 mt-3 small text-muted d-flex align-items-center">
                        {' '}
                        <button
                          onClick={() => setSelection('ProfileTagLine')}
                          className="btn-upload me-3"
                        >
                          {' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 5V19M5 12H19"
                              stroke="#005A70"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>{' '}
                        Add profile tagline
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="shadow-sm p-4 rounded-4 me-4 bg-white bg-white bg-white">
                <div className="text-theme mb-3">
                  <span className="me-3 fw-medium">
                    <strong>{followers?.length || 0}</strong> Followers
                  </span>
                  <span className="fw-medium">
                    <strong>{following?.length || 0}</strong> Following
                  </span>
                </div>
                <Typography
                  className="btn btn-theme w-100"
                  component={Link}
                  to={'/businessprofile'}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6668 17.5V15.8333C16.6668 14.9493 16.3156 14.1014 15.6905 13.4763C15.0654 12.8512 14.2176 12.5 13.3335 12.5H6.66683C5.78277 12.5 4.93493 12.8512 4.30981 13.4763C3.68469 14.1014 3.3335 14.9493 3.3335 15.8333V17.5M13.3335 5.83333C13.3335 7.67428 11.8411 9.16667 10.0002 9.16667C8.15921 9.16667 6.66683 7.67428 6.66683 5.83333C6.66683 3.99238 8.15921 2.5 10.0002 2.5C11.8411 2.5 13.3335 3.99238 13.3335 5.83333Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  View my profile
                </Typography>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-pills vehicles-group mb-3">
                  <li className="nav-item">
                    <a
                      onClick={() => setChangeTabs('FleetBusinessProfile')}
                      className={
                        changeTabs === 'FleetBusinessProfile'
                          ? 'nav-link bg-white active'
                          : 'nav-link bg-white'
                      }
                      href="#"
                    >
                      Business profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={() => {
                        setChangeTabs('TerminalsAndWareHousing')
                        frProUser?.updateProfileForm?.terminal?.length === 0
                          ? setSelection('Terminals')
                          : setSelection('terminalAdded')
                      }}
                      className={
                        changeTabs === 'TerminalsAndWareHousing'
                          ? 'nav-link bg-white active'
                          : 'nav-link bg-white'
                      }
                      href="#"
                    >
                      Terminals and warehousing
                    </a>
                  </li>
                </ul>
                {/* Fleet Business Profile start */}
                {changeTabs === 'FleetBusinessProfile' && (
                  <div>
                    <h3 className="businessheading">Business profile</h3>
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-Profile"
                        role="tabpanel"
                        aria-labelledby="v-Profile-tab"
                        tabIndex="0"
                      >
                        {/* FleetBuisinessSummary start */}
                        {select === 'FleetBusinessSummary' ||
                          frProUser?.updateProfileForm?.businessName?.length >
                          0 ? (
                          <FleetBuisinessSummary setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetBusinessSummary'}
                          />
                        )}
                        {/* FleetBuisinessSummary end */}

                        {/* FleetContact start */}
                        {select === 'FleetContact' ||
                          frProUser?.updateProfileForm?.primaryEmail ? (
                          <FleetContact setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetContact'}
                          />
                        )}

                        {select === 'FleetBusinessOwnerShip' ||
                          frProUser?.updateProfileForm?.youOwnerOfTheFleet ? (
                          <FleetBusinessOwnerShip setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetBusinessOwnerShip'}
                          />
                        )}

                        {select === 'FleetDocumentation' ||
                          frProUser?.documentName ? (
                          <FleetDocumentation setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetDocumentation'}
                          />
                        )}

                        {select === 'FleetServices' ||
                          frProUser?.updateProfileForm?.wareHouseServiceFlag ? (
                          <FleetServices setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetServices'}
                          />
                        )}

                        {select === 'FleetShippingAdress' ||
                          frProUser?.updateProfileForm?.shippingAddress
                            ?.address ? (
                          <FleetShippingAdress setSelection={setSelection} />
                        ) : (
                          <FleetPlusButtonCode
                            setSelection={setSelection}
                            data={'FleetShippingAdress'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* Fleet Business Proile End */}

                <FleetTerminalsAndWareHouses
                  setSelection={setSelection}
                  select={select}
                  changeTabs={changeTabs}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <UploadProfileImage
        setOpenModal={setOpenModal}
        openModal={openModal}
        setProfileImg={setProfileImg}
      />
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

BusinessUpdateProfile.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.object,
}
