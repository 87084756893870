import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { UsersApis } from '../../API/Login/UsersApi'
import Loader from '../../Components/Loader/Loader'
import Toasts from '../../Components/Toasts/Toasts'
import { defaultImage } from '../../utils/Images'
import { StropheContext } from '../ChatClient'
// import { UseloadChatClient } from "../ChatClient/Chatclient";

const Notifications = ({ connectionObj }) => {
  let loogedInUser = JSON.parse(localStorage.getItem('user'))
  const { frProUser } = useSelector((state) => state.Users)

  // const { approveBuddyReq, loadChatclient } = UseloadChatClient();
  const stropheContext = useContext(StropheContext)
  console.log('Notification strophe connection context', stropheContext)
  const { state } = useLocation()
  console.log(state)
  const [notificationList_new] = useState(loogedInUser?.notificationsList)
  const [reqAccepted, setreqAccepted] = useState(false)
  const [reqRejected, setreqRejected] = useState(false)
  const [toasts, setToasts] = useState(null)
  const [showLoading, setshowLoading] = useState(true)
  console.log('Notification', notificationList_new)

  const approveBuddyReqFunc = (event) => {
    console.log('Approve buddy req', event?.currentTarget?.name)
    let notification = StropheContext?.approveBuddyReq(
      event?.currentTarget?.name
    )
    //Approved buddy request
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      // "requestFromUser": event?.currentTarget?.name,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestApproved(obj)
      .then((res) => {
        console.log('success userBuddyRequestApproved ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)

        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved',
          },
        ]
        setshowLoading(true)
        setToasts(item)
        setreqAccepted(notification)
        setTimeout(() => {
          setshowLoading(false)
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 2000)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestApproved ', err)
        // setbuddiesList(buddiesList)

        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved failed',
          },
        ]
        setshowLoading(true)
        setToasts(item)
        setreqAccepted(notification)
        setTimeout(() => {
          setshowLoading(false)
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 2000)
      })
  }
  const rejectBuddyReqFunc = (event) => {
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      // "requestFromUser": event?.currentTarget?.name,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestDenied(obj)
      .then((res) => {
        console.log('success userBuddyRequestDenied ', res?.data)
        // buddiesList?.push(res?.data)
        // setbuddiesList(buddiesList)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestDenied ', err)
        // setbuddiesList(buddiesList)
      })

    let item = [
      {
        name: event?.currentTarget?.name,
        type: 'Request rejected',
      },
    ]
    setshowLoading(true)
    setToasts(item)
    console.log('Reject buddy req', event?.currentTarget?.name)
    // let notification = approveBuddyReq(event?.currentTarget?.name)
    setreqRejected(true)
    setTimeout(() => {
      setshowLoading(false)
      // props?.setshowSearchResults(false)
      setreqRejected(false)
    }, 2000)
  }
  useEffect(() => {
    console.log('Alert updated notifications')
    // if (window?.connectionObj?.connected === false) {
    if (connectionObj?.connected === false) {
      // window.connectionObj = null;
      //loadChatclient()
    }
    // let loogedInUser1 = JSON.parse(localStorage.getItem("user"));
    // setnotificationList_new(loogedInUser1?.notificationsList)
  }, [loogedInUser?.notificationsList, loadChatclient]) //eslint-disable-line

  return (
    <main className="main users chart-page" id="skip-target">
      <div className="container-fluid p-lg-4">
        <div className="p-0 mb-4">
          <h2 className="main-title">Notifications</h2>
        </div>
        {showLoading && <Loader />}
        {!showLoading && notificationList_new?.length === 0 && (
          <div
            className="Content"
            style={{
              width: '100%',
              height: 56,
              padding: 16,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 1,
              display: 'inline-flex',
            }}
          >
            <div
              className="Content"
              style={{
                flex: '1 1 0',
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 6,
                paddingBottom: 6,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 12,
                display: 'inline-flex',
              }}
            >
              <div className="This" style={{ alignSelf: 'stretch' }}>
                <span
                  style={{
                    color: '#6CBC6E',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  No notifications found!
                </span>
                <span
                  style={{
                    color: '#292929',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                </span>
                <span
                  style={{
                    color: '#292929',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  Tune to get more notifications.
                </span>
              </div>
            </div>
            <div
              className="Icon"
              style={{ width: 24, height: 24, position: 'relative' }}
            >
              <div
                className="X"
                style={{
                  width: 8,
                  height: 8,
                  left: 8,
                  top: 8,
                  position: 'absolute',
                  border: '1.50px #80849D solid',
                }}
              ></div>
            </div>
          </div>
        )}
        <div className="chat-list fullpagemessage">
          {notificationList_new?.map((item, i) => {
            console.log('Notification', item, i)
            return (
              <>
                {item?.type === 'subscribe' ? (
                  <a href="#" className="d-flex align-items-center" key={i}>
                    <div className="flex-shrink-0">
                      <img
                        className="img-fluid"
                        style={{ width: 45, height: 45, borderRadius: '50%' }}
                        src={
                          `data:image/png;base64, ${defaultImage}` ||
                          'https://mehedihtml.com/chatbox/assets/img/user.png'
                        }
                        alt="user img"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p>
                        <strong>{item?.jid}</strong> sent a request...
                      </p>
                    </div>
                    <div className="time12">
                      <button
                        className="notift-reject"
                        onClick={rejectBuddyReqFunc}
                        name={item?.jid}
                      >
                        Reject
                      </button>
                    </div>
                    <div className="time12 ms-2">
                      <button
                        className="notify-accept"
                        onClick={approveBuddyReqFunc}
                        name={item?.jid}
                      >
                        Accept
                      </button>
                    </div>
                  </a>
                ) : (
                  <div className="flex-grow-1 ms-3">
                    <p>
                      <strong>{item?.jid}</strong> Liked a post
                    </p>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </div>
      {/* {reqAccepted && <div style={{ position: 'fixed', width: "30%", left: "2%", bottom: "2%", borderRadius: "6px", backgroundColor: '#054757', padding: 6, display: 'flex', justifyContent: "center", alignItems: 'center', color: "#fff", }}>
                <h5 style={{ color: "white" }}>{`Request Accepted  successfully`}</h5>
            </div>}
            {reqRejected && <div style={{ position: 'fixed', width: "30%", left: "2%", bottom: "2%", borderRadius: "6px", backgroundColor: '#054757', padding: 6, display: 'flex', justifyContent: "center", alignItems: 'center', color: "#fff", }}>
                <h5 style={{ color: "white" }}>{`Request Rejected successfully`}</h5>
            </div>} */}
      {reqAccepted && <Toasts toastsList={toasts} />}
      {reqRejected && <Toasts toastsList={toasts} />}
    </main>
  )
}

export default Notifications

Notifications.propTypes = {
  connectionObj: PropTypes.object,
}
