import PropTypes from 'prop-types'
import * as React from 'react'

// project import

// assets
import { useTranslation } from 'react-i18next'

const PostsCard = (props) => {
  const { loading = false } = props //Simulate skeleton loading
  const { t } = useTranslation()
  let item = props.postsData

  return (
    <>
      <div
        className={loading ? 'loading-skeleton row' : 'row'}
        key={props?.key}
      >
        {/* <!-- Post section start --> */}
        <div className="col-lg-12">
          <div className="post-section">
            <div className="p-header">
              <div className="left">
                <img
                  src={`${item?.localPath}?w=96&h=96&fit=crop&auto=format&dpr=2 2x`}
                  alt=""
                />
                <span>{item?.userName}</span>
              </div>
              <div className="right">
                <li className="text">Posted on 24 hours</li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-three-dots"></i>
                  </a>
                </li>
              </div>
            </div>
            <p>{item?.body}</p>
            <div className="p-body">
              <div className="row g-0">
                <div className="col-lg-10">
                  {(item?.link === false || item.id !== 2) && (
                    <img src={item?.localPath} className="img-fluid" alt="" />
                  )}
                  {(item?.link === true || item.id === 2) && (
                    <iframe
                      width="100%"
                      height="480px"
                      src="https://www.youtube.com/embed/v0VGNi0r9Mo"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
                <div className="col-lg-2">
                  <div className="content">
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-heart"></i>
                      </a>
                      <span>
                        {t('like')}{' '}
                        {item?.reactions && item?.reactions[0]?.like}
                      </span>
                    </li>
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-chat"></i>
                      </a>
                      <span>
                        {t('comment')}{' '}
                        {item?.reactions && item?.reactions[1]?.comments}
                      </span>
                    </li>
                    <li className="">
                      <a href="/emptyLink">
                        <i className="bi bi-share"></i>
                      </a>
                      <span>
                        {t('share')}{' '}
                        {item?.reactions && item?.reactions[2]?.share}
                      </span>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-footer">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('comment_here')}
                />
                <button className="btn btn-post">{t('post')}</button>
              </div>
              <div className="icon">
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-emoji-smile"></i>
                    {t('emoji')}
                  </a>
                </li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-heart"></i>
                    {t('like')}
                  </a>
                </li>
                <li>
                  <a href="/emptyLink">
                    <i className="bi bi-share"></i>
                    {t('share')}
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Post section end --> */}
      </div>
    </>
  )
}

PostsCard.propTypes = {
  loading: PropTypes.bool,
  postsData: PropTypes.object,
  usersSuggestions: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  key: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

PostsCard.defaultProps = {
  color: 'primary',
}

export default PostsCard
