import PropTypes from 'prop-types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export default function BusinessProfilePageProgressStatus(props) {
  const { t } = useTranslation()
  let updateProfile = t('update_profile')
  let viewProfile = t('view_profile')
  return (
    <>
      <div className={props?.loading ? 'loading-skeleton row' : 'row'}>
        <div className="col">
          <div className="pBar">
            <div className="d-flex justify-content-between mb-2">
              <span>{t('keep_profile_updated')}</span>
              <NavLink
                // className={value === 0 ? 'aactive' : ''}
                style={{ display: 'flex', textDecoration: 'none' }}
                to={
                  window?.location?.href?.includes('profile')
                    ? `/businessupdateprofile`
                    : `/businessprofile`
                }
              >
                <span className="btn btn-default">
                  {window?.location?.href?.includes('profile')
                    ? updateProfile
                    : viewProfile}
                </span>
              </NavLink>
            </div>
            {/* <div
              className="progress"
              role="progressbar"
              aria-label="Warning example"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div className="progress-bar" style={{ width: "75%" }}>
                75%
              </div>
            </div> */}
          </div>
        </div>
        {/* <!-- user profile meter end --> */}
      </div>
    </>
  )
}

BusinessProfilePageProgressStatus.propTypes = {
  loading: PropTypes.bool,
}
