import { Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import { ProfileApis } from "../../API/Login/ProfileApi";
import Toasts from '../../Components/Toasts/Toasts'
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setauthorizationToken,
// } from "../../store/reducers/Users/users";
// import { dispatch } from "../../store/reducers/persist";
// import {
//   setFrProUser,
//   setFrProUserProfile,
// } from "../../store/reducers/Users/users";
import { StropheContext } from '../../Pages/ChatClient'
import useCountryAndStatesHook from '../../customHooks/useCountryAndStatesHook'

const Dashboard = (props) => {
  const [toasts, setToasts] = useState(null)
  const stropheContext = useContext(StropheContext)
  console.log('dashboard props', props, stropheContext)

  const { frProUser, emailVerified, numberVerified } = useSelector(
    (state) => state.Users
  )

  useCountryAndStatesHook(setToasts)

  // const [isCountriesLoaded, setisCountriesLoaded] = useState(false)

  // const navigate = useNavigate()

  // const [emailVerified] = useState(verificationStatus?.emailVerified);
  // const [numberVerified] = useState(verificationStatus?.numberVerified);
  // const [profileData, setprofileData] = useState(undefined);
  // const getUserProfile = (userObj) => {
  //   let token = {
  //     fre8ProAccessToken: userObj?.fre8ProAccessToken,
  //   };
  //   ProfileApis.getuserProfile(token).then((resProfile) => {
  //     dispatch(
  //       setauthorizationToken({
  //         authorizationToken: resProfile?.data?.fre8ProAccessToken,
  //       })
  //     );

  //     ProfileApis.userProfileImage(token)
  //       .then((resImg) => {
  //         dispatch(
  //           setFrProUser({
  //             frProUser: {
  //               ...frProUser,
  //               updateProfileForm: resProfile?.data,
  //               profileImg: `data:image/jpg;base64, ${resImg}`,
  //             },
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log("error", err);
  //       });
  //   });
  // };

  useEffect(() => {
    // console.log("props frpro user", frProUser);
    // let accessToken = JSON.parse(sessionStorage.getItem("accessToken") || {}) || null;
    // let loogedInUser = JSON.parse(localStorage.getItem("user"));
    // if ((!frProUser || (Object.keys(frProUser).length === 0 && frProUser?.constructor === Object))) {
    //   let item = [{
    //     name: "No signed in user found, please sign in again",
    //     type: "Session Invalidated"
    //   }]
    //   setToasts(item)
    //   //Clearing  strophe session
    //   var check = $pres({
    //     type: "unavailable",
    //   });
    //   window?.connectionObj?.send(check);
    //   window?.connectionObj?.disconnect();
    //   window.connectionObj = null;
    //   // alert("Session invalidated due refresh")
    //   // setTimeout(() => {
    //   navigate("/")
    //   // }, 00);
    // } else {
    //   // userEmailStatus(frProUser);
    //   // userMobileStatus(frProUser);
    //   // getUserProfile();
    //   dispatch(setFrProUser({ frProUser: loogedInUser }));
    //   setTimeout(() => {
    //     userEmailStatus(frProUser || loogedInUser);
    //     userMobileStatus(frProUser || loogedInUser);
    //     getUserProfile(frProUser || loogedInUser);
    //   }, 3000)
    // }
    // dispatch(setFrProUser({ frProUser: loogedInUser }));
    // getUserProfile(frProUser || loogedInUser);
  }, [emailVerified, numberVerified]) //eslint-disable-line

  // useEffect(() => {
  //     userEmailStatus(frProUser);
  //     userMobileStatus(frProUser);

  // }, []) //eslint-disable-line

  return (
    <>
      {/* <GetUserProfile /> */}
      <main className="main users chart-page">
        {toasts ? <Toasts toastsList={toasts} /> : null}
        <div className="container-fluid p-lg-4">
          <div className="p-0 mb-4">
            <h2 className="main-title">Welcome</h2>
          </div>
          <div className="row">
            <div className="col-12 col-lg-9 mx-lg-auto">
              <div className="text-center mb-4 title">
                <h2>
                  Hi{' '}
                  {frProUser?.updateProfileForm?.firstName
                    ? frProUser?.updateProfileForm?.firstName
                    : frProUser?.updateProfileForm?.personalInfo?.firstName
                      ? frProUser?.updateProfileForm?.personalInfo?.firstName
                      : frProUser?.updateProfileForm?.businessName}{' '}
                  {'  '}
                  {frProUser?.updateProfileForm?.lastName
                    ? frProUser?.updateProfileForm?.lastName
                    : frProUser?.updateProfileForm?.personalInfo?.lastName}
                  , Welcome to Fre8!
                </h2>
                <p>
                  Please complete the following to get started with us.
                  <br className="d-none d-lg-block" /> We’ll take you through it
                  step by step.{' '}
                </p>
              </div>

              <div className="contact-box d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center">
                  <span className="circle32">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M1.33301 9.66667L7.99967 13L14.6663 9.66667M7.99967 3L1.33301 6.33333L7.99967 9.66667L14.6663 6.33333L7.99967 3Z"
                        stroke="#054757"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <div className="ms-2">
                    <Typography
                      component={Link}
                      to={
                        frProUser?.userCategory === 'Fleet Owner'
                          ? '/businessupdateprofile'
                          : '/updateprofile'
                      }
                    >
                      <strong>Complete personal details</strong>
                      <p>We’ll need this to setting up your account</p>
                    </Typography>
                  </div>
                </div>
                <div>
                  <img src="images/check-base.png" alt="" />
                </div>
              </div>
              <div
                className={
                  numberVerified
                    ? 'contact-box d-flex justify-content-between align-items-center active'
                    : ' contact-box d-flex justify-content-between align-items-center'
                }
              >
                <div className="d-flex align-items-center">
                  <span className="circle32">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1821_3160)">
                        <path
                          d="M14.6669 11.2797V13.2797C14.6677 13.4654 14.6297 13.6492 14.5553 13.8193C14.4809 13.9894 14.3718 14.1421 14.235 14.2676C14.0982 14.3932 13.9367 14.4887 13.7608 14.5482C13.5849 14.6077 13.3985 14.6298 13.2136 14.6131C11.1622 14.3902 9.19161 13.6892 7.46028 12.5664C5.8495 11.5428 4.48384 10.1772 3.46028 8.56641C2.3336 6.82721 1.63244 4.84707 1.41361 2.78641C1.39695 2.60205 1.41886 2.41625 1.47795 2.24082C1.53703 2.0654 1.63199 1.9042 1.75679 1.76749C1.88159 1.63077 2.03348 1.52155 2.20281 1.44675C2.37213 1.37196 2.55517 1.33325 2.74028 1.33307H4.74028C5.06382 1.32989 5.37748 1.44446 5.62279 1.65543C5.8681 1.8664 6.02833 2.15937 6.07361 2.47974C6.15803 3.11978 6.31458 3.74822 6.54028 4.35307C6.62998 4.59169 6.64939 4.85102 6.59622 5.10033C6.54305 5.34964 6.41952 5.57848 6.24028 5.75974L5.39361 6.60641C6.34265 8.27544 7.72458 9.65737 9.39361 10.6064L10.2403 9.75974C10.4215 9.5805 10.6504 9.45697 10.8997 9.4038C11.149 9.35063 11.4083 9.37004 11.6469 9.45974C12.2518 9.68544 12.8802 9.84199 13.5203 9.92641C13.8441 9.97209 14.1399 10.1352 14.3513 10.3847C14.5627 10.6343 14.6751 10.9528 14.6669 11.2797Z"
                          stroke="#054757"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1821_3160">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <div className="ms-2">
                    <Typography
                      component={Link}
                      className={''}
                      to="/verificationmobile"
                      // color="textSecondary"
                      // variant="h6"
                      sx={{
                        textDecoration: 'none',
                        fontSize: '1rem',
                        margin: '12px 0',
                      }}
                    >
                      <strong>Phone number verification</strong>
                      <p>Help us to ensure your phone number is genuine</p>
                    </Typography>
                  </div>
                </div>
                <div>
                  {numberVerified ? (
                    <img src="images/verif-done.svg" alt="" />
                  ) : (
                    <img src="images/check-base.png" alt="" />
                  )}
                </div>
              </div>
              <div
                className={
                  emailVerified
                    ? 'contact-box d-flex justify-content-between align-items-center active'
                    : 'contact-box d-flex justify-content-between align-items-center'
                }
              >
                <div className="d-flex align-items-center">
                  <span className="circle32">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14.6663 4.00033C14.6663 3.26699 14.0663 2.66699 13.333 2.66699H2.66634C1.93301 2.66699 1.33301 3.26699 1.33301 4.00033M14.6663 4.00033V12.0003C14.6663 12.7337 14.0663 13.3337 13.333 13.3337H2.66634C1.93301 13.3337 1.33301 12.7337 1.33301 12.0003V4.00033M14.6663 4.00033L7.99967 8.66699L1.33301 4.00033"
                        stroke="#054757"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <div className="ms-2"></div>
                  <Typography
                    component={Link}
                    className={''}
                    to="/verificationemail"
                    // color="textSecondary"
                    // variant="h6"
                    sx={{
                      textDecoration: 'none',
                      fontSize: '1rem',
                      margin: '12px 0',
                    }}
                  >
                    {' '}
                    Email verification
                    <p>
                      Help us to ensure that the email is genuine that we can
                      use to send updates on your account
                    </p>
                  </Typography>
                </div>
                <div>
                  {emailVerified ? (
                    <img src="images/verif-done.svg" alt="" />
                  ) : (
                    <img src="images/check-base.png" alt="" />
                  )}
                  {/* <img src="images/check-base.png" alt='' /> */}
                </div>
              </div>
              <div
                className={
                  'contact-box d-flex justify-content-between align-items-center '
                }
              >
                <div className="d-flex align-items-center">
                  <span className="circle32">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.667 14V12.6667C10.667 11.9594 10.386 11.2811 9.88594 10.781C9.38585 10.281 8.70757 10 8.00033 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14M11.3337 7.33333L12.667 8.66667L15.3337 6M8.33366 4.66667C8.33366 6.13943 7.13975 7.33333 5.66699 7.33333C4.19423 7.33333 3.00033 6.13943 3.00033 4.66667C3.00033 3.19391 4.19423 2 5.66699 2C7.13975 2 8.33366 3.19391 8.33366 4.66667Z"
                        stroke="#054757"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <div className="ms-2">
                    <strong>ID verification</strong>
                    <p>
                      Verify your official id to increase your chances of
                      employment and use all services within Fr8
                    </p>
                  </div>
                </div>
                <div>
                  <img src="images/check-base.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="contact-box d-flex justify-content-between align-items-center active">
          <div className="d-flex align-items-center">
            <span className="circle32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <g clipPath="url(#clip0_1821_3160)">
                  <path
                    d="M14.6669 11.2797V13.2797C14.6677 13.4654 14.6297 13.6492 14.5553 13.8193C14.4809 13.9894 14.3718 14.1421 14.235 14.2676C14.0982 14.3932 13.9367 14.4887 13.7608 14.5482C13.5849 14.6077 13.3985 14.6298 13.2136 14.6131C11.1622 14.3902 9.19161 13.6892 7.46028 12.5664C5.8495 11.5428 4.48384 10.1772 3.46028 8.56641C2.3336 6.82721 1.63244 4.84707 1.41361 2.78641C1.39695 2.60205 1.41886 2.41625 1.47795 2.24082C1.53703 2.0654 1.63199 1.9042 1.75679 1.76749C1.88159 1.63077 2.03348 1.52155 2.20281 1.44675C2.37213 1.37196 2.55517 1.33325 2.74028 1.33307H4.74028C5.06382 1.32989 5.37748 1.44446 5.62279 1.65543C5.8681 1.8664 6.02833 2.15937 6.07361 2.47974C6.15803 3.11978 6.31458 3.74822 6.54028 4.35307C6.62998 4.59169 6.64939 4.85102 6.59622 5.10033C6.54305 5.34964 6.41952 5.57848 6.24028 5.75974L5.39361 6.60641C6.34265 8.27544 7.72458 9.65737 9.39361 10.6064L10.2403 9.75974C10.4215 9.5805 10.6504 9.45697 10.8997 9.4038C11.149 9.35063 11.4083 9.37004 11.6469 9.45974C12.2518 9.68544 12.8802 9.84199 13.5203 9.92641C13.8441 9.97209 14.1399 10.1352 14.3513 10.3847C14.5627 10.6343 14.6751 10.9528 14.6669 11.2797Z"
                    stroke="#054757"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1821_3160">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <div className="ms-2">
              <strong>Phone number verification</strong>
              <p>Help us to ensure your phone number is genuine</p>
            </div>
          </div>
          <div>
            <img src="images/verif-done.svg" alt="" />
          </div>
        </div>
        <div className="contact-box d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="circle32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M14.6663 4.00033C14.6663 3.26699 14.0663 2.66699 13.333 2.66699H2.66634C1.93301 2.66699 1.33301 3.26699 1.33301 4.00033M14.6663 4.00033V12.0003C14.6663 12.7337 14.0663 13.3337 13.333 13.3337H2.66634C1.93301 13.3337 1.33301 12.7337 1.33301 12.0003V4.00033M14.6663 4.00033L7.99967 8.66699L1.33301 4.00033"
                  stroke="#054757"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="ms-2"></div>
            <Typography
              component={Link}
              className={""}
              to="/verificationemail"
              // color="textSecondary"
              // variant="h6"
              sx={{
                textDecoration: "none",
                fontSize: "1rem",
                margin: "12px 0",
              }}>
              {" "}
              Email verification
              <p>
                Help us to ensure that the email is genuine that we can use to
                send updates on your account
              </p>
            </Typography>
          </div>
          <div>
            <img src="images/check-base.png" alt="" />
          </div>
        </div>
        <div className="contact-box d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="circle32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M10.667 14V12.6667C10.667 11.9594 10.386 11.2811 9.88594 10.781C9.38585 10.281 8.70757 10 8.00033 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14M11.3337 7.33333L12.667 8.66667L15.3337 6M8.33366 4.66667C8.33366 6.13943 7.13975 7.33333 5.66699 7.33333C4.19423 7.33333 3.00033 6.13943 3.00033 4.66667C3.00033 3.19391 4.19423 2 5.66699 2C7.13975 2 8.33366 3.19391 8.33366 4.66667Z"
                  stroke="#054757"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="ms-2">
              <strong>ID verification</strong>
              <p>
                Verify your official id to increase your chances of employment
                and use all services within Fr8
              </p>
            </div>
          </div>
          <div>
            <img src="images/check-base.png" alt="" />
          </div>
        </div> */}
      </main>
    </>
  )
}

export default Dashboard
