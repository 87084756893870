import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import { Slider, Typography, Dialog, DialogContent } from '@mui/material'
import getCroppedImg from '../ImageCropper/CropperUtility'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { setFrProUser } from '../../store/reducers/Users/users'
import Toasts from '../Toasts/Toasts'
import ErrorHandle from '../ErrorHandle'

const allowedTypes = ['image/jpeg', 'image/png']

const UploadProfileImage = ({
  classes,
  openModal,
  setOpenModal,
  setProfileImg,
  profileImg = null,
}) => {
  const { frProUser } = useSelector((state) => state.Users)
  const dispatch = useDispatch()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  // const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [errorType, setErrorType] = useState('')
  const [progress, setProgress] = useState(0)
  const [toasts, setToasts] = useState(null)

  const handleUploadImage = (e) => {
    setErrorType('')
    setProgress('')
    setImage(null)
    setImageUrl(null)
    e.preventDefault()
    let file = e?.target?.files[0]
    if (file) {
      setImage(file)
      const fileSize = file?.size / (1024 * 1024)
      if (fileSize > 5) {
        setErrorType('FILE_SIZE')
        return
      } else if (
        file?.type === '' ||
        file?.type === undefined ||
        !allowedTypes.includes(file?.type)
      ) {
        setErrorType('FILE_TYPE')
        return
      }
      const reader = new FileReader()
      try {
        reader.onload = (e) => {
          const interval = setInterval(() => {
            setProgress((prevProgress) => {
              console.log(prevProgress, 'prog_________')
              const newProgress = +prevProgress + 20
              if (prevProgress === 100) {
                clearInterval(interval)

                setImageUrl(e?.target?.result)
              }
              // Divide 100% into 10 parts
              return newProgress > 100 ? 100 : newProgress
            })
          }, 300) //updates every 0.4 sec
        }
        reader.readAsDataURL(file)
      } catch (err) {
        console.debug(err)
      }
    }
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const zoomPercentage = (val) => {
    return `${Math.round(val * 100)}%`
  }

  const uploadProfileImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, 0)
      // setImageUrl(croppedImage?.url);
      setImage(croppedImage?.file)

      const formData22 = new FormData()
      formData22.append('profileImage', croppedImage?.file)

      let dataDesc = {
        description: 'file description if available',
        fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      }
      formData22.append(
        'profileImageForm',
        new Blob([JSON.stringify(dataDesc)], {
          type: 'application/json',
        })
      )
      ProfileApis.userProfileUploadImage(formData22)
        .then((res) => {
          dispatch(
            setFrProUser({
              frProUser: {
                ...frProUser,
                profileImg: imageUrl,
              },
            })
          )
          setProfileImg(imageUrl)
          setImage(null)
          setImageUrl(null)
          setOpenModal(false)
          setErrorType('')
          setProgress(0)
        })
        .catch((err) => {
          let customMessage = 'User image update failed'
          let type = 'User Profile'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
        })
    } catch (err) {
      console.debug('Error', err)
      let customMessage = 'User profile image crop failed'
      let type = 'User Profile'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)
    }
  }
  useEffect(() => {
    if (profileImg !== null) setImageUrl(profileImg)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])
  return (
    <>
      <Dialog
        fullWidth="md"
        maxWidth="sm"
        open={openModal}
        onClose={() => {
          setImage(null)
          setImageUrl(null)
          setOpenModal(false)
          setProgress(0)
        }}
      >
        <DialogContent>
          <div>
            {!imageUrl && (
              <>
                <h1
                  className="fs-5 fw-semibold modal-title"
                  id="coverPhotoModalLabel"
                >
                  Profile photo
                </h1>
                <p className="m-0 mt-2">Upload a new profile photo</p>

                <div className="border my-3 p-3 rounded-3 text-center">
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#F2F4F7"
                    ></rect>
                    <rect
                      x="3"
                      y="3"
                      width="40"
                      height="40"
                      rx="20"
                      stroke="#F9FAFB"
                      strokeWidth="6"
                    ></rect>
                    <g clipPath="url(#clip0_2236_1419)">
                      <path
                        d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                        stroke="#475467"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_2236_1419">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(13 13)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>

                  <p className="mb-1">
                    <label htmlFor="customFileInput">
                      <input
                        type="file"
                        id="customFileInput"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleUploadImage(e)}
                      />{' '}
                      <strong>Click to upload</strong>
                    </label>{' '}
                    or drag and drop
                  </p>
                  <p className="mb-0 small">
                    PNG or JPG (max. 400x400px). Max 5MB file size.
                  </p>
                </div>
                {!errorType && image ? (
                  <div className=" border  d-flex my-3 p-3 rounded-3 mb-3">
                    <div className="me-3">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="32"
                          height="32"
                          rx="16"
                          fill="#B3F0FF"
                        ></rect>
                        <rect
                          x="2"
                          y="2"
                          width="32"
                          height="32"
                          rx="16"
                          stroke="#E6FAFF"
                          strokeWidth="4"
                        ></rect>
                        <path
                          d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
                          stroke="#054757"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div className="flex-grow-1">
                      <div className="fw-medium mb-1 ">{image?.name}</div>
                      <p className="mb-2 text-muted">
                        {(image?.size / (1024 * 1024)).toFixed(2)} MB
                      </p>
                      <div className="align-items-center d-flex">
                        <div
                          className="progress w-100"
                          style={{ height: '8px' }}
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            className="progress-bar"
                            style={{
                              width: `${progress}%`,
                              backgroundColor: '#054757',
                            }}
                          ></div>
                        </div>
                        <span className="ms-2 small"> {`${progress}%`}</span>
                      </div>
                    </div>

                    <div>
                      <button
                        className="btn p-0"
                        onClick={() => {
                          setErrorType('')
                          setImage(null)
                          setProgress(0)
                        }}
                      >
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6666C12.1667 25.1087 12.3423 25.5326 12.6548 25.8451C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8451C23.6577 25.5326 23.8333 25.1087 23.8333 24.6666V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4673 15.1548 10.1548C15.4674 9.84222 15.8913 9.66663 16.3333 9.66663H19.6667C20.1087 9.66663 20.5326 9.84222 20.8452 10.1548C21.1577 10.4673 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1666V22.1666M19.6667 17.1666V22.1666"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : errorType ? (
                  <div
                    className=" border  d-flex my-3 p-3 rounded-3 mb-3 alert alert-danger"
                    role="alert"
                  >
                    <div className="me-3">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="32"
                          height="32"
                          rx="16"
                          fill="#B3F0FF"
                        ></rect>
                        <rect
                          x="2"
                          y="2"
                          width="32"
                          height="32"
                          rx="16"
                          stroke="#E6FAFF"
                          strokeWidth="4"
                        ></rect>
                        <path
                          d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
                          stroke="#054757"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div className="flex-grow-1">
                      <div className="fw-medium mb-1 ">
                        Upload failed, please try again.
                      </div>
                      <p className="mb-2 text-muted">{image?.name}</p>
                      <div className="align-items-center d-flex">
                        {errorType === 'FILE_SIZE' &&
                          'File size is above 5 MB.'}
                        {errorType === 'FILE_TYPE' &&
                          'Incorrect image format, upload .PNG or .JPEG image.'}
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn p-0"
                        onClick={() => {
                          setErrorType('')
                          setImage(null)
                          setProgress(0)
                        }}
                      >
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6666C12.1667 25.1087 12.3423 25.5326 12.6548 25.8451C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8451C23.6577 25.5326 23.8333 25.1087 23.8333 24.6666V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4673 15.1548 10.1548C15.4674 9.84222 15.8913 9.66663 16.3333 9.66663H19.6667C20.1087 9.66663 20.5326 9.84222 20.8452 10.1548C21.1577 10.4673 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1666V22.1666M19.6667 17.1666V22.1666"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
            {imageUrl && (
              <div className="mb-3">
                <div
                  className="text-center"
                  style={{
                    background: '#333',
                    position: 'relative',
                    height: 400,
                    width: 'auto',
                    minWidth: { sm: 500 },
                  }}
                >
                  <div className={classes?.cropContainer}>
                    <Cropper
                      className=""
                      image={imageUrl}
                      crop={crop}
                      // rotation={rotation}
                      zoom={zoom}
                      aspect={1 / 1}
                      onCropChange={setCrop}
                      // onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                </div>

                <div className={classes?.controls}>
                  <div className={classes?.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes?.sliderLabel }}
                    >
                      Zoom {zoomPercentage(zoom)}
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      classes={{ root: classes?.slider }}
                      onChange={(e, zoom) => setZoom(zoom)}
                      valueLabelDisplay="auto"
                      valueLabelFormat={zoomPercentage}
                    />
                  </div>
                  {/* <div className={classes?.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes?.sliderLabel }}
                  >
                    Rotation
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    classes={{ root: classes?.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div> */}
                </div>
              </div>
            )}

            <div className="d-flex">
              <button
                type="button"
                className="btn flex-grow-1 btn-border me-2"
                onClick={() => {
                  setErrorType('')
                  setImage(null)
                  setImageUrl(null)
                  setOpenModal(false)
                  setProgress(0)
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn flex-grow-1 btn-theme ms-2"
                disabled={!imageUrl}
                onClick={uploadProfileImage}
              >
                Upload profile photo
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

UploadProfileImage.propTypes = {
  classes: PropTypes.object,
  imageUrl: PropTypes.string,
  cropContainer: PropTypes.object,
  controls: PropTypes.object,
  sliderContainer: PropTypes.object,
  sliderLabel: PropTypes.object,
  slider: PropTypes.object,
  cropButton: PropTypes.object,
  setOpenModal: PropTypes.func,
  openModal: PropTypes.bool,
  frProUser: PropTypes.object,
  setProfileImg: PropTypes.func,
  profileImg: PropTypes.string,
}
export default UploadProfileImage
