import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FleetBusinessOwnershipView from './FleetBusinessOwnershipView'
import FleetBusinessOwnerShipFormik from './FleetBusinessOwnerShipFormik'
import { useSelector } from 'react-redux'
import Toasts from '../../../../Components/Toasts/Toasts'

const FleetBusinessOwnerShip = ({ setSelection }) => {
  const { frProUser } = useSelector((state) => state.Users)
  const [toasts, setToasts] = useState(null)
  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.youOwnerOfTheFleet ? true : false
  )
  const deleteFleetOwnerShipData = {
    ...frProUser?.updateProfileForm,
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    youOwnerOfTheFleet: '',
    firstName: '',
    lastName: '',
    gender: '',
    designation: '',
  }

  return (
    //This is a wrapper For FleetBusinessOwnershipView And FleetBusinessOwnerShipFormik
    <div>
      {saving ? (
        <FleetBusinessOwnershipView
          setSaving={setSaving}
          setSelection={setSelection}
          deleteFleetOwnerShipData={deleteFleetOwnerShipData}
          setToasts={setToasts}
        />
      ) : (
        <FleetBusinessOwnerShipFormik
          setSaving={setSaving}
          setSelection={setSelection}
          setToasts={setToasts}
        />
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </div>
  )
}

export default FleetBusinessOwnerShip

FleetBusinessOwnerShip.propTypes = {
  setSelection: PropTypes.func,
}
