import { Form, Formik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputField from '../../../Components/inputField'
import * as Yup from 'yup'
import { setFrProUser } from '../../../store/reducers/Users/users'
import verifdone from '../../../css/images/verif-done.svg'
import { ProfileApis } from '../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import CustomPencilSvg from '../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import Toasts from '../../../Components/Toasts/Toasts'
import ErrorHandle from '../../../Components/ErrorHandle'
// import axios from "axios";

const FleetDocumentation = ({ setSelection }) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  const [saving, setSaving] = useState(frProUser?.documentName ? true : false)
  const [toasts, setToasts] = useState(null)

  // const [progress, setProgress] = useState({ started: false, progress: 0 });
  const [file, setFile] = useState(
    frProUser?.updateProfileForm?.FleetBusinessDocument?.document
      ? frProUser?.updateProfileForm?.FleetBusinessDocument?.document
      : ''
  )

  const handleUploadDocument = async (e) => {
    const file = e.target.files[0]
    console.log(file)
    if (!file) {
      return
    }
    try {
      console.log('jasdad')
      const upload = URL.createObjectURL(file)
      dispatch(
        setFrProUser({
          frProUser: {
            ...frProUser,
            updateProfileForm: {
              ...frProUser?.updateProfileForm,
              FleetBusinessDocument: {
                ...frProUser?.updateProfileForm?.FleetBusinessDocument,
                document: upload,
              },
            },
          },
        })
      )
      setFile(upload)
      // const data = await axios.post("url", file, {
      //   onUploadProgress: (progressEvent) => {
      //     setProgress((prev) => {
      //       return { ...prev, progress: progressEvent.progress * 100 };
      //     });
      //   },
      // });

      // console.log(data);
    } catch (error) {
      console.log(error)
    }
  }
  const deleteFleetDocumentationData = {
    // ...frProUser?.updateProfileForm,
    // fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    documentName: '',
    nameOfDocumentHolder: '',
    secondaryMobileNumber: '',
  }
  return (
    <div>
      <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
        <div className="row">
          {saving ? (
            <div className="col-md-12">
              <div className="d-flex justify-content-between px-4 pt-4">
                <h5 className="fw-bold mb-3">Documentation</h5>
                <div>
                  <button onClick={() => setSaving(false)} className=" p-0">
                    <CustomPencilSvg />
                  </button>
                  <button
                    onClick={() => {
                      // ProfileApis.updateFleetOwnerUserProfile(
                      //   deleteFleetDocumentationData
                      // ).then(() => {

                      // });
                      dispatch(
                        setFrProUser({
                          frProUser: {
                            ...frProUser,
                            // updateProfileForm: {
                            //   ...frProUser?.updateProfileForm,
                            ...deleteFleetDocumentationData,
                            // },
                          },
                        })
                      )
                      setSelection('')
                    }}
                  >
                    <CustomDeleteSvg />
                  </button>
                </div>
              </div>

              <button className="contact-box d-flex justify-content-between align-items-center uploaded w-25">
                <div className="d-flex align-items-center">
                  <span className="circle32">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.66663 1.33301H3.99996C3.64634 1.33301 3.3072 1.47348 3.05715 1.72353C2.8071 1.97358 2.66663 2.31272 2.66663 2.66634V13.333C2.66663 13.6866 2.8071 14.0258 3.05715 14.2758C3.3072 14.5259 3.64634 14.6663 3.99996 14.6663H12C12.3536 14.6663 12.6927 14.5259 12.9428 14.2758C13.1928 14.0258 13.3333 13.6866 13.3333 13.333V5.99967M8.66663 1.33301L13.3333 5.99967M8.66663 1.33301V5.99967H13.3333"
                        stroke="#054757"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <div className="ms-2 text-start">
                    <strong>
                      {frProUser?.documentName}
                      .pdf
                    </strong>
                    <strong>
                      <a
                        href={file}
                        download="SR detailed advertisement.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                      {/* <u></u> */}
                    </strong>
                  </div>
                </div>
                <div>
                  <img src={verifdone} alt="verifdone" />
                </div>
              </button>
            </div>
          ) : (
            <>
              <div className="col-md-4">
                <h5 className="fw-bold mb-3">Documentation</h5>
              </div>
              <div className="col-md-7 ms-auto">
                <div className="row g-4">
                  <Formik
                    initialValues={{
                      documentName: frProUser?.documentName,
                      NameOfDocumentHolder: frProUser?.nameOfDocumentHolder,
                      secondaryPhoneNumber: frProUser?.secondaryMobileNumber,
                    }}
                    validationSchema={Yup.object().shape({
                      // firstname: Yup.string(),
                    })}
                    onSubmit={async (
                      values,
                      { setErrors, setStatus, setSubmitting }
                    ) => {
                      const documentData = {
                        documentName: values?.documentName,
                        nameOfDocumentHolder: values?.NameOfDocumentHolder,
                        secondaryMobileNumber: values?.secondaryPhoneNumber,
                      }

                      try {
                        const data = {
                          ...frProUser?.updateProfileForm,
                          fre8ProAccessToken: frProUser?.fre8ProAccessToken,
                          ...documentData,
                        }
                        console.log('data..........', data)
                        ProfileApis.updateFleetOwnerUserProfile(data)
                          .then(() => {
                            dispatch(
                              setFrProUser({
                                frProUser: {
                                  ...frProUser,
                                  ...documentData,
                                },
                              })
                            )
                          })
                          .catch((err) => {
                            let customMessage =
                              'User Fleet documentation update failed'
                            let type = 'User Profile'
                            let color = 'red'
                            ErrorHandle(
                              err,
                              setToasts,
                              customMessage,
                              type,
                              color
                            )
                          })
                        setSaving(true)
                      } catch (err) {
                        console.error(err)
                        let customMessage =
                          'User Fleet documentation update failed'
                        let type = 'User Profile'
                        let color = 'red'
                        ErrorHandle(err, setToasts, customMessage, type, color)
                      }
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form>
                        <div className="col-md-12  mb-2">
                          <h3 className="font18-black py-3">
                            Upload articles of business
                          </h3>
                          <label className="form-label">Document name</label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'documentName'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>

                        <div className="col-md-12  mb-2">
                          <label className="form-label">
                            Name of document holder
                          </label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'NameOfDocumentHolder'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>

                        <div className="col-md-12 col-lg-8 mb-2">
                          <label className="form-label">
                            Secondary phone number
                          </label>

                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'secondaryPhoneNumber'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div
                          style={{ position: 'relative' }}
                          className="col-md-12"
                        >
                          <button
                            type="button"
                            className="border p-3 rounded-3 text-center w-100"
                          >
                            <input
                              style={{
                                position: 'absolute',
                                top: '15%',
                                left: '45%',
                                opacity: '0',
                              }}
                              type="file"
                              onChange={(e) => handleUploadDocument(e)}
                            />
                            <svg
                              width="46"
                              height="46"
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="40"
                                height="40"
                                rx="20"
                                fill="#F2F4F7"
                              ></rect>
                              <rect
                                x="3"
                                y="3"
                                width="40"
                                height="40"
                                rx="20"
                                stroke="#F9FAFB"
                                strokeWidth="6"
                              ></rect>
                              <g clipPath="url(#clip0_2236_1419)">
                                <path
                                  d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                                  stroke="#475467"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_2236_1419">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(13 13)"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>

                            <p className="mb-1">
                              <strong>Click to upload</strong> or drag and drop
                            </p>
                            <p className="mb-0 small">
                              DOC. Max 5MB file size.
                            </p>
                          </button>
                        </div>
                        {saving && (
                          <div className="col-md-12">
                            <div className=" border  d-flex p-3 rounded-3 mb-3">
                              <div className="me-3">
                                <svg
                                  width="36"
                                  height="36"
                                  viewBox="0 0 36 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="2"
                                    y="2"
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#B3F0FF"
                                  ></rect>
                                  <rect
                                    x="2"
                                    y="2"
                                    width="32"
                                    height="32"
                                    rx="16"
                                    stroke="#E6FAFF"
                                    strokeWidth="4"
                                  ></rect>
                                  <path
                                    d="M13.3333 24H22.6667C23.403 24 24 23.403 24 22.6667V13.3333C24 12.597 23.403 12 22.6667 12H13.3333C12.597 12 12 12.597 12 13.3333V22.6667C12 23.403 12.597 24 13.3333 24ZM13.3333 24L20.6667 16.6667L24 20M16.6667 15.6667C16.6667 16.219 16.219 16.6667 15.6667 16.6667C15.1144 16.6667 14.6667 16.219 14.6667 15.6667C14.6667 15.1144 15.1144 14.6667 15.6667 14.6667C16.219 14.6667 16.6667 15.1144 16.6667 15.6667Z"
                                    stroke="#054757"
                                    strokeWidth="1.33333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </div>
                              <div className="flex-grow-1">
                                <div className="fw-medium mb-1 ">
                                  FleetNYarticleofbusiness.pdf
                                </div>
                                <p className="mb-2 text-muted">16 MB</p>
                                <div className="align-items-center d-flex">
                                  <div
                                    className="progress w-100"
                                    style={{ height: '8px' }}
                                    role="progressbar"
                                    aria-label="Basic example"
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{
                                        width: `${16}%`,
                                        backgroundColor: '#054757',
                                      }}
                                    ></div>
                                  </div>
                                  <span className="ms-2 small">40%</span>
                                </div>
                              </div>
                              <div>
                                <button className="btn p-0">
                                  <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.5 13H12.1667M12.1667 13H25.5M12.1667 13V24.6666C12.1667 25.1087 12.3423 25.5326 12.6548 25.8451C12.9674 26.1577 13.3913 26.3333 13.8333 26.3333H22.1667C22.6087 26.3333 23.0326 26.1577 23.3452 25.8451C23.6577 25.5326 23.8333 25.1087 23.8333 24.6666V13H12.1667ZM14.6667 13V11.3333C14.6667 10.8913 14.8423 10.4673 15.1548 10.1548C15.4674 9.84222 15.8913 9.66663 16.3333 9.66663H19.6667C20.1087 9.66663 20.5326 9.84222 20.8452 10.1548C21.1577 10.4673 21.3333 10.8913 21.3333 11.3333V13M16.3333 17.1666V22.1666M19.6667 17.1666V22.1666"
                                      stroke="#667085"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="mt-4">
                          <button type="submit" className="btn btn-theme me-2">
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-border"
                            onClick={() =>
                              frProUser?.documentName
                                ? setSaving(true)
                                : setSelection('')
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {toasts && <Toasts toastsList={toasts} />}
    </div>
  )
}

export default FleetDocumentation
FleetDocumentation.propTypes = {
  setSelection: PropTypes.func,
}
