import React, { useEffect } from 'react'

const ErrorLayoutPage = (props) => {
  // const navigate = useNavigate()
  console.log('Error', props)
  useEffect(() => {
    // navigate("/error")
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* <HeaderContent /> */}

      {/* <Error errorDetails={props} /> */}
      {/* <FooterLayout /> */}
    </>
  )
}

export default ErrorLayoutPage
