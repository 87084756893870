import { FormHelperText } from '@mui/material'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

const InputField = ({
  add = '',
  tag = '',
  type = '',
  values,
  editMode = 'false',
  name,
  touched,
  errors = '',
  children,
  radVal = '',
  handleCountryChange = '',
  checkVal = '',
  placeholder = '',
  rows = '',
  id,
  onChange = undefined,
  onBlur = () => {},
  maxLength,
  disabled = false,
}) => {
  useEffect(() => {
    if (typeof handleCountryChange === 'function') {
      handleCountryChange(values[name])
    }
  }, [values[name]]) //eslint-disable-line react-hooks/exhaustive-deps

  const { handleChange, setFieldTouched } = useFormikContext()
  return (
    <>
      <Field
        id={id}
        as={tag}
        rows={rows}
        type={type}
        className={
          add
            ? add
            : tag === 'select'
            ? 'form-select'
            : type === 'radio'
            ? 'form-check-input'
            : type === 'checkbox'
            ? 'form-check-input'
            : 'form-control'
        }
        aria-label={tag ? 'Default select example' : ''}
        placeholder={placeholder ? placeholder : `Enter a ${name}`}
        value={
          (editMode || tag) &&
          type !== 'radio' &&
          type !== 'file' &&
          type !== 'checkbox'
            ? values[name]
            : type === 'radio'
            ? radVal
            : type === 'file'
            ? values[name]
            : type === 'checkbox'
            ? checkVal
            : ''
        }
        //  disabled={editMode ? false : true}
        disabled={disabled}
        name={name}
        onChange={onChange || handleChange}
        onBlur={() => {
          onBlur()
          setFieldTouched(name, true, true)
        }}
        maxLength={maxLength}
      >
        {children}
      </Field>
      {touched[name] && errors[name] && (
        <FormHelperText error id={`helper-text-${name}`}>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  )
}

InputField.propTypes = {
  values: PropTypes.object,
  editMode: PropTypes.bool,
  name: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  type: PropTypes.string,
  tag: PropTypes.string,
  children: PropTypes.node,
  radVal: PropTypes.string,
  handleCountryChange: PropTypes.func,
  checkVal: PropTypes.string,
  add: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
}

export default InputField
