import React from 'react'

const Messages = (props) => {
  console.log(props)
  return (
    <main className="main users chart-page" id="skip-target">
      <div className="container-fluid p-lg-4">
        <div className="p-0 mb-4">
          <h2 className="main-title">Message</h2>
        </div>
        <div className="chat-list fullpagemessage">
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Mehedi Hasan</h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Ryhan</h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Malek Hasan</h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 hr</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Sadik Hasan</h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Bulu </h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Bulu </h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Bulu </h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Bulu </h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
          <a href="#" className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                className="img-fluid"
                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                alt="user img"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>Bulu </h3>
              <p>Hello how are you!</p>
            </div>
            <div className="time12">10 mins</div>
          </a>
        </div>
      </div>
    </main>
  )
}

export default Messages
