import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BuddiesUserCard from '../cards/differentCards/BuddiesUserCard'
import LiveUserCard from '../cards/differentCards/LiveUserCard'

const CustomLiveUserCardWrapper = (props) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={props?.loading ? 'loading-skeleton row' : 'row'}>
        {/* <!-- Left side buddy section start --> */}
        <div className="col-lg-12">
          <div
            className={
              props.cardType === 'buddies'
                ? 'l-buddies-section'
                : 'l-bSuggestion-section'
            }
          >
            <div className="heading">
              <div className="title">
                {props.name || 'Freight Live'}{' '}
                <span className="badge text-bg-success ms-2">100</span>
              </div>

              {props.cardType === 'buddies' ? (
                <a href="">{t('see_all_buddies')}</a>
              ) : (
                <span className="spinner-grow"></span>
              )}
            </div>
            <div
              className={
                props.cardType === 'buddies' ? 'row row-cols-2' : 'bBox'
              }
              style={{ maxHeight: '380px', overflow: 'scroll' }}
            >
              {/* // <CustomUserCard itemData={item} /> */}
              {props.cardType === 'buddies'
                ? props?.buddies?.map((item, index) => (
                    <BuddiesUserCard
                      key={item?.id + '_' + index}
                      itemData={item}
                      cardType={props.cardType}
                    />
                  ))
                : props?.usersSuggestions?.map((item, index) => (
                    <LiveUserCard
                      itemData={item}
                      key={item?.id + '_' + index}
                      cardType={props.cardType}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CustomLiveUserCardWrapper.propTypes = {
  usersSuggestions: PropTypes.object,
  buddies: PropTypes.object,
  cardType: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
}

export default CustomLiveUserCardWrapper
