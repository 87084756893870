const ErrorHandle = (error = {}, setToasts, customMessage, type, color) => {
  let customMessageIsArr = Array.isArray(customMessage)
    ? [...customMessage]
    : [customMessage]
  let errorMessage =
    error?.response?.data?.errorDetail?.errors?.uiErrorKey?.length > 0
      ? error?.response?.data?.errorDetail?.errors?.uiErrorKey?.map(
        (errorMessage) => errorMessage?.localMessage
      )
      : customMessageIsArr
  let item = [
    {
      name: errorMessage,
      type: type,
      color: color,
    },
  ]
  setToasts(item)
  setTimeout(() => {
    setToasts(null)
  }, 5000)

  if (
    (error?.response?.data?.errorDetail?.status === 500 ||
      error?.response?.data?.httpStatusCode === 500 ||
      error?.response?.status === 500) &&
    (error?.response?.data?.errorDetail?.developerMessage ===
      'getLastAccessedTime: Session already invalidated' ||
      error?.response?.data?.errorDetail?.developerMessage ===
      'Session object not found ')
  ) {
    window.location.href = '/'
  }

  return ''
}

export default ErrorHandle
