// types
import { createSlice } from '@reduxjs/toolkit'

// initial state
const initialState = {
  followingList: null,
}

// ==============================|| SLICE - Followers ||============================== //

const following = createSlice({
  name: 'following',
  initialState,
  reducers: {
    reset: () => initialState,
    getFollowersList(state, action) {
      // state.followersList = action.payload.followersList;
      return state.followingList
    },
    setFollowingList(state, action) {
      console.log('Action palylod', action.payload)
      state.followingList = action.payload.followingList
    },
  },
})

export default following.reducer

export const {
  setFollowingList,
  getFollowersList,
  reset: resetFollowing,
} = following.actions
