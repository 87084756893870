import PropTypes from 'prop-types'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { UsersApis } from '../../API/Login/UsersApi'
import Toasts from '../../Components/Toasts/Toasts'
import { StropheContext } from '../ChatClient'

export default function StandardImaCustomNotificationsList(props) {
  // let data = props?.itemData || itemData;
  const [notificationList, setnotificationList] = React.useState([])
  // const { approveBuddyReq } = UseloadChatClient();
  const { frProUser } = useSelector((state) => state.Users)
  const [toasts, setToasts] = React.useState(null)
  const [reqAccepted, setreqAccepted] = React.useState(false)
  const [reqRejected, setreqRejected] = React.useState(false)
  const stropheContext = React.useContext(StropheContext)
  console.log('Notification strophe connection context', stropheContext)
  React.useEffect(() => {
    let notifications = JSON.parse(
      localStorage.getItem('user')
    ).notificationsList

    setnotificationList(notifications)
  }, [])
  console.log('Notifications list', notificationList)

  const approveBuddyReqFunc = (event) => {
    console.log('Approve buddy req', event?.currentTarget?.name)
    stropheContext?.approveBuddyReq(event?.currentTarget?.name)
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      // "requestFromUser": event?.currentTarget?.name,
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestApproved(obj)
      .then((res) => {
        console.log('success userBuddyRequestApproved ', res?.data)
        // buddiesList?.push(res?.data)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved',
          },
        ]
        setToasts(item)
        setreqAccepted(true)
        setTimeout(() => {
          // props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
        }, 2000)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 4000)

        // setbuddiesList(buddiesList)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestApproved ', err)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request Approved Failed',
            color: 'red',
          },
        ]
        setToasts(item)
        setreqAccepted(true)
        setTimeout(() => {
          // props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
        }, 2000)
        setTimeout(() => {
          // props?.setshowSearchResults(false)
          setreqAccepted(false)
        }, 4000)
      })
  }
  const rejectBuddyReqFunc = (event) => {
    console.log('Reject buddy req', event?.currentTarget?.name)
    let obj = {
      fre8ProAccessToken: frProUser.fre8ProAccessToken,
      // "requestFromUser": event?.currentTarget?.name,
      requestFromUser: event?.currentTarget?.name?.split('@')[0],
      requestTime: new Date().toISOString(),
    }
    UsersApis.userBuddyRequestDenied(obj)
      .then((res) => {
        console.log('success userBuddyRequestDenied ', res?.data)
        // buddiesList?.push(res?.data)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request rejected',
          },
        ]
        setToasts(item)
        // let notification = approveBuddyReq(event?.currentTarget?.name)
        setreqRejected(true)
        setTimeout(() => {
          // props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
          setreqRejected(false)
        }, 2000)
        // setbuddiesList(buddiesList)
      })
      .catch((err) => {
        console.log('Err userBuddyRequestDenied ', err)
        // setbuddiesList(buddiesList)
        let item = [
          {
            name: event?.currentTarget?.name,
            type: 'Request rejection failed',
            color: 'red',
          },
        ]
        setToasts(item)
        // let notification = approveBuddyReq(event?.currentTarget?.name)
        setreqRejected(true)
        setTimeout(() => {
          // props?.setshowNotification(false)
          // props?.setshowSearchResults(false)
          setreqRejected(false)
        }, 2000)
      })
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="notification">
              <div className="title">
                Notifications{' '}
                <span className="badge bg-orange ms-2">4 new</span>
              </div>
              <hr />
              {notificationList &&
                notificationList?.map((item) => {
                  // if(ite)
                  return (
                    <>
                      <div className="nBar bgColor mb-3">
                        <div className="img">
                          <img src="images/user-img1.jpg" alt="" />
                        </div>
                        <div>
                          <div className="name">
                            <b>{item?.jid} </b> sent you a friend request.{' '}
                            <b>
                              {item?.type === 'subscribed'
                                ? 'and you accepted'
                                : ' '}
                            </b>
                          </div>
                          {item?.type === 'subscribe' && (
                            <>
                              <button
                                href=""
                                className="btn btn-accept"
                                onClick={approveBuddyReqFunc}
                                name={item?.jid}
                              >
                                Accept
                              </button>{' '}
                              <a
                                href=""
                                name={item?.jid}
                                className="btn btn-delete"
                                onClick={rejectBuddyReqFunc}
                              >
                                Delete
                              </a>
                            </>
                          )}
                        </div>
                        <div className="time ms-auto">Just now</div>
                      </div>
                      <hr />
                    </>
                  )
                })}
              {/* <div className="nBar mb-3">
                                <div className="img">
                                    <img src="images/user-img2.jpg" alt="" />
                                </div>
                                <div>
                                    <div className="name">
                                        Wish <b>Manmohan Singh</b> a happy birthday (Nov 12).
                                    </div>
                                    <span className="border rounded p-1">Say happy birthday <i className="bi bi-cake2"></i></span>
                                </div>
                                <div className="time ms-auto">10min</div>
                            </div>
                            <hr />
                            <div className="nBar mb-3">
                                <div className="img">
                                    <img src="images/user-img3.jpg" alt="" />
                                </div>
                                <div>
                                    <div className="name">
                                        <b>Mahinder</b>
                                    </div>
                                    <p>Hello! I`&apos;`m going to meet my frend at the department store as soon as possible.</p>
                                </div>
                                <div className="time ms-auto">10:00 AM</div>
                            </div>
                            <hr />
                            <div className="nBar mb-3">
                                <div className="img">
                                    <img src="images/user-img4.jpg" alt="" />
                                </div>
                                <div>
                                    <div className="name">
                                        <b>Ranveer Singh</b>
                                    </div>
                                    <p>Updated profile picture.</p>
                                </div>
                                <div className="time ms-auto">2 days ago</div>
                            </div>*/}
            </div>
          </div>
        </div>
        {reqAccepted && <Toasts toastsList={toasts} />}
        {reqRejected && <Toasts toastsList={toasts} />}
      </div>
    </>
  )
}
StandardImaCustomNotificationsList.propTypes = {
  loading: PropTypes.bool,
  itemData: PropTypes.object,
}
// const itemData = [
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     name: "Ajith Singh",
//     time: "just now",
//     category:"freind request",
//     message: "sent you a friend request.",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     name: "Mahinder",
//     time: "1hrs",
//     category:"wishes",
//     message:
//       "Wish Manmohan Singh a happy birthday (Nov 12)",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     name: "Ranveer Singh",
//     time: "10:00 AM",
//     category:"",
//     message: "How are you my friend",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     name: "Ompreet Khosla",
//     time: "2 days ago",
//     category:"",
//     message: "when will meet buddy?",
//   }
// ];
