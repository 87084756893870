import React from 'react'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import propTypes from 'prop-types'
import CustomPencilSvg from '../../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetShippingAddressView = ({
  setSaving,
  setSelection,
  deleteFleetShippingAddressData,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  return (
    <div className="col-md-12">
      <div className="d-flex justify-content-between px-4 pt-4">
        <h5 className="fw-bold mb-3">Shipping</h5>
        <div>
          <button onClick={() => setSaving(false)} className=" p-0">
            <CustomPencilSvg />
          </button>
          <button
            onClick={() => {
              ProfileApis.updateFleetOwnerUserProfile(
                deleteFleetShippingAddressData
              )
                .then(() => {
                  console.log('aedrfvaerfv', deleteFleetShippingAddressData)
                  dispatch(
                    setFrProUser({
                      frProUser: {
                        ...frProUser,
                        updateProfileForm: {
                          ...frProUser?.updateProfileForm,
                          ...deleteFleetShippingAddressData,
                        },
                      },
                    })
                  )
                })
                .catch((err) => {
                  console.log(err)
                  let customMessage = 'Deleting shipping Address failed'
                  let type = 'User Profile'
                  let color = 'red'
                  ErrorHandle(err, setToasts, customMessage, type, color)
                })
              setSelection('')
            }}
          >
            <CustomDeleteSvg />
          </button>
        </div>
      </div>

      <div className="p-4 pt-0">
        <dl className="row business_list mb-0 gy-2">
          <dt className="col-sm-3"> Shipping address </dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.shippingAddress?.address}
            {', '}
            {frProUser?.updateProfileForm?.shippingAddress?.city}
            {', '}
            {frProUser?.updateProfileForm?.shippingAddress?.state}
            {', '}
            {frProUser?.updateProfileForm?.shippingAddress?.country}
            {' - '}
            {frProUser?.updateProfileForm?.shippingAddress?.postalCode}
          </dd>

          <dt className="col-sm-3">Logistics and transportation category </dt>
          <dd className="col-sm-9">
            {' '}
            {frProUser?.updateProfileForm?.fleetSegment}
          </dd>
          <dt className="col-sm-3"> Fleet type </dt>
          <dd className="col-sm-9">
            {' '}
            {frProUser?.updateProfileForm?.fleetType}
          </dd>

          <dl></dl>
        </dl>
      </div>
    </div>
  )
}

export default FleetShippingAddressView
FleetShippingAddressView.propTypes = {
  setSelection: propTypes.func,
  setSaving: propTypes.func,
  setToasts: propTypes.func,
  deleteFleetShippingAddressData: propTypes.object,
}
