// import "../../css/New/style.css";

// material-ui
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import '../../css/ddsmoothmenu.css'

import { FormHelperText, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { LoginApis } from '../../API/Login/LoginApi'
import '../../css/New/style.css'
import { setFrProUser } from '../../store/reducers/Users/users'
// import TermsAndConditions from './TermsAndConditions'
// import { ProfileApis } from "../../API/Login/ProfileApi";

// TODO: Min 6 and max 25 characters.
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}$/

const emailRules = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

const FleetCreateAccount = () => {
  // const [usernameVal, setUsernameVal] = useState("");
  const [userNameError, setuserNameError] = useState('')
  //   const [verifyEmailCode, setverifyEmailCode] = React.useState("");
  //   const [verifyUsernameCode, setverifyUsernameCode] = React.useState();

  const userNameIsAvailableOrNot = (usernameVal) => {
    LoginApis.userIsAvailable(usernameVal)
      .then((res) => {
        console.log('userIsAvailable', res)
        // !res?.data?.available
        //   ? setuserNameError("Username Already Exiting")
        //   : setuserNameError("Unique");
        setuserNameError(!res?.data?.available)
      })
      .catch((err) => {
        // setuserNameError(err?.message);
        console.log('userIs not Available', err)
      })
  }

  // const dispatch = useDispatch();
  const navigate = useNavigate()
  // const [termsAndConditionShow, setTermsAndConditionShow] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  // const [showTermsError, setShowTermsError] = useState(false);
  // const [termsChecked, setTermsChecked] = useState(false);
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)

  console.log('---------- frProUser -------->',frProUser);

  return (
    <>
      <section className="py-5">
        <div className="container py-lg-4">
          <div className="row">
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="title text-center mb-4">
                <span className="custom-bages fleet-button">
                  Fleet Business
                </span>
                <h1 className="mt-2 color-700">Create account</h1>
              </div>
              <Formik
                initialValues={{
                  fre8ProUserId: '',
                  password: '',
                  termsCheck: false,
                }}
                validate={(value) => {
                  const error = {}
                  console.log(value)
                  if (value?.fre8ProUserId?.length !== 0 && userNameError) {
                    error.fre8ProUserId = 'Email already exists'
                  }
                  return error
                }}
                validationSchema={Yup.object().shape({
                  fre8ProUserId: Yup.string()
                    .required('User Name is required')
                    .matches(
                      emailRules,
                      'Please provide user name as a valid email'
                    ),
                  password: Yup.string()
                    .required('Password is required')
                    .matches(
                      passwordRules,
                      'Please use strong password. It should have atleast one upper case, one lower case, one number, one special character'
                    ),
                  termsCheck: Yup.bool()
                    .required('Please accept terms and conditions.')
                    .oneOf([true], 'Terms and conditions must be checked.'),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  // localStorage.setItem('usersList', JSON.stringify([]))
                  try {
                    // await new Promise((r) => setTimeout(r, 500));
                    setStatus({ success: false })
                    setSubmitting(false)
                    console.log(':VAlues from login', values)

                    dispatch(
                      setFrProUser({
                        frProUser: {
                          ...frProUser,
                          ...values,
                        },
                      })
                    )
                    navigate('/fleetcreateaccountmoreinfo')
                  } catch (err) {
                    setStatus({ success: false })
                    setErrors({ submit: err.message })
                    setSubmitting(false)
                  }
                }}
                // let UserAndPassObj = {
                //   fre8ProUserId: values?.username,
                //   password: values?.password,
                // };
                // onSubmit={async (
                //   values,
                //   { setErrors, setStatus, setSubmitting }
                // ) => {

                // dispatch(setFrProUser({frProUser: {}}));
                // try {
                //     await new Promise((r) => setTimeout(r, 500));
                //     setStatus({ success: false });
                //     setSubmitting(false);
                //     console.log(":VAlues from login", values)
                //     LoginApis.signIn(values.username, values?.password).then((res) => {
                //         console.log("response", res)
                //         dispatch(setFrProUser({ frProUser: res?.data }));
                //         let userList = JSON.parse(localStorage.getItem('usersList'));
                //         // let loggedInUser = JSON.parse(localStorage.getItem('user'))
                //         let user = userList?.filter((item) => {
                //             return item?.fre8ProUserId === res?.data?.fre8ProUserId
                //         })
                //         if (user?.length === 1) {
                //             //userList.push(user[0])z
                //         } else {
                //             userList.push(res?.data)
                //         }
                //         localStorage.setItem('usersList', JSON.stringify(userList))
                //         localStorage.setItem('user', JSON.stringify(res?.data));
                //         if (res?.data?.fre8ProStatus === 'Standard') {
                //             navigate("/profile", { username: "test" })
                //         } else {
                //             navigate("/otpscreen", { username: "test" })
                //         }
                //     }).catch((err) => {
                //         console.log(":VAlues from login", values, err)
                //         setStatus({ success: false });
                //         setErrors({ submit: err.message });
                //         setSubmitting(false);
                //         // navigate("/error", { err: err })
                //     }).finally(() => {
                //         //TODO : test code remove thislater
                //         // navigate("/otpscreen", { username: "testUser" })
                //     })
                //     // navigate("/otpscreen", { username: "testUser" })
                // } catch (err) {
                //     setStatus({ success: false });
                //     setErrors({ submit: err.message });
                //     setSubmitting(false);
                // }
                // }}
                validateOnChange={false}
                validator={() => ({})}
              >
                {({ values, errors, handleSubmit, touched, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label f-w-700 color-700">
                        User Name <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="abc...@gmail.com"
                        // id="for_disabled"
                        autoComplete={true}
                        onBlur={(e) => {
                          const value = e.target.value
                          if (value) {
                            const lowerCaseValue = value.toLowerCase()
                            setFieldValue('fre8ProUserId', lowerCaseValue, {
                              shouldValidate: true,
                            })
                            userNameIsAvailableOrNot({
                              mobileNumber: '',
                              email: '',
                              fre8ProUserId: lowerCaseValue,
                            })
                          }
                        }}
                      />
                      {touched.fre8ProUserId && errors.fre8ProUserId && (
                        <FormHelperText error id="helper-text-fre8ProUserId">
                          {errors.fre8ProUserId}
                          {/* {userNameError} */}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mb-3" style={{ position: 'relative' }}>
                      <label className="form-label f-w-700 color-700">
                        Password <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Create password"
                        // id="for_disabled"
                        onChange={(e) =>
                          setFieldValue('password', e.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      <span
                        className="isPasswordVisibleor"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? 'Hide' : 'Show'}
                      </span>
                      {touched.password && errors.password && (
                        <FormHelperText error id="helper-text-passowrd">
                          {errors.password}
                        </FormHelperText>
                      )}
                      <div className="d-flex error-holder mt-3">
                        <span
                          style={{
                            backgroundColor:
                              values?.password.length >= 1 ? 'red' : 'black',
                          }}
                        ></span>
                        <span
                          style={{
                            backgroundColor:
                              /\d/.test(values?.password) &&
                              /[A-Z]/.test(values?.password)
                                ? '#fdb022'
                                : 'black',
                            transition: '2',
                          }}
                        ></span>
                        <span
                          style={{
                            backgroundColor:
                              /[@.#$!%*?&^]/.test(values?.password) &&
                              /[a-z]/.test(values?.password) &&
                              /[A-Z]/.test(values?.password) &&
                              /\d/.test(values?.password) &&
                              values?.password.length >= 6
                                ? '#32d583'
                                : 'black',
                          }}
                        ></span>
                      </div>
                      <div className="characters-holder mt-3">
                        <ul className="list-check">
                          <li>
                            <img
                              src={
                                values?.password.length >= 6
                                  ? 'images/doneerror-icon.svg'
                                  : 'images/done-default.svg'
                              }
                              alt=""
                            />{' '}
                            At least 6 characters
                          </li>
                          <li>
                            <img
                              src={
                                /\d/.test(values?.password)
                                  ? 'images/doneerror-icon.svg'
                                  : 'images/done-default.svg'
                              }
                              alt=""
                            />{' '}
                            At least 1 number
                          </li>
                          <li>
                            <img
                              src={
                                /[A-Z]/.test(values?.password)
                                  ? 'images/doneerror-icon.svg'
                                  : 'images/done-default.svg'
                              }
                              alt=""
                            />{' '}
                            At least 1 uppercase lette
                          </li>
                          <li>
                            <img
                              src={
                                /[a-z]/.test(values?.password)
                                  ? 'images/doneerror-icon.svg'
                                  : 'images/done-default.svg'
                              }
                              alt=""
                            />{' '}
                            At least 1 lowercase letter
                          </li>

                          <li>
                            <img
                              src={
                                /[@.#$!%*?&^]/.test(values?.password)
                                  ? 'images/doneerror-icon.svg'
                                  : 'images/done-default.svg'
                              }
                              alt=""
                            />{' '}
                            At least 1 special character
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pt-2 pb-4">
                      <div className="form-check">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          name="termsCheck"
                          // onChange={(e) => {
                          //   setFieldValue("termsCheck", e?.target?.checked);
                          // }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          I accept FreightPro8{' '}
                          <span style={{ color: 'red' }}>*</span>{' '}
                        </label>
                          <a
                          href='/TermsAndConditions'
                            // onClick={() => {
                            //   setTermsAndConditionShow(!termsAndConditionShow)
                            // }}
                            className="f-w-700 color-700"
                          >
                            terms & conditions
                          </a>
                        {touched?.termsCheck && errors?.termsCheck && (
                          <FormHelperText error id="helper-text-termsCheck">
                            {errors?.termsCheck}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className={'btn custom-button w-100 noUnderline oneRem'}
                        // color="textSecondary"
                        // variant="h6"
                        component={Link}
                        // to="/FleetCreateAccountmoreinfo"
                        // sx={{ textDecoration: "none" }}
                        onClick={handleSubmit}
                      >
                        Continue
                      </Typography>
                      {/* <Typography
                        component={Link}
                        class={"noUnderline"}
                        // to="/FleetCreateAccountmoreinfo"
                        // color="textSecondary"
                        // variant="h6"
                        // sx={{ textDecoration: "none", fontSize: "1rem" }}
                        // onClick={() => { history.goBack() }}
                      >
                        <button
                          type="submit"
                          className="btn custom-button w-100 ">
                          Sign in
                        </button>
                      </Typography> */}
                    </div>
                    <div
                      className="text-center mt-5 d-flex align-items-center justify-content-center"
                      onClick={() => navigate(-1)}
                    >
                      <a className="f-w-700 color-400">
                        <img src="images/back.svg" alt="" className="me-3" />{' '}
                        Back
                      </a>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* {termsAndConditionShow && <TermsAndConditions />} */}
      </section>
    </>
  )
}

export default FleetCreateAccount
