import React from 'react'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import propTypes from 'prop-types'
import CustomPencilSvg from '../../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetBusinessSummarySkeleton = ({
  setSaving,
  setSelection,
  deleteBusinessSummaryData,
  setToasts,
}) => {
  const dispatch = useDispatch()
  const { frProUser, businessUser } = useSelector((state) => state.Users)
  return (
    <div className="mb-4  shadow-sm rounded-4 bg-white">
      <div className="d-flex justify-content-between px-4 pt-4">
        <h5 className="fw-bold">Business summary</h5>
        <div>
          <button onClick={() => setSaving(false)} className=" p-0">
            <CustomPencilSvg />
          </button>
          <button
            onClick={() => {
              ProfileApis.updateFleetOwnerUserProfile(deleteBusinessSummaryData)
                .then(() => {
                  dispatch(
                    setFrProUser({
                      frProUser: {
                        ...frProUser,
                        updateProfileForm: {
                          ...frProUser?.updateProfileForm,
                          ...deleteBusinessSummaryData,
                        },
                      },
                    })
                  )
                })
                .catch((err) => {
                  console.log(err)
                  let customMessage = 'User Business summary delete failed'
                  let type = 'User Profile'
                  let color = 'red'
                  ErrorHandle(err, setToasts, customMessage, type, color)
                })
              setSelection('')
            }}
          >
            <CustomDeleteSvg />
          </button>
        </div>
      </div>

      <h6 className="fw-bold my-3 gray-900 border-bottom  px-4 pb-3">
        {businessUser?.firstName} {businessUser?.lastName}
      </h6>
      <div className="p-4 pt-0">
        <dl className="row business_list mb-0 gy-2">
          <dt className="col-sm-3">Legal business name</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.businessName
              ? frProUser?.updateProfileForm?.businessName
              : ''}
          </dd>
          <dt className="col-sm-3">Business number</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.businessNumber
              ? frProUser?.updateProfileForm?.businessNumber
              : ''}
          </dd>
          <dt className="col-sm-3">EIN number</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.einNumber
              ? frProUser?.updateProfileForm?.einNumber
              : ''}
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default FleetBusinessSummarySkeleton
FleetBusinessSummarySkeleton.propTypes = {
  setSelection: propTypes.func,
  setSaving: propTypes.func,
  deleteBusinessSummaryData: propTypes.object,
  setToasts: propTypes.func,
}
