import { LoginApis } from '../../../API/Login/LoginApi'
import { ProfileApis } from '../../../API/Login/ProfileApi'
import ErrorHandle from '../../../Components/ErrorHandle'
import {
  setLoggedInUserBuddiesList,
  setLoggedInUserFollowersList,
  setLoggedInUserFollowingList,
} from '../../../store/reducers/Users/users'
import { dispatch } from '../../../store/reducers/persist'

// const helper = () => {

//     return (
//         <div>helper</div>
//     )
// }

// export default helper
const addToFollowing = (data, otherUserProfileInfo, setToasts) => {
  // const { frProUser } = useSelector((state) => state.Users);
  console.log('Data', data)
  let obj = {
    fre8ProAccessToken: data?.fre8ProAccessToken,
    requestToUser:
      otherUserProfileInfo?.fre8ProUserId || otherUserProfileInfo?.userId,
    requestTime: new Date().toISOString(),
  }
  console.log('ffinal obj', obj)
  ProfileApis.sendUserFollowRequest(obj)
    .then((res) => {
      console.log('Response ', res)
      // return res
    })
    .catch((err) => {
      console.error('Error', err)
      let customMessage = 'Follow Request failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)

      // return err
    })
}

const getLoggedInUserFollowingList = (data, setToasts) => {
  let obj = {
    fre8ProAccessToken: data?.fre8ProAccessToken,
  }
  ProfileApis.getLoggedInUserFollowingList(obj)
    .then((res) => {
      dispatch(setLoggedInUserFollowingList({ followingList: res?.data || [] }))
      // res?.data?.map((item) => {
      //     getOtherUserProfileForDetailedInfoAboutUser(item?.userId)

      // })
      let promiseObjToLoadImage = res?.data?.map((friend) => {
        let data1 = loadImage(friend, data)
        friend = data1
        // frndList.push(data);
        return data1
      })

      setTimeout(() => {
        Promise.all(promiseObjToLoadImage)
          .then((res) => {
            // storeVariable[varValue] = res;

            // settargetUserfollowing(res)
            dispatch(setLoggedInUserFollowingList({ followingList: res }))
          })
          .catch((err) => {
            // if (!(err?.response?.data?.success)) {
            //  let errorMessage =
            // err?.response?.data?.errorDetail?.errors?.uiErrorKey[0]
            //   ?.localMessage ?? "User Fleet contact update failed";//
            // let item = [
            // //         {
            // //             name: err?.response?.data?.errorDetail?.developerMessage,
            // //             type: err?.response?.data?.errorDetail?.errorType,
            // //         },
            // //     ];
            // //     setShowSuccess(true)
            // //     setToasts(item);
            // //     setTimeout(() => {
            // //         // navigate('/')
            // //     }, 4000);
            // // }
            let customMessage = 'User following list failed'
            let type = 'User Information'
            let color = 'red'
            ErrorHandle(err, setToasts, customMessage, type, color)
          })
          .finally(() => {
            // setimagesLoaded(true);
          })
      }, 1000)
      return res?.data
    })
    .catch((err) => {
      dispatch(setLoggedInUserFollowingList({ followingList: [] }))
      return err?.data
    })
}

const getLoggedInUserBuddiesList = (data, setToasts) => {
  // const { frProUser } = useSelector((state) => state.Users);

  let obj = {
    fre8ProAccessToken: data?.fre8ProAccessToken,
  }

  ProfileApis.getLoggedInUserBuddiesList(obj)
    .then((res) => {

      let filteredDataForUnbuddy = res?.data?.filter((item) => { return item.buddyStatus !== 'unbuddy' })
      dispatch(setLoggedInUserBuddiesList({ buddiesList: filteredDataForUnbuddy || [] }))

      let promiseObjToLoadImage = filteredDataForUnbuddy?.map((friend) => {
        let data = loadImage(friend)

        friend = data
        // frndList.push(data);

        return data
      })

      setTimeout(() => {
        Promise.all(promiseObjToLoadImage)
          .then((res) => {
            // storeVariable[varValue] = res;

            // settargetUserfollowers(res)
            dispatch(setLoggedInUserBuddiesList({ buddiesList: res }))
          })
          .catch((err) => {

            dispatch(setLoggedInUserBuddiesList({ buddiesList: [] }))
          })
          .finally(() => {
            // setimagesLoaded(true);
          })
      }, 1000)

      return res?.data
    })
    .catch((err) => {

      dispatch(setLoggedInUserBuddiesList({ buddiesList: [] }))
      let customMessage = 'User buddies list failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)

      return err?.data
    })
}

const getLoggedInUserFollowersList = (data, setToasts) => {
  // const { frProUser } = useSelector((state) => state.Users);

  let obj = {
    fre8ProAccessToken: data?.fre8ProAccessToken,
  }

  ProfileApis.getLoggedInUserFollowersList(obj)
    .then((res) => {

      dispatch(setLoggedInUserFollowersList({ followersList: res?.data || [] }))
      let promiseObjToLoadImage = res?.data?.map((friend) => {
        let data = loadImage(friend)

        friend = data
        // frndList.push(data);

        return data
      })

      setTimeout(() => {
        Promise.all(promiseObjToLoadImage)
          .then((res) => {
            // storeVariable[varValue] = res;

            // settargetUserfollowers(res)
            dispatch(setLoggedInUserFollowersList({ followersList: res }))
          })
          .catch((err) => {
            let customMessage = 'Failed to load image'
            let type = 'User Information'
            let color = 'red'
            ErrorHandle(err, setToasts, customMessage, type, color)

            dispatch(setLoggedInUserFollowersList({ followersList: [] }))
          })
          .finally(() => {
            // setimagesLoaded(true);
          })
      }, 1000)

      return res?.data
    })
    .catch((err) => {
      dispatch(setLoggedInUserFollowersList({ followersList: [] }))
      let customMessage = 'User follower list failed'
      let type = 'User Information'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)

      return err?.data
    })
}

// const getOtherUserProfileForDetailedInfoAboutUser = async (userObject) => {
//     let userObj = {
//         userId: userObject?.userId,
//         fre8ProAccessToken: userObject?.fre8ProAccessToken,
//     };
//     UsersApis.getUserProfileOfOtherUser(userObj)
//         .then((res) => {
//             return res?.data
//         })
//         .catch((err) => {
//             console.error(
//                 "Error in get User Profile Of O ther User from compoennet",
//                 err
//             );
//             return err?.data
//         });
// };
const loadImage = (friendVal, frProUser, setToasts) => {
  let loogedInUser = JSON.parse(localStorage.getItem('user'))
  return new Promise((resolve, reject) => {
    let friend = friendVal
    let objSearchImg = {
      url: friend?.userProfileImgUrl
        ?.replace('{userId}', friend?.userId)
        .replace('//', '/'),
      accessToken: {
        fre8ProAccessToken:
          frProUser?.fre8ProAccessToken || loogedInUser?.fre8ProAccessToken,
      },
    }
    LoginApis.fetchsearchFriendImage(objSearchImg)
      .then((res) => {
        // friend["userProfileImgUrlData"] = res
        // console.log('Search::updatedg user data', friend, "res  ", friendVal)

        // setTimeout(() => {
        let imageData = {
          userProfileImgUrlData: `data:image/jpg;base64, ${res}`,
        }
        // friend.buddyRelationForm.followingMeForm.userProfileImgUrlData = res;
        let merged = { ...friend, ...imageData }

        resolve(merged)
        // }, 2000)
      })
      .catch((err) => {
        let customMessage = 'User friend image load failed'
        let type = 'User Information'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)

        resolve(err?.developerMessage)
      })
  })
}

export {
  addToFollowing,
  getLoggedInUserBuddiesList,
  getLoggedInUserFollowersList,
  getLoggedInUserFollowingList
}

