import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import Toasts from '../../../../Components/Toasts/Toasts'
import ErrorHandle from '../../../../Components/ErrorHandle'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'

const ProfileTagLine = ({ setSelection }) => {
  const dispatch = useDispatch()

  const { frProUser } = useSelector((state) => state.Users)
  const [ProfileTagLineData, setProfileTagLineData] = useState('')
  const [toasts, setToasts] = useState(null)

  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.profileTagForm?.profileTag ? true : false
  )

  const obj = {
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    profileId: '',
    profileTag: ProfileTagLineData,
  }

  const createProfileTag = () => {
    ProfileTagLineData && setSaving(true), // eslint-disable-line
      ProfileApis.createProfileTag(obj).catch((err) => {
        let customMessage = 'User Tag Line  create failed'
        let type = 'User Tag Line'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })

    dispatch(
      setFrProUser({
        frProUser: {
          ...frProUser,
          updateProfileForm: {
            ...frProUser?.updateProfileForm,
            profileTagForm: {
              ...frProUser?.updateProfileForm?.profileTagForm,
              profileTag: ProfileTagLineData,
            },
          },
        },
      })
    )
  }
  const updateProfileTag = () => {
    ProfileTagLineData && setSaving(true), // eslint-disable-line
      ProfileApis.updateProfileTag(obj).catch((err) => {
        let customMessage = 'User Tag Line  update failed'
        let type = 'User Tag Line'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
    dispatch(
      setFrProUser({
        frProUser: {
          ...frProUser,
          updateProfileForm: {
            ...frProUser?.updateProfileForm,
            profileTagForm: {
              ...frProUser.updateProfileForm.profileTagForm,
              profileTag: ProfileTagLineData,
            },
          },
        },
      })
    )
  }
  const deleteProfileTag = () => {
    // ProfileTagLineData && setSaving(true), // eslint-disable-line
    ProfileApis.deleteProfileTag({
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    }).catch((err) => {
      let customMessage = 'User Tag Line  delete failed'
      let type = 'User Tag Line'
      let color = 'red'
      ErrorHandle(err, setToasts, customMessage, type, color)
    })
    setSelection('')
    dispatch(
      setFrProUser({
        frProUser: {
          ...frProUser,
          updateProfileForm: {
            ...frProUser?.updateProfileForm,
            profileTagForm: {
              ...frProUser?.updateProfileForm?.profileTagForm,
              profileTag: '',
            },
          },
        },
      })
    )
  }

  return (
    <div>
      {saving ? (
        <p className="mb-0 mt-3 text-dark d-flex align-items-center ">
          {' '}
          <button onClick={() => setSaving(false)} className=" btn-upload me-3">
            {' '}
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 8.82843L12.5 4.82843M1 20.3284L4.38437 19.9524C4.79786 19.9064 5.0046 19.8835 5.19785 19.8209C5.36929 19.7654 5.53245 19.687 5.68289 19.5878C5.85245 19.476 5.99955 19.3289 6.29373 19.0347L19.5 5.82843C20.6046 4.72386 20.6046 2.933 19.5 1.82843C18.3955 0.723859 16.6046 0.723857 15.5 1.82843L2.29373 15.0347C1.99955 15.3289 1.85246 15.476 1.74064 15.6455C1.64143 15.796 1.56301 15.9591 1.50751 16.1306C1.44496 16.3238 1.42198 16.5306 1.37604 16.9441L1 20.3284Z"
                stroke="#005A70"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>{' '}
          <p className='m-0 custom-ellipsis'>{frProUser?.updateProfileForm?.profileTagForm?.profileTag}</p>
          <button onClick={deleteProfileTag}>
            <CustomDeleteSvg />
          </button>
        </p>
      ) : (
        <div>
          <div className="mt-2">
            <input
              onKeyDown={(e) =>
                e.key === 'Enter' && ProfileTagLineData && setSaving(true)
              }
              type="text"
              className="form-control mb-2"
              placeholder="Enter a profile tag line"
              maxLength={100}
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  setProfileTagLineData(e.target.value);
                }
              }}
              value={ProfileTagLineData}
            />
            <p className="text-muted mb-2">
              {100 - ProfileTagLineData.length} characters remaining
            </p>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <button
                onClick={
                  frProUser?.updateProfileForm?.profileTagForm?.profileTag
                    ? updateProfileTag
                    : createProfileTag
                }
                className="btn btn-theme"
              >
                Save
              </button>
              <button
                onClick={() =>
                  frProUser?.updateProfileForm?.profileTagForm?.profileTag
                    ? setSaving(true)
                    : setSelection('')
                }
                className="btn btn-border"
              >
                Cancel{' '}
              </button>
            </div>
          </div>
        </div>
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </div>
  )
}

export default ProfileTagLine

ProfileTagLine.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.object,
  setSelection: PropTypes.string,
}
