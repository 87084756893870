// import { Typography } from '@mui/material';
import { Typography } from '@mui/material'
import $ from 'jquery'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { $msg } from 'strophe.js'
import { LoginApis } from '../../API/Login/LoginApi'
import { ProfileApis } from '../../API/Login/ProfileApi'
import ErrorHandle from '../../Components/ErrorHandle'
import Loader from '../../Components/Loader/Loader'
import Toasts from '../../Components/Toasts/Toasts'
import { StropheContext } from '../../Pages/ChatClient'
import { setLoggedInUserBuddiesList } from '../../store/reducers/Users/users'
import { dispatch } from '../../store/reducers/persist'
import { defaultImage } from '../../utils/Images'

// import { useLocation } from 'react-router-dom';
// import { $pres } from "strophe.js";
// import Loader from '../../Components/Loader/Loader';
// // import { UseloadChatClient } from "../../Pages/ChatClient/Chatclient";
// import "../../css/New/dashboard.css";
// import { setBuddyList, setNotificationList } from '../../store/reducers/Users/users';
// import { dispatch } from '../../store/reducers/persist';
// import { defaultImage } from '../../utils/Images';

const FooterLayout = () => {
    const [message, setMessage] = useState('')
    const stropheContext = useContext(StropheContext)
    console.log('Footer strophe connection context', stropheContext)
    const [chatButtonClicked, setchatButtonClicked] = useState(false)
    const [showMessageWindow, setshowMessageWindow] = useState(false)
    // const [chatWindowUserName, setchatWindowUserName] = useState('')
    const [selectedBuddyChatWindow, setselectedBuddyChatWindow] = useState({})
    const [hideShowLoaderDueNetworkIssue, sethideShowLoaderDueNetworkIssue] = useState(false)
    const [
        filterMsgForuserReceivedInWindow,
        setfilterMsgForuserReceivedInWindow,
    ] = useState([])
    const [filterMsgForuserSentInWindow, setfilterMsgForuserSentInWindow] =
        useState([])

    // const [allMessagesForUsers, setallMessagesForUsers] = useState([])

    // const location = useLocation();
    const { buddyList, frProUser } = useSelector((state) => {
        console.log('state.Users', state.Users)
        return state.Users
    })
    // const arrayRef = useRef([]);
    const [buddiesList, setbuddiesList] = useState([])
    const [toasts, setToasts] = useState(null)
    const [showToasts, setshowToasts] = useState(false)

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    useEffect(() => {

        let timer = setTimeout(() => {
            if (!stropheContext?.connectionObj?.connected) {

                sethideShowLoaderDueNetworkIssue(true)
                setshowToasts(true)
                let customMessage = 'Connection failed try after some time, or try logging out or clicking on reconnect button at right bottom'
                let type = 'User Information'
                let color = 'red'
                let err = null
                ErrorHandle(err, setToasts, customMessage, type, color)
            }

        }, 90000)



        return () => {
            clearTimeout(timer);
        };


    }, [stropheContext?.connectionObj?.connected, hideShowLoaderDueNetworkIssue])

    useEffect(() => {
        scrollToBottom()
    }, [filterMsgForuserReceivedInWindow, filterMsgForuserSentInWindow])

    const loadImage = (friendVal) => {
        return new Promise((resolve, reject) => {
            let friend = friendVal
            let objSearchImg = {
                url: friend?.userProfileImgUrl
                    ?.replace('{userId}', friend?.userId)
                    .replace('//', '/'),
                accessToken: {
                    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
                },
            }
            LoginApis.fetchsearchFriendImage(objSearchImg)
                .then((res) => {
                    console.log('Search::load image search loaded res')
                    // friend["userProfileImgUrlData"] = res
                    // console.log('Search::updatedg user data', friend, "res  ", friendVal)

                    // setTimeout(() => {
                    let imageData = {
                        userProfileImgUrlData: `data:image/jpg;base64, ${res}`,
                    }
                    let merged = { ...friend, ...imageData }
                    resolve(merged)
                    // }, 2000)
                })
                .catch((err) => {
                    console.log('Search::updatedg image data failed', err)
                    resolve(err?.developerMessage)
                    setshowToasts(!showToasts)
                    let customMessage = 'User Friend image load failed'
                    let type = 'User Information'
                    let color = 'red'
                    ErrorHandle(err, setToasts, customMessage, type, color)
                })
        })
    }
    useEffect(() => {
        let obj = {
            fre8ProAccessToken: frProUser?.fre8ProAccessToken,
        }

        ProfileApis.getLoggedInUserBuddiesList(obj)
            .then((res) => {
                let filteredDataForUnbuddy = res?.data?.filter((item) => {
                    return item.buddyStatus !== 'unbuddy'
                })
                setbuddiesList([...buddiesList, ...filteredDataForUnbuddy] || [])
                dispatch(
                    setLoggedInUserBuddiesList({
                        buddiesList: [...buddiesList, ...filteredDataForUnbuddy] || [],
                    })
                )
                setTimeout(() => {
                    loadAllImages([...buddiesList, ...filteredDataForUnbuddy])
                }, 1000)
                // return res?.data;
            })
            .catch((err) => {
                setbuddiesList([...buddiesList, ...[]])
                setshowToasts(!showToasts)
                dispatch(setLoggedInUserBuddiesList({ buddiesList: buddiesList }))
                let customMessage = 'User buddies list failed'
                let type = 'User Information'
                let color = 'red'
                ErrorHandle(err, setToasts, customMessage, type, color)
                // return err?.data;
            })
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    const loadAllImages = (buddyData) => {
        let promiseObjToLoadImage = buddyData?.map((friend) => {
            let data = loadImage(friend)
            friend = data
            // frndList.push(data);
            return data
        })

        setTimeout(() => {
            Promise.all(promiseObjToLoadImage)
                .then((res) => {

                    if (res) {
                        // setbuddiesList(res)
                        // dispatch(
                        //   setSearchFriends({
                        //     searchFriendsResults: res
                        //   })
                        // );
                        setbuddiesList([...buddiesList, ...res])
                        dispatch(
                            setLoggedInUserBuddiesList({
                                buddiesList: [...buddiesList, ...res],
                            })
                        )
                    }
                })
                .catch((err) => {

                    if (!err?.response?.data?.success) {
                        // let item = [
                        //   {
                        //     name: err?.response?.data?.errorDetail?.developerMessage,
                        //     type: err?.response?.data?.errorDetail?.errorType,
                        //   },
                        // ];
                        // setshowToasts(true)
                        // setToasts(item);
                        // setTimeout(() => {
                        //   navigate('/')
                        // }, 4000);
                        // dispatch(setLoggedInUserBuddiesList({ buddiesList: [] }))
                    }
                })
                .finally(() => {
                    // setimagesLoaded(true);
                })
        }, 1000)
    }

    const loadChatclientAgain = () => {
        // window.connectionObj = null;
        //loadChatclient()
        sethideShowLoaderDueNetworkIssue(false)
        stropheContext?.loadChatclient()
    }

    // useEffect(() => {
    //     //load msg
    //     let buddyMsgs = stropheContext?.buddyMessages.map((item, key) => {

    //         return item
    //     })

    //     console.log(buddyMsgs)
    // }, []) //eslint-disable-line

    const openMessageWindow = (event) => {
        setshowMessageWindow(!showMessageWindow)
        console.log(event?.currentTarget?.name)
        // setchatWindowUserName(event?.currentTarget?.name)
        let data = stropheContext?.chatData;
        let filterData = data.users.filter((item) => {
            return (
                item?.id ===
                stropheContext?.custom_jid_to_id(event?.currentTarget?.name)
            )
        })
        if (filterData?.length === 0) {
            data?.users?.push({
                "id": stropheContext?.custom_jid_to_id(event?.currentTarget?.name),
                "username": event?.currentTarget?.name,
                "messages": [],
                "windowOpened": true,
            })
        } else {
            // let filterData = data.users.filter((item) => { return item?.id === stropheContext?.custom_jid_to_id(user?.id) });

            data.users.map((item) => {
                //eslint-disable-line
                // if (
                //   item?.id ===
                //   stropheContext?.custom_jid_to_id(event?.currentTarget?.name)
                // ) {
                //   item.windowOpened = true
                // }

                if (
                    item?.id ===
                    stropheContext?.custom_jid_to_id(event?.currentTarget?.name)
                ) {
                    // return { ...item, windowOpened: true };
                    item.windowOpened = true
                }
                return item;
            });

            stropheContext?.setchatData(data)
        }
        stropheContext?.setchatData(data)

        let filterMsgForuser =
            stropheContext?.buddyMessages[
            stropheContext?.custom_jid_to_id(event?.currentTarget?.name)
            ]
        setfilterMsgForuserReceivedInWindow(filterMsgForuser)
        let user = buddyList.filter((item) => {
            return item?.jabberId === event?.currentTarget?.name
        })
        // // let userNewData = [...filterMsgForuser, ...user[0]]
        // // let userNewData = {};
        // user[0]['messagesFrom'] = filterMsgForuser
        setselectedBuddyChatWindow(user[0])
    }

    const sendMsg = (ev) => {
        var jid = ev?.currentTarget?.name

        if (ev.which === 13 || ev?.type === 'click') {
            ev.preventDefault()

            var body =
                ev?.currentTarget?.value !== ''
                    ? ev?.currentTarget?.value
                    : document.getElementById('inputSendMessage').value

            var message = $msg({
                to: jid,
                type: 'chat',
            })
                .c('body')
                .t(body)
                .up()
                .c('active', { xmlns: 'http://jabber.org/protocol/chatstates' })

            stropheContext?.connectionObj?.send(message)
            ev.currentTarget.value = ''
            // arrayRef.current.push(value);
            setfilterMsgForuserSentInWindow((filterMsgForuserReceivedInWindow) => [
                ...filterMsgForuserReceivedInWindow,
                body,
            ])
            stropheContext?.setbuddyMessages('from_' + body)
            let data = []
            data.push({ to: jid, msg: body })
            // let strDta = stropheContext?.buddyMessages
            // stropheContext?.setnewbuddyMessages([...strDta, ...data])
            const now = Date.now(); // Unix timestamp in milliseconds

            let fullMessage = {
                id: now,
                to: jid,
                from: frProUser?.jabberId,
                // type: type,
                msg: body,
            }
            let buddyMsg = stropheContext?.buddyMessages
            if (buddyMsg && buddyMsg[frProUser?.jabberId] !== undefined) {
                buddyMsg[stropheContext?.custom_jid_to_id(frProUser?.jabberId)].push(
                    body
                )
            } else {
                if (buddyMsg) {
                    buddyMsg[stropheContext?.custom_jid_to_id(frProUser?.jabberId)] = []
                    // buddyMsg[frProUser?.jabberId].push(...new Set(fullMessage)) //eslint-disable-line
                    buddyMsg[stropheContext?.custom_jid_to_id(frProUser?.jabberId)].push(
                        fullMessage
                    ) //eslint-disable-line
                }
            }
            stropheContext?.setbuddyMessages(buddyMsg || [])
            // const newMessage = {
            //     id: stropheContext?.chatData?.length + 1,
            //     from: frProUser?.jabberId,
            //     to: jid,
            //     message: body,
            //     // type: type,
            // };
            // stropheContext?.setchatData([...stropheContext.chatData, newMessage]);
            // $(this).parent().find('.chat-messages').append(
            //     "<div class='chat-message'>&lt;"+
            //     "<span class='chat-name me'>"+
            //     Strophe.getNodeFromJid(Gab.connection.jid) +
            //     "</span>&gt;<span class='chat-text'>"+
            //     body +
            //     "</span></div>"
            // );
            // document.querySelector('.chat-body  .chat-holder.p-3').append(
            //     `<div className="d-flex" key='item'>
            //     <div className="flex-shrink-0">
            //         <img className="img-fluid" style={{ height: 45, width: 45, borderRadius: "50%" }} src=${selectedBuddyChatWindow?.userProfileImgUrlData} alt="user img" />
            //     </div>
            //     <div className="flex-grow-1 ms-2 ">
            //         <p className="bgadmin">${body}</p>
            //     </div>
            // </div>`
            // );

            // Gab.scroll_chat(Gab.jid_to_id(jid));

            // $(this).val('');
            // $(this).parent().data('composing', false);

            let msgData = stropheContext?.chatData;
            // eslint-disable-next-line
            msgData.users.map((item) => {
                if (item?.id === stropheContext?.custom_jid_to_id(jid)) {
                    item?.messages?.push({
                        "id": stropheContext?.custom_jid_to_id(frProUser?.jabberId),
                        "from": frProUser?.jabberId,
                        "to": jid,
                        "msg": body,
                        "msgType": 'send',
                    })
                }
            })
            stropheContext?.setchatData(msgData)
            setMessage('')
            // if (filtermsgData?.length > 0) {
            //     filtermsgData[0]?.messages?.push({
            //         "id": stropheContext?.custom_jid_to_id(frProUser?.jabberId),
            //         "from": frProUser?.jabberId,
            //         "to": jid,
            //         "msg": body
            //     })
            //     let objNew = Object.assign({}, ...msgData.users, ...filtermsgData)
            //     stropheContext?.setchatData(objNew)
            // }
        } else {
            var composing = $(this).parent().data('composing')

            if (!composing) {
                var notify = $msg({
                    to: jid,
                    type: 'chat',
                }).c('composing', { xmlns: 'http://jabber.org/protocol/chatstates' })

                stropheContext?.connectionObj?.send(notify)

                // $(this).parent().data('composing', true);
            }
        }
    }

    //ref

    // const [inputValue, setInputValue] = useState("");
    // const previousInputValue = useRef("");

    // useEffect(() => {
    //     previousInputValue.current = inputValue;
    // }, [inputValue]);

    return (
        <div
            className="footer"
            style={{
                display: 'flex',
                position: 'absolute',
                bottom: 20,
                width: '100%',
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col">
                        {stropheContext?.connectionObj?.connected ? (
                            <div
                                id="Smallchat"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'end',
                                    position: 'absolute',
                                    width: '100%',
                                    bottom: 0,
                                    right: 0,
                                    flexDirection: 'row-reverse',
                                }}
                            >
                                {chatButtonClicked && (
                                    <div className="Layout Layout-open Layout-expand Layout-right">
                                        <div className="Messenger_messenger">
                                            <div className="Messenger_header p-3">
                                                <span className="chat-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="21"
                                                        viewBox="0 0 20 21"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8.56654 12.85L6.05508 15.3929C5.70476 15.7476 5.5296 15.9249 5.37905 15.9374C5.24843 15.9483 5.12054 15.8958 5.03524 15.7962C4.93691 15.6816 4.93691 15.4323 4.93691 14.9338V13.6598C4.93691 13.2126 4.57065 12.889 4.12812 12.8241V12.8241C3.05709 12.6673 2.21595 11.8261 2.05907 10.7551C2.0332 10.5785 2.0332 10.3678 2.0332 9.9463V6.15334C2.0332 4.78121 2.0332 4.09514 2.30024 3.57106C2.53513 3.11006 2.90993 2.73526 3.37093 2.50037C3.89501 2.23334 4.58107 2.23334 5.9532 2.23334H11.9965C13.3687 2.23334 14.0547 2.23334 14.5788 2.50037C15.0398 2.73526 15.4146 3.11006 15.6495 3.57106C15.9165 4.09514 15.9165 4.78121 15.9165 6.15334V9.58334M15.9165 18.5667L14.1391 17.331C13.8893 17.1573 13.7644 17.0704 13.6284 17.0088C13.5077 16.9542 13.3809 16.9144 13.2506 16.8904C13.1038 16.8633 12.9517 16.8633 12.6474 16.8633H11.1799C10.2651 16.8633 9.80774 16.8633 9.45835 16.6853C9.15102 16.5287 8.90115 16.2789 8.74456 15.9715C8.56654 15.6221 8.56654 15.1648 8.56654 14.25V12.1967C8.56654 11.2819 8.56654 10.8245 8.74456 10.4752C8.90115 10.1678 9.15102 9.91795 9.45835 9.76136C9.80774 9.58334 10.2651 9.58334 11.1799 9.58334H15.7532C16.668 9.58334 17.1253 9.58334 17.4747 9.76136C17.7821 9.91795 18.0319 10.1678 18.1885 10.4752C18.3665 10.8245 18.3665 11.2819 18.3665 12.1967V14.4133C18.3665 15.1744 18.3665 15.5549 18.2422 15.8551C18.0764 16.2553 17.7585 16.5732 17.3583 16.739C17.0581 16.8633 16.6776 16.8633 15.9165 16.8633V18.5667Z"
                                                            stroke="#E19E00"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <h4 className="Messenger_prompt text-center">
                                                    Buddy Connect
                                                </h4>
                                                <span
                                                    className="chat_close_icon"
                                                    onClick={() => {
                                                        setchatButtonClicked(!chatButtonClicked)
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M17 7L7 17M7 7L17 17"
                                                            stroke="#005A70"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="Messenger_content">
                                                {!showMessageWindow && (
                                                    <div
                                                        className="search-wrapper m-2 mb-0"
                                                        id="search-area"
                                                    >
                                                        <button className="serchbutton ">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                                    stroke="#005A70"
                                                                    strokeWidth="1.66667"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                        <input
                                                            id="sendMessageTextBox"
                                                            type="text"
                                                            placeholder="Search"
                                                            required=""
                                                            className="rounded"
                                                        />
                                                    </div>
                                                )}
                                                <div className="Messages">
                                                    <div className="Messages_list">
                                                        {/* <!--=======================================All user data start here===========================--> */}
                                                        {
                                                            <div
                                                                className="footer chat-list p-3"
                                                                id="user-data"
                                                            >
                                                                {buddyList &&
                                                                    buddyList?.map((item, i) => {
                                                                        console.log('Buddy list item', item, i)

                                                                        return (
                                                                            <a
                                                                                key={i}
                                                                                className="d-flex align-items-center"
                                                                                onClick={openMessageWindow}
                                                                                name={item?.jabberId}
                                                                            >
                                                                                <div className="flex-shrink-0">
                                                                                    <img
                                                                                        style={{
                                                                                            height: 45,
                                                                                            width: 45,
                                                                                            borderRadius: '50%',
                                                                                        }}
                                                                                        className="img-fluid"
                                                                                        src={
                                                                                            `${item?.userProfileImgUrlData ||
                                                                                            defaultImage
                                                                                            }` ||
                                                                                            'https://mehedihtml.com/chatbox/assets/img/user.png'
                                                                                        }
                                                                                        alt="user img"
                                                                                    />
                                                                                    <span className="st-statis online"></span>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h3>
                                                                                        {item?.businessName ||
                                                                                            item?.fullNameOfTheUser}
                                                                                    </h3>
                                                                                    <p>{item?.profileTagLine} </p>
                                                                                </div>
                                                                                <span
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        right: 60,
                                                                                        background: 'green',
                                                                                        padding: '3px 9px',
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        stropheContext?.buddyMessages?.[
                                                                                            stropheContext?.custom_jid_to_id(
                                                                                                item?.jabberId
                                                                                            )
                                                                                        ]?.length
                                                                                    }
                                                                                </span>
                                                                                <div className="time12">Now</div>
                                                                            </a>
                                                                        )
                                                                    })}
                                                            </div>
                                                        }
                                                        {/* <!--=======================================All user data end here===========================--> */}

                                                        {/* <!--================================user chat screen start here=================================================--> */}
                                                        {/* {
                                                showMessageWindow && <div className="chat-body" id="chatuser" name={chatWindowUserName}>gi

                                                    <button className="p-2 chatgoback" onClick={() => { setshowMessageWindow(!showMessageWindow) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8334M4.16675 10L10.0001 4.16669" stroke="#667085"
                                                                strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        Go back
                                                    </button> */}

                                                        {/* <!--================================online userbox start here=================================================--> */}
                                                        {/* <a href="#" className="d-flex inlineuser-bg p-2">
                                                        <div className="flex-shrink-0">
                                                            <img className="img-fluid" style={{ height: 45, width: 45, borderRadius: "50%" }} src={selectedBuddyChatWindow?.userProfileImgUrlData} alt="user img" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h3>{chatWindowUserName || ""} </h3>
                                                            <div className="position-relative textonline"> <span className="greenstatus  position-static"></span> Online
                                                            </div>
                                                        </div>
                                                    </a> */}
                                                        {/* <!--================================online userbox end here=================================================--> */}

                                                        {/* <div className="chat-holder p-3">
                                                        {filterMsgForuserReceivedInWindow?.map((item) => {
                                                            return <div className="d-flex" key='item'>
                                                                <div className="flex-shrink-0">
                                                                    <img className="img-fluid" style={{ height: 45, width: 45, borderRadius: "50%" }} src={selectedBuddyChatWindow?.userProfileImgUrlData} alt="user img" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-2 ">
                                                                    <p className="bgadmin">{item}</p>
                                                                </div>
                                                            </div>

                                                        })
                                                        }
                                                        {filterMsgForuserSentInWindow?.map((item) => {
                                                            return <div className="d-flex" key='item'>

                                                                <div className="flex-grow-1 ms-2 ">
                                                                    <p className="bgadmin">{item}</p>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <img className="img-fluid" style={{ height: 45, width: 45, borderRadius: "50%" }} src={frProUser?.profileImg} alt="user img" />
                                                                </div>
                                                            </div>

                                                        })
                                                        }


                                                    </div>


                                                </div>

                                                } */}
                                                        {/* <!--================================user chat screen end here=================================================--> */}
                                                    </div>
                                                </div>
                                                {/* <!--====================================chat message box start here=====================================================--> */}
                                                {/* <div className="m-3" id="typing-area">
                                            <div className="Input Input-blank">
                                                <span>{stropheContext?.composing ? `${chatWindowUserName?.split(" ")[0]}  is typing` : "not typing"}</span>
                                                <input className="Input_field" placeholder={"Type a message..."} onKeyUp={sendMsg} name={chatWindowUserName?.split(" ")[0]} />
                                                <button className="Input_button Input_button-send" onClick={sendMsg} name={chatWindowUserName?.split(" ")[0]}>
                                                    <div className="Icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M16.1401 2.96001L7.11012 5.96001C1.04012 7.99001 1.04012 11.3 7.11012 13.32L9.79012 14.21L10.6801 16.89C12.7001 22.96 16.0201 22.96 18.0401 16.89L21.0501 7.87001C22.3901 3.82001 20.1901 1.61001 16.1401 2.96001ZM16.4601 8.34001L12.6601 12.16C12.5101 12.31 12.3201 12.38 12.1301 12.38C11.9401 12.38 11.7501 12.31 11.6001 12.16C11.4606 12.0189 11.3824 11.8284 11.3824 11.63C11.3824 11.4316 11.4606 11.2412 11.6001 11.1L15.4001 7.28001C15.6901 6.99001 16.1701 6.99001 16.4601 7.28001C16.7501 7.57001 16.7501 8.05001 16.4601 8.34001Z"
                                                                fill="#005A70" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        </div> */}
                                                {/* <!--====================================chat message box end here=====================================================--> */}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {stropheContext?.chatData?.users?.map((user, i) => {
                                    return (
                                        user?.windowOpened && (
                                            <div
                                                key={user?.id}
                                                // style={{ right: 330 * (i + 1) }}
                                                className="Layout Layout-open Layout-expand Layout-right"
                                            >
                                                <div className="Messenger_messenger">
                                                    <div className="Messenger_header p-3">
                                                        <span className="chat-icon">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M8.56654 12.85L6.05508 15.3929C5.70476 15.7476 5.5296 15.9249 5.37905 15.9374C5.24843 15.9483 5.12054 15.8958 5.03524 15.7962C4.93691 15.6816 4.93691 15.4323 4.93691 14.9338V13.6598C4.93691 13.2126 4.57065 12.889 4.12812 12.8241V12.8241C3.05709 12.6673 2.21595 11.8261 2.05907 10.7551C2.0332 10.5785 2.0332 10.3678 2.0332 9.9463V6.15334C2.0332 4.78121 2.0332 4.09514 2.30024 3.57106C2.53513 3.11006 2.90993 2.73526 3.37093 2.50037C3.89501 2.23334 4.58107 2.23334 5.9532 2.23334H11.9965C13.3687 2.23334 14.0547 2.23334 14.5788 2.50037C15.0398 2.73526 15.4146 3.11006 15.6495 3.57106C15.9165 4.09514 15.9165 4.78121 15.9165 6.15334V9.58334M15.9165 18.5667L14.1391 17.331C13.8893 17.1573 13.7644 17.0704 13.6284 17.0088C13.5077 16.9542 13.3809 16.9144 13.2506 16.8904C13.1038 16.8633 12.9517 16.8633 12.6474 16.8633H11.1799C10.2651 16.8633 9.80774 16.8633 9.45835 16.6853C9.15102 16.5287 8.90115 16.2789 8.74456 15.9715C8.56654 15.6221 8.56654 15.1648 8.56654 14.25V12.1967C8.56654 11.2819 8.56654 10.8245 8.74456 10.4752C8.90115 10.1678 9.15102 9.91795 9.45835 9.76136C9.80774 9.58334 10.2651 9.58334 11.1799 9.58334H15.7532C16.668 9.58334 17.1253 9.58334 17.4747 9.76136C17.7821 9.91795 18.0319 10.1678 18.1885 10.4752C18.3665 10.8245 18.3665 11.2819 18.3665 12.1967V14.4133C18.3665 15.1744 18.3665 15.5549 18.2422 15.8551C18.0764 16.2553 17.7585 16.5732 17.3583 16.739C17.0581 16.8633 16.6776 16.8633 15.9165 16.8633V18.5667Z"
                                                                    stroke="#E19E00"
                                                                    strokeWidth="1.2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <h4 className="Messenger_prompt text-center">
                                                            Buddy Connect
                                                        </h4>
                                                        <span
                                                            className="chat_close_icon"
                                                            onClick={() => {
                                                                setshowMessageWindow(!showMessageWindow)
                                                                let data = stropheContext?.chatData
                                                                // let filterData = data.users.filter((item) => { return item?.id === stropheContext?.custom_jid_to_id(user?.id) });
                                                                // data.users.map((item) => {
                                                                //   //eslint-disable-line
                                                                //   if (
                                                                //     item?.id ===
                                                                //     stropheContext?.custom_jid_to_id(user?.id)
                                                                //   ) {
                                                                //     item.windowOpened = false
                                                                //   }
                                                                // })

                                                                data?.users?.map((item) => {
                                                                    //eslint-disable-line
                                                                    if (
                                                                        item?.id ===
                                                                        stropheContext?.custom_jid_to_id(user?.id)
                                                                    ) {
                                                                        item.windowOpened = false
                                                                    }
                                                                    return item; // Add this line to fix the ESLint warning
                                                                })
                                                                stropheContext?.setchatData(data)
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M17 7L7 17M7 7L17 17"
                                                                    stroke="#005A70"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="Messenger_content">
                                                        {/* {!showMessageWindow && <div className="search-wrapper m-2 mb-0" id="search-area">
                                                <button className="serchbutton ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path
                                                            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                            stroke="#005A70" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </button>
                                                <input type="text" placeholder="Search" required="" className="rounded" />
                                            </div>} */}
                                                        <div className="Messages">
                                                            <div className="Messages_list">
                                                                {/* <!--=======================================All user data start here===========================--> */}
                                                                {/* {!showMessageWindow && <div className="footer chat-list p-3" id="user-data">
                                                        {buddyList && buddyList?.map((item, i) => {
                                                            console.log("Buddy list item", item, i);
    
                                                            return <a key={i} className="d-flex align-items-center" onClick={openMessageWindow} name={item?.jabberId}>
                                                                <div className="flex-shrink-0">
                                                                    <img style={{ height: 45, width: 45, borderRadius: "50%" }} className="img-fluid" src={`${item?.userProfileImgUrlData || defaultImage}` || "https://mehedihtml.com/chatbox/assets/img/user.png"} alt="user img" />
                                                                    <span className="st-statis online"></span>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h3>{item?.businessName || item?.fullNameOfTheUser}</h3>
                                                                    <p>{item?.profileTagLine} </p>
                                                                </div>
                                                                <div className="time12">Now</div>
                                                            </a>
                                                        })}
    
                                                    </div>} */}
                                                                {/* <!--=======================================All user data end here===========================--> */}

                                                                {/* <!--================================user chat screen start here=================================================--> */}
                                                                {
                                                                    <div
                                                                        className="chat-body"
                                                                        id="chatuser"
                                                                        name={user?.username}
                                                                    >
                                                                        <button
                                                                            className="p-2 chatgoback"
                                                                            onClick={() => {
                                                                                setshowMessageWindow(!showMessageWindow)
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="20"
                                                                                height="20"
                                                                                viewBox="0 0 20 20"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8334M4.16675 10L10.0001 4.16669"
                                                                                    stroke="#667085"
                                                                                    strokeWidth="1.67"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                            Go back
                                                                        </button>
                                                                        {/* {stropheContext?.buddyMessages?.[stropheContext?.custom_jid_to_id(chatWindowUserName)]?.length > 0 && <span style={{ position: "absolute", right: 60, background: "green", padding: "3px 9px", borderRadius: "50%" }}>{stropheContext?.buddyMessages?.[stropheContext?.custom_jid_to_id(chatWindowUserName)]?.length}</span>} */}

                                                                        {/* <!--================================online userbox start here=================================================--> */}
                                                                        <Link
                                                                            to="#"
                                                                            className="d-flex inlineuser-bg p-2"
                                                                        >
                                                                            <div className="flex-shrink-0">
                                                                                <img
                                                                                    className="img-fluid"
                                                                                    style={{
                                                                                        height: 45,
                                                                                        width: 45,
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                    src={
                                                                                        selectedBuddyChatWindow?.userProfileImgUrlData
                                                                                    }
                                                                                    alt="user img"
                                                                                />
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h3>{user?.username || ''} </h3>
                                                                                <div className="position-relative textonline">
                                                                                    {' '}
                                                                                    <span className="greenstatus  position-static"></span>{' '}
                                                                                    Online
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                        {/* <!--================================online userbox end here=================================================--> */}

                                                                        <div className="chat-holder p-3">
                                                                            {/* {filterMsgForuserReceivedInWindow?.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <div className="d-flex" key="item">
                                                                                        <div className="flex-shrink-0">
                                                                                            <img
                                                                                                className="img-fluid"
                                                                                                style={{
                                                                                                    height: 45,
                                                                                                    width: 45,
                                                                                                    borderRadius: '50%',
                                                                                                }}
                                                                                                src={
                                                                                                    selectedBuddyChatWindow?.userProfileImgUrlData
                                                                                                }
                                                                                                alt="user img"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="flex-grow-1 ms-2 ">
                                                                                            <p className="bgadmin">{item}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                        {filterMsgForuserSentInWindow?.map((item) => {
                                                                            return (
                                                                                <div className="d-flex" key="item">
                                                                                    <div className="flex-grow-1 ms-2 ">
                                                                                        <p className="bgadmin">{item}</p>
                                                                                    </div>
                                                                                    <div className="flex-shrink-0">
                                                                                        <img
                                                                                            className="img-fluid"
                                                                                            style={{
                                                                                                height: 45,
                                                                                                width: 45,
                                                                                                borderRadius: '50%',
                                                                                            }}
                                                                                            src={frProUser?.profileImg}
                                                                                            alt="user img"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })} */}
                                                                            {stropheContext?.chatData?.users
                                                                                ?.filter((item) => {
                                                                                    return (
                                                                                        item?.id ===
                                                                                        stropheContext?.custom_jid_to_id(
                                                                                            user?.id
                                                                                        )
                                                                                    )
                                                                                })[0]
                                                                                ?.messages?.map((item) => {
                                                                                    return (
                                                                                        <div className="d-flex" key="item">
                                                                                            {item?.msgType === 'received' && (
                                                                                                <div className="flex-shrink-0">
                                                                                                    <img
                                                                                                        className="img-fluid"
                                                                                                        style={{
                                                                                                            height: 45,
                                                                                                            width: 45,
                                                                                                            borderRadius: '50%',
                                                                                                        }}
                                                                                                        src={
                                                                                                            selectedBuddyChatWindow?.userProfileImgUrlData
                                                                                                        }
                                                                                                        alt="user img"
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="flex-grow-1 ms-2 ">
                                                                                                <p className="bgadmin">
                                                                                                    {item?.msg}
                                                                                                </p>
                                                                                            </div>
                                                                                            {item?.msgType === 'send' && (
                                                                                                <div className="flex-shrink-0">
                                                                                                    <img
                                                                                                        className="img-fluid"
                                                                                                        style={{
                                                                                                            height: 45,
                                                                                                            width: 45,
                                                                                                            borderRadius: '50%',
                                                                                                        }}
                                                                                                        src={frProUser?.profileImg}
                                                                                                        alt="user img"
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            chat_on
                                                                        </div>

                                                                        <div ref={messagesEndRef} />
                                                                    </div>
                                                                }
                                                                {/* <!--================================user chat screen end here=================================================--> */}
                                                            </div>
                                                        </div>
                                                        {/* <!--====================================chat message box start here=====================================================--> */}
                                                        <div className="m-3" id="typing-area">
                                                            <div className="Input Input-blank">
                                                                <span>
                                                                    {stropheContext?.composing
                                                                        ? `${user?.username?.split(' ')[0]
                                                                        }  is typing`
                                                                        : ''}
                                                                </span>
                                                                <input
                                                                    className="Input_field"
                                                                    placeholder={'Type a message...'}
                                                                    onKeyUp={sendMsg}
                                                                    name={user?.username?.split(' ')[0]}
                                                                    // value={inputValue}
                                                                    // onChange={(e) => setInputValue(e.target.value)}

                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                    id="inputSendMessage"
                                                                />
                                                                <button
                                                                    className="Input_button Input_button-send"
                                                                    onClick={sendMsg}
                                                                    name={user?.username?.split(' ')[0]}
                                                                    disabled={!message.trim()}
                                                                >
                                                                    <div className="Icon">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M16.1401 2.96001L7.11012 5.96001C1.04012 7.99001 1.04012 11.3 7.11012 13.32L9.79012 14.21L10.6801 16.89C12.7001 22.96 16.0201 22.96 18.0401 16.89L21.0501 7.87001C22.3901 3.82001 20.1901 1.61001 16.1401 2.96001ZM16.4601 8.34001L12.6601 12.16C12.5101 12.31 12.3201 12.38 12.1301 12.38C11.9401 12.38 11.7501 12.31 11.6001 12.16C11.4606 12.0189 11.3824 11.8284 11.3824 11.63C11.3824 11.4316 11.4606 11.2412 11.6001 11.1L15.4001 7.28001C15.6901 6.99001 16.1701 6.99001 16.4601 7.28001C16.7501 7.57001 16.7501 8.05001 16.4601 8.34001Z"
                                                                                fill="#005A70"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <!--====================================chat message box end here=====================================================--> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                })}

                                {/* <!--===============CHAT ON BUTTON STRART===============--> */}
                                {!chatButtonClicked && (
                                    <div className="chat_on">
                                        <span
                                            className="chat_on_icon"
                                            onClick={() => {
                                                setchatButtonClicked(!chatButtonClicked)
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="49"
                                                height="49"
                                                viewBox="0 0 49 49"
                                                fill="none"
                                            >
                                                <path
                                                    d="M20.4166 30.625L14.1379 36.9821C13.2621 37.8689 12.8243 38.3122 12.4479 38.3435C12.1213 38.3706 11.8016 38.2394 11.5883 37.9906C11.3425 37.7039 11.3425 37.0807 11.3425 35.8344V32.6496C11.3425 31.5314 10.4269 30.7224 9.32054 30.5603V30.5603C6.64298 30.1681 4.54013 28.0653 4.14792 25.3877C4.08325 24.9462 4.08325 24.4194 4.08325 23.3657V13.8833C4.08325 10.453 4.08325 8.73786 4.75084 7.42765C5.33806 6.27516 6.27507 5.33815 7.42756 4.75093C8.73777 4.08334 10.4529 4.08334 13.8833 4.08334H28.9916C32.4219 4.08334 34.1371 4.08334 35.4473 4.75093C36.5998 5.33815 37.5368 6.27516 38.124 7.42765C38.7916 8.73786 38.7916 10.453 38.7916 13.8833V22.4583M38.7916 44.9167L34.3481 41.8274C33.7235 41.3931 33.4112 41.176 33.0712 41.0221C32.7696 40.8854 32.4524 40.786 32.1267 40.726C31.7598 40.6583 31.3794 40.6583 30.6186 40.6583H26.9499C24.663 40.6583 23.5196 40.6583 22.6461 40.2133C21.8778 39.8218 21.2531 39.1971 20.8616 38.4288C20.4166 37.5553 20.4166 36.4119 20.4166 34.125V28.9917C20.4166 26.7048 20.4166 25.5614 20.8616 24.6879C21.2531 23.9196 21.8778 23.2949 22.6461 22.9034C23.5196 22.4583 24.663 22.4583 26.9499 22.4583H38.3833C40.6701 22.4583 41.8136 22.4583 42.687 22.9034C43.4554 23.2949 44.08 23.9196 44.4715 24.6879C44.9166 25.5614 44.9166 26.7048 44.9166 28.9917V34.5333C44.9166 36.4359 44.9166 37.3872 44.6058 38.1376C44.1913 39.1382 43.3964 39.9331 42.3959 40.3475C41.6455 40.6583 40.6942 40.6583 38.7916 40.6583V44.9167Z"
                                                    stroke="#E19E00"
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                )}
                                {/* <!--===============CHAT ON BUTTON END===============--> */}
                            </div>
                        ) : (
                            <div
                                style={{
                                    position: 'fixed',
                                    zIndex: 10,
                                    width: 100,
                                    height: 80,
                                    right: 15,
                                    bottom: 20,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                {!hideShowLoaderDueNetworkIssue && <Loader />}

                                <Typography onClick={loadChatclientAgain}>
                                    Re-Connect
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showToasts && <Toasts toastsList={toasts} />}
        </div>
    )
}

export default FooterLayout
