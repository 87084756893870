/*eslint-disable*/
import React, { Suspense } from 'react'

// project import
import Loader from './Loader'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  /*eslint-disable*/
  (Component) =>
    (
      props
    ) => (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )

export default Loadable
