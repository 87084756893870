import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { LoginApis } from '../../API/Login/LoginApi'
import Loader from '../../Components/Loader/Loader'
import fr8verifiedFull from '../../css/images/fr8verified-full.svg'
import standardFull from '../../css/images/standard-full.svg'
import unverifiedFull from '../../css/images/unverified-full.svg'
import { setSearchFriends } from '../../store/reducers/Users/users'
import { dispatch } from '../../store/reducers/persist'
import { StropheContext } from '../ChatClient'

// let defaultImage = false
const SearchFriendPopUp = (props) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  // const { subscribePresence } = UseloadChatClient();
  const stropheContext = useContext(StropheContext)
  console.log('Notification strophe connection context', stropheContext)
  console.log(
    'Navigate func',
    props,
    'subscribePresence',
    stropheContext?.subscribePresence
  )
  const { frProUser, searchFriendsResults } = useSelector(
    (state) => state.Users
  )
  const [searchFriend, setsearchFriend] = React.useState(
    props?.searchFriendText
  )
  // const [buttons, setButtons] = useState(false);
  // const [reqSent, setreqSent] = useState('')
  // const [seeAll, setSeeAll] = React.useState(3);
  const [friendsList, setfriendsList] = React.useState(searchFriendsResults)
  const [interimfriendsList, setinterimfriendsList] =
    React.useState(searchFriendsResults)
  const [toasts, setToasts] = useState(null)
  const [imagesLoaded, setimagesLoaded] = useState(false)
  console.log(setsearchFriend, interimfriendsList)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setshowLoading] = useState(false)
  console.log(showLoading, showSuccess, toasts, state)
  React.useEffect(() => {
    // let loogedInUser = JSON.parse(localStorage.getItem('user'))
    let searchFriendObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      searchText: searchFriend,
      searchType: 'buddy',
    }
    console.log('Search::searchFriendObj before request sent', searchFriendObj)
    if (searchFriend?.length > 2) {
      setshowLoading(true)
      setTimeout(() => {
        LoginApis.searchFriend(searchFriendObj)
          .then((res) => {
            console.log('Search::Response of seach friend', res)
            // setTimeout(() => {

            // const filteredFriends = res?.data?.userList.filter((n) => n.fullNameOfTheUser.includes(searchFriend))

            setfriendsList(res?.data?.userList)
            dispatch(
              setSearchFriends({
                searchFriendsResults: res?.data?.userList,
              })
            )
            console.log('Search::Response set into store')

            let promiseObjToLoadImage = res?.data?.userList?.map((friend) => {
              let data = loadImage(friend)
              console.log('loadImage Data', data)
              friend = data
              // frndList.push(data);
              return data
            })

            setTimeout(() => {
              Promise.all(promiseObjToLoadImage)
                .then((res) => {
                  console.log('Search::Image url data updated set into store')
                  if (res) {
                    setinterimfriendsList(res)
                  }
                })
                .catch((err) => {
                  console.log('Search::Failed to load images222', err)
                  if (!err?.response?.data?.success) {
                    let item = [
                      {
                        name: err?.response?.data?.errorDetail
                          ?.developerMessage,
                        type: err?.response?.data?.errorDetail?.errorType,
                      },
                    ]
                    setShowSuccess(true)
                    setToasts(item)
                    setTimeout(() => {
                      navigate('/')
                    }, 4000)
                  }
                })
                .finally(() => {
                  setimagesLoaded(true)
                })
            }, 1000)
            // }, 1000);
          })
          .catch((err) => {
            console.log('Search::Error seach friend', err)
          })
          .finally(() => {
            setshowLoading(false)
          })
      }, 100)
    } else {
      props?.setshowSearchResults(false)
    }
  }, [searchFriend]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (imagesLoaded === true) setfriendsList(interimfriendsList)
  }, [imagesLoaded]) //eslint-disable-line  react-hooks/exhaustive-deps

  const loadImage = (friendVal) => {
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    return new Promise((resolve, reject) => {
      let friend = friendVal
      let objSearchImg = {
        url: friend?.userProfileImgUrl
          ?.replace('{userId}', friend?.userId)
          .replace('//', '/'),
        accessToken: {
          fre8ProAccessToken:
            frProUser?.fre8ProAccessToken || loogedInUser?.fre8ProAccessToken,
        },
      }
      LoginApis.fetchsearchFriendImage(objSearchImg)
        .then((res) => {
          console.log('Search::load image search loaded res')
          // friend["userProfileImgUrlData"] = res
          // console.log('Search::updatedg user data', friend, "res  ", friendVal)

          // setTimeout(() => {
          let imageData = { userProfileImgUrlData: res }
          let merged = { ...friend, ...imageData }
          resolve(merged)
          // }, 2000)
        })
        .catch((err) => {
          console.log('Search::updatedg image data failed', err)
          resolve(err?.developerMessage)
        })
    })
  }

  const viewAllClicked = (event) => {
    localStorage.setItem("search", searchFriend);
    console.log(event?.currentTarget?.name)
    navigate('/searchresultspage', { state: { friendsList, searchFriend } })
    props?.setshowSearchResults(false)
  }
  const viewOthersClicked = (item) => {
    props?.setshowSearchResults(false)
    if (item?.userCategory === 'Fleet Owner') {
      navigate(`/fleetownerbuddyprofile/${item?.userId}`)
    } else if (item?.userCategory === 'Driver') {
      navigate(`/driverbuddyprofile/${item?.userId}`)
    } else if (item?.userCategory === 'Own Operator') {
      navigate(`/owneroperatorbuddiesprofile/${item?.userId}`)
    }
  }
  return (
    <>
      <ul className="results notificationdropdown">
        {showLoading && <Loader />}
        <span
          onClick={() => {
            props?.setshowSearchResults(false)
          }}
          style={{
            position: 'absolute',
            top: 0,
            right: '3%',
            fontSize: 'large',
            cursor: 'pointer',
            margin: '-3px',
            fontWeight: 'bolder',
          }}
        >
          x
        </span>
        {!showLoading && friendsList?.length === 0 && (
          <div
            className="Content"
            style={{
              width: '100%',
              height: 56,
              padding: 16,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 1,
              display: 'inline-flex',
            }}
          >
            <div
              className="Content"
              style={{
                flex: '1 1 0',
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 6,
                paddingBottom: 6,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 12,
                display: 'inline-flex',
              }}
            >
              <div className="This" style={{ alignSelf: 'stretch' }}>
                <span
                  style={{
                    color: '#6CBC6E',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  No buddies found with{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                    }}
                  >
                    {state?.searchFriend}
                  </span>{' '}
                  !
                </span>
                <span
                  style={{
                    color: '#292929',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  {' '}
                </span>
                <span
                  style={{
                    color: '#292929',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    lineHeight: 1,
                    wordWrap: 'break-word',
                  }}
                >
                  Please start typing.
                </span>
              </div>
            </div>
          </div>
        )}
        {friendsList?.slice(0, 5).map((item, i) => {
          console.log('Image:::: search item', item)
          return (
            <li key={item?.id + i}>
              <a href="#" className="d-flex border-bottom p-3">
                <div
                  className={`flex-shrink-0  ${item?.userProfileImgUrlData === null ||
                    item?.userProfileImgUrlData === undefined
                    ? 'placeholder-glow'
                    : ''
                    }`}
                >
                  <img
                    className={`img-fluid  ${item?.userProfileImgUrlData === null ||
                      item?.userProfileImgUrlData === undefined
                      ? 'placeholder'
                      : ''
                      } `}
                    alt="user img"
                    style={{ width: 90, height: 90, borderRadius: '50%' }}
                    src={`data:image/jpg;base64, ${item?.userProfileImgUrlData}`}
                  />
                </div>
                <Typography
                  component={Link}
                  class={''}
                  sx={{ width: '45%' }}
                  to={
                    item?.userCategory === 'Fleet Owner'
                      ? `/fleetownerbuddyprofile/${item.userId}`
                      : item?.userCategory === 'Driver'
                        ? `/driverbuddyprofile/${item.userId}`
                        : item?.userCategory === 'Own Operator'
                          ? `/owneroperatorbuddiesprofile/${item.userId}`
                          : ''
                  }
                  onClick={() => viewOthersClicked(item)}
                // name={item?.userId}
                // url={item?.userProfileUrl}
                >
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex align-items-center">
                      <div className="usa-img">
                        {item?.fre8ProStatus === 'fr8pro-verified' && (
                          <img src={fr8verifiedFull} alt="" />
                        )}
                        {item?.fre8ProStatus === 'UNVERIFIED' && (
                          <img src={unverifiedFull} alt="" />
                        )}
                        {item?.fre8ProStatus === 'STANDARD' && (
                          <img src={standardFull} alt="" />
                        )}
                      </div>
                      <h5 className="ps-2">
                        {item?.businessName || item?.fullNameOfTheUser},{' '}
                        {item?.locationForm?.countryForm?.countryName}
                      </h5>
                    </div>
                    <div className="d-flex gap-2">
                      <button className="p-0">
                        {item?.userCategory === 'Driver' && (
                          <img src="images/notif-driver-b.svg" alt="" />
                        )}
                        {item?.userCategory === 'Couch Provider' && (
                          <img src="images/couch-provider-b.svg" alt="" />
                        )}
                        {item?.userCategory === 'Fleet Owner' && (
                          <img src="images/notif-fleet-owner.svg" alt="" />
                        )}
                        {item?.userCategory === 'Fleet Business' && (
                          <img src="images/notif-fleet-business.svg" alt="" />
                        )}
                        {item?.userCategory === 'Own Operator' && (
                          <img src="images/notif-owner-operator.svg" alt="" />
                        )}
                      </button>
                      <button className="p-0">
                        {item?.userCategory === 'Driver' && item?.membershipStatus === 'bronze' && (
                          <img src="images/bronze-icon.svg" alt="" />
                        )}
                        {item?.userCategory === 'Fleet Owner' && (item?.membershipStatus === 'platinum' || item?.membershipStatus === 'gold') && (
                          <img src="images/gold-icon.svg" alt="" />
                        )}
                        {item?.userCategory === 'Own Operator' && item?.membershipStatus === 'silver' && (
                          <img src="images/silver-icon.svg" alt="" />
                        )}
                      </button>
                    </div>
                    <div className="statusinfo">
                      {item?.profileTagLine || 'no tag line added'}
                    </div>
                  </div>
                </Typography>
              </a>
            </li>
          )
        })}

        {friendsList?.length > 0 && (
          <div className="p-3 fw-bold">
            <a className="viewall" onClick={viewAllClicked}>
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.33398 6.00065H10.6673M10.6673 6.00065L6.00065 1.33398M10.6673 6.00065L6.00065 10.6673"
                  stroke="#344054"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{' '}
              See all results
            </a>
          </div>
        )}
      </ul>
    </>
  )
}

export default SearchFriendPopUp

SearchFriendPopUp.propTypes = {
  searchFriendText: PropTypes.string,
  setshowSearchResults: PropTypes.func,
}
