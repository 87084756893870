import React from 'react'

const FleetOwnerBuddyProfile = () => {
  return (
    <div>
      <main className="main users chart-page p-0" id="skip-target">
        <div className="profile-bg">
          <div className="cover-image">
            <div className="container-fluid"></div>
          </div>

          <div className="container-fluid py-3 ">
            <div className="align-items-start d-flex justify-content-between mb-5 flex-wrap">
              <div>
                <div className="d-flex flex-wrap">
                  <div className="image-holder-main position-relative">
                    <div className="image-holder overflow-hidden position-relative">
                      <img src="images/profile-image.svg" alt="" />
                    </div>
                  </div>
                  <div className="px-4 my-4 my-lg-0">
                    <img src="images/Fleet-business-profile.svg" alt="" />
                    <img src="images/verifed-icon.svg" alt="" />

                    <h4 className="mt-2">New York Fleet</h4>
                    <p className="mb-0 small text-muted">No tag line added</p>
                  </div>
                </div>
              </div>
              <div className="shadow-sm p-4 rounded-4 me-4 bg-white bg-white bg-white">
                <div className="text-theme mb-3 text-center">
                  <span className="me-3 fw-medium">
                    <strong>23</strong> Followers
                  </span>
                  <span className="fw-medium">
                    <strong>25</strong> Following
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <button>
                    <img src="images/follow-btn.svg" alt="" />
                  </button>
                  <button>
                    <img src="images/message-btn.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div
                  className=""
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                    <div className="nav flex-column nav-pills gap-3 driv-profile">
                      <button
                        className="align-items-center d-flex justify-content-between nav-link fw-semibold active"
                        id="v-Profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-Profile"
                        type="button"
                        role="tab"
                        aria-controls="v-Profile"
                        aria-selected="true"
                      >
                        <span>
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.3333 17.5V4.16667C13.3333 3.72464 13.1577 3.30072 12.8451 2.98816C12.5326 2.67559 12.1087 2.5 11.6666 2.5H8.33329C7.89127 2.5 7.46734 2.67559 7.15478 2.98816C6.84222 3.30072 6.66663 3.72464 6.66663 4.16667V17.5M3.33329 5.83333H16.6666C17.5871 5.83333 18.3333 6.57953 18.3333 7.5V15.8333C18.3333 16.7538 17.5871 17.5 16.6666 17.5H3.33329C2.41282 17.5 1.66663 16.7538 1.66663 15.8333V7.5C1.66663 6.57953 2.41282 5.83333 3.33329 5.83333Z"
                              stroke="#083945"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>{' '}
                          Business profile
                        </span>
                      </button>
                      <a
                        href="fleet-other-Terminals-and-warehousing.html"
                        className="align-items-center d-flex justify-content-between nav-link fw-semibold"
                      >
                        <span>
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_2613_4607)">
                              <path
                                d="M17.5 8.33301C17.5 14.1663 10 19.1663 10 19.1663C10 19.1663 2.5 14.1663 2.5 8.33301C2.5 6.34388 3.29018 4.43623 4.6967 3.02971C6.10322 1.62318 8.01088 0.833008 10 0.833008C11.9891 0.833008 13.8968 1.62318 15.3033 3.02971C16.7098 4.43623 17.5 6.34388 17.5 8.33301Z"
                                stroke="#083945"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 10.833C11.3807 10.833 12.5 9.71372 12.5 8.33301C12.5 6.9523 11.3807 5.83301 10 5.83301C8.61929 5.83301 7.5 6.9523 7.5 8.33301C7.5 9.71372 8.61929 10.833 10 10.833Z"
                                stroke="#083945"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2613_4607">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>{' '}
                          Terminals and warehouses
                        </span>
                        <span className="badge text-bg-light">0</span>
                      </a>
                      <button
                        className="align-items-center d-flex justify-content-between nav-link fw-semibold"
                        id="v-Buddies-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-Buddies"
                        type="button"
                        role="tab"
                        aria-controls="v-Buddies"
                        aria-selected="false"
                      >
                        <span>
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.8667 9.2081L10.2084 16.8664C9.27017 17.8046 7.99769 18.3317 6.67087 18.3317C5.34405 18.3317 4.07157 17.8046 3.13337 16.8664C2.19517 15.9282 1.66809 14.6558 1.66809 13.3289C1.66809 12.0021 2.19517 10.7296 3.13337 9.79144L10.7917 2.1331C11.4172 1.50763 12.2655 1.15625 13.15 1.15625C14.0346 1.15625 14.8829 1.50763 15.5084 2.1331C16.1338 2.75857 16.4852 3.60689 16.4852 4.49144C16.4852 5.37598 16.1338 6.2243 15.5084 6.84977L7.8417 14.5081C7.52897 14.8208 7.10481 14.9965 6.66254 14.9965C6.22026 14.9965 5.79611 14.8208 5.48337 14.5081C5.17064 14.1954 4.99494 13.7712 4.99494 13.3289C4.99494 12.8867 5.17064 12.4625 5.48337 12.1498L12.5584 5.0831"
                              stroke="#083945"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>{' '}
                          Stories and posts
                        </span>
                        <span className="badge text-bg-light">0</span>
                      </button>
                      <button
                        className="align-items-center d-flex justify-content-between nav-link fw-semibold"
                        id="v-Followers-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-Followers"
                        type="button"
                        role="tab"
                        aria-controls="v-Followers"
                        aria-selected="false"
                      >
                        <span>
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_2218_2601)">
                              <path
                                d="M9.99984 13.3332L13.3332 9.99984M13.3332 9.99984L9.99984 6.6665M13.3332 9.99984H6.6665M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                                stroke="#083945"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2218_2601">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>{' '}
                          Followers
                        </span>
                        <span className="badge text-bg-light">0</span>
                      </button>
                      <button
                        className="align-items-center d-flex justify-content-between nav-link fw-semibold"
                        id="v-Following-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-Following"
                        type="button"
                        role="tab"
                        aria-controls="v-Following"
                        aria-selected="false"
                      >
                        <span>
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_2218_2609)">
                              <path
                                d="M9.99984 6.6665L6.6665 9.99984M6.6665 9.99984L9.99984 13.3332M6.6665 9.99984H13.3332M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
                                stroke="#083945"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2218_2609">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>{' '}
                          Following
                        </span>
                        <span className="badge text-bg-light">0</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                  <h5 className="fw-bold mb-3">Contact</h5>
                  <ul className="gap-2 list-group list-group-flush">
                    <li className="list-group-item border-0 text-dark px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="me-3"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      newyorkfleet@gmail.com
                    </li>
                    <li className="list-group-item border-0 text-dark px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="me-3"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      +1 (555) 123456789
                    </li>

                    <li className="list-group-item border-0 text-dark px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="me-3"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      +1 (555) 112233445
                    </li>
                    <li className="list-group-item border-0 text-dark px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="me-3"
                      >
                        <path
                          d="M22 12C22 17.5228 17.5228 22 12 22M22 12C22 6.47715 17.5228 2 12 2M22 12H2M12 22C6.47715 22 2 17.5228 2 12M12 22C14.5013 19.2616 15.9228 15.708 16 12C15.9228 8.29203 14.5013 4.73835 12 2M12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2M2 12C2 6.47715 6.47715 2 12 2"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      www.nyfleet.com
                    </li>
                    <li className="list-group-item border-0 text-dark px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="me-3"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                          stroke="#007A99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      Rochester city, New York, New York, 10001
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-8">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-Profile"
                    role="tabpanel"
                    aria-labelledby="v-Profile-tab"
                    tabIndex="0"
                  >
                    <div className="mb-4  shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3 px-4 pt-4">
                        Business summary
                      </h5>
                      <h6 className="fw-bold my-3 gray-900 border-bottom  px-4 pb-3">
                        New York Fleet
                      </h6>
                      <div className="p-4 pt-0">
                        <dl className="row business_list mb-0 gy-2">
                          <dt className="col-sm-3">Legal business name</dt>
                          <dd className="col-sm-9">
                            <a href="#" className="request-other-profile">
                              Request
                            </a>
                          </dd>
                          <dt className="col-sm-3">Business number</dt>
                          <dd className="col-sm-9">
                            <a href="#" className="request-other-profile">
                              Request
                            </a>
                          </dd>

                          <dt className="col-sm-3">EIN number</dt>
                          <dd className="col-sm-9">
                            <a href="#" className="request-other-profile">
                              Request
                            </a>
                          </dd>
                        </dl>
                      </div>
                    </div>

                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Business ownership</h5>
                      <dl className="row business_list mb-0 gy-2">
                        <dt className="col-sm-3">Owner </dt>
                        <dd className="col-sm-9">Not shared</dd>
                        <dt className="col-sm-3">Independent owner?</dt>
                        <dd className="col-sm-9">Yes</dd>
                      </dl>
                      <h6 className="fw-bold mb-3 py-3">
                        {' '}
                        Non-owner personale details
                      </h6>

                      <dl className="row business_list mb-0 gy-2">
                        <dt className="col-sm-3">Name </dt>
                        <dd className="col-sm-9">Firstname Lastname</dd>
                        <dt className="col-sm-3">Designation</dt>
                        <dd className="col-sm-9">Executive manager</dd>
                      </dl>
                    </div>

                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Services</h5>
                      <dl className="row business_list mb-0 gy-2">
                        <dt className="col-sm-3">Warehousing services </dt>
                        <dd className="col-sm-9">
                          <p>Inventory control</p>
                          <p>Cross docking</p>
                          <p>Fulfilment and pick & pack services</p>
                          <p>Transportation and transloading</p>
                        </dd>
                        <dt className="col-sm-3">
                          Logistics and transportation category
                        </dt>
                        <dd className="col-sm-9">
                          Manufacturing and processing
                        </dd>
                        <dt className="col-sm-3">Fleet type</dt>
                        <dd className="col-sm-9">Corporation</dd>
                        <dt className="col-sm-3">Related services</dt>
                        <dd className="col-sm-9">
                          <p>Dealer - trucks</p>
                          <p>Dealer - trailers</p>
                        </dd>
                      </dl>
                    </div>
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Documentation</h5>
                      <button className="contact-box d-flex justify-content-between align-items-center uploaded w-100">
                        <div className="d-flex align-items-center">
                          <span className="circle32">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8.66663 1.33301H3.99996C3.64634 1.33301 3.3072 1.47348 3.05715 1.72353C2.8071 1.97358 2.66663 2.31272 2.66663 2.66634V13.333C2.66663 13.6866 2.8071 14.0258 3.05715 14.2758C3.3072 14.5259 3.64634 14.6663 3.99996 14.6663H12C12.3536 14.6663 12.6927 14.5259 12.9428 14.2758C13.1928 14.0258 13.3333 13.6866 13.3333 13.333V5.99967M8.66663 1.33301L13.3333 5.99967M8.66663 1.33301V5.99967H13.3333"
                                stroke="#054757"
                                strokeWidth="1.33333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <div className="ms-2 text-start">
                            <strong>Article of business.pdf</strong>
                            <strong>
                              <u>Download</u>
                            </strong>
                          </div>
                        </div>
                        <div>
                          <img src="images/verif-done.svg" alt="" />
                        </div>
                      </button>
                    </div>
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Shipping</h5>
                      <dl className="row business_list mb-0 gy-2">
                        <dt className="col-sm-3">Shipping address</dt>
                        <dd className="col-sm-9">
                          Rochester city, New York, New York, 10001
                        </dd>
                        <dt className="col-sm-3">
                          Logistics and transportation category
                        </dt>
                        <dd className="col-sm-9">
                          Manufacturing and processing
                        </dd>
                        <dt className="col-sm-3">Fleet type</dt>
                        <dd className="col-sm-9">Corporation</dd>
                      </dl>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-tales"
                    role="tabpanel"
                    aria-labelledby="v-tales-tab"
                    tabIndex="0"
                  >
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">
                        Terminals and warehousing
                      </h5>
                      {/* </svg></button> */}
                      <p className="text-muted mb-0">
                        No shipping address added
                      </p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-Buddies"
                    role="tabpanel"
                    aria-labelledby="v-Buddies-tab"
                    tabIndex="0"
                  >
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold">Buddies</h5>
                      {/* <div className="text-muted mb-3">0</div> */}
                      <p className="text-muted mb-0 py-4 text-center">No buddies added</p>
                    </div>

                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Suggestions</h5>
                      <p className="text-muted mb-4">
                        Based on years of experience, location, language spoken,
                        here’s some buddy suggestions
                      </p>

                      <div className="row g-3">
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-1.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Theo Bryce
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-2.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Charlie Baker
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-3.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Zak Gabrielle
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-4.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Theo Bryce
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-5.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Charlie Baker
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-6.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Zak Gabrielle
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-Followers"
                    role="tabpanel"
                    aria-labelledby="v-Followers-tab"
                    tabIndex="0"
                  >
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold">Followers</h5>
                      {/* <div className="text-muted mb-3">0</div> */}
                      <p className="text-muted mb-0 py-4 text-center">
                        You don’t have followers yet
                      </p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-Following"
                    role="tabpanel"
                    aria-labelledby="v-Following-tab"
                    tabIndex="0"
                  >
                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold">Following</h5>
                      {/* <div className="text-muted mb-3">0</div> */}
                      <p className="text-muted mb-0 py-4 text-center">
                        You are not following anyone yet
                      </p>
                    </div>

                    <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                      <h5 className="fw-bold mb-3">Suggestions</h5>
                      <p className="text-muted mb-4">
                        Based on years of experience, location, interests,
                        here’s some suggestions
                      </p>

                      <div className="row g-3">
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-1.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Theo Bryce
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-2.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Charlie Baker
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-3.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Zak Gabrielle
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-4.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Theo Bryce
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-5.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Charlie Baker
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="border-0 card rounded-4 shadow-sm">
                            <div className="card-body">
                              <img
                                src="images/driver.svg"
                                alt="driver"
                                className="mb-2"
                              />
                              <img
                                src="images/buddies-6.jpg"
                                className="rounded-4 img-fluid"
                                alt="img"
                              />
                              <h5 className="card-title fw-bold mb-4 mt-3">
                                Zak Gabrielle
                              </h5>
                              <a href="#" className="btn w-100 btn-light-theme">
                                Add buddy
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FleetOwnerBuddyProfile
