import * as React from 'react'
import './App.css'
import UserSession from './Components/UserSession'
import Internationalization from './internationalization'
import Routes from './routes'

function App() {
  let userList = JSON.parse(localStorage.getItem('usersList'))
  userList =
    userList === '' || userList === null || userList === undefined
      ? []
      : userList
  localStorage.setItem('usersList', JSON.stringify(userList))

  let user = JSON.parse(localStorage.getItem('user'))
  user = user === '' || user === null || user === undefined ? {} : user
  localStorage.setItem('user', JSON.stringify(user))

  let loogedInUserbuddyList = JSON.parse(
    localStorage.getItem('loogedInUserbuddyList')
  )
  loogedInUserbuddyList =
    loogedInUserbuddyList === '' ||
    loogedInUserbuddyList === null ||
    loogedInUserbuddyList === undefined
      ? []
      : loogedInUserbuddyList
  localStorage.setItem(
    'loogedInUserbuddyList',
    JSON.stringify(loogedInUserbuddyList)
  )

  let requestSentToUserbuddyList = JSON.parse(
    localStorage.getItem('requestSentToUserbuddyList')
  )
  requestSentToUserbuddyList =
    requestSentToUserbuddyList === '' ||
    requestSentToUserbuddyList === null ||
    requestSentToUserbuddyList === undefined
      ? []
      : requestSentToUserbuddyList
  localStorage.setItem(
    'requestSentToUserbuddyList',
    JSON.stringify(requestSentToUserbuddyList)
  )

  let notificationList = JSON.parse(localStorage.getItem('notificationList'))
  notificationList =
    notificationList === '' ||
    notificationList === undefined ||
    notificationList === null
      ? []
      : notificationList
  localStorage.setItem('notificationList', JSON.stringify(notificationList))

  let usercategory = JSON.parse(localStorage.getItem('usercategory'))
  usercategory =
    usercategory === '' || usercategory === null || usercategory === undefined
      ? ''
      : usercategory
  localStorage.setItem('usercategory', JSON.stringify(usercategory))

  let userSession = JSON.parse(sessionStorage.getItem('userSession'))
  userSession =
    userSession === '' || userSession === null || userSession === undefined
      ? ''
      : userSession
  sessionStorage.setItem('userSession', JSON.stringify(userSession))

  // let accessToken = JSON.parse(sessionStorage.getItem('accessToken'));
  // accessToken = accessToken === '' || accessToken === null || accessToken === undefined ? {} : accessToken
  // sessionStorage.setItem('accessToken', JSON.stringify(accessToken));

  return (
    <UserSession>
      <Internationalization>
        <Routes />
      </Internationalization>
    </UserSession>
  )
}

export default App
