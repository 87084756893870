import { api } from '../Configs/AxiosConfigs'
import { defineCancelApiObject } from '../Configs/AxiosUtils'

export const ProfileApis = {
  userForgotPassword: async function (username, password, cancel = false) {
    console.log(`/v1/user/forgotpassword`, api, password, username)
    const response = await api.request({
      url: `/v1/user/forgotpassword`,
      method: 'POST',
      headers: {},
      data: {
        username: username, // This is the body part
        password: password,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  getuserProfile: async function (profile, cancel = false) {
    console.log(`/v1/user/profile`, api)
    const response = await api.request({
      url: `/v1/user/profile`,
      method: 'POST',
      headers: {},
      data: profile,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateuserProfile: async function (profile, cancel = false) {
    console.log(`/v1/user/profile`, api)
    const response = await api.request({
      url: `/v1/user/profile`,
      method: 'PUT',
      headers: {},
      data: profile,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateuserProfileForDriver: async function (profile, type = 'driver', cancel = false) {
    console.log(`/v1/user/profile/${type}`, api)
    const response = await api.request({
      url: `/v1/user/profile/${type}`,
      method: 'PUT',
      headers: {},
      data: profile,
    })

    return response.data
  },
  userVerifyUserName: async function (usernameObj, cancel = false) {
    console.log(`/v1/user/verifyUserName`, api)
    const response = await api.request({
      url: `/v1/user/verifyUserName`,
      method: 'POST',
      headers: {},
      data: usernameObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  // userVerifyUserName: async function (usernameObj, cancel = false) {
  //   console.log(`/v1/user/verifyUserName`, api);
  //   const response = await api.request({
  //     url: `/v1/user/verifyUserName`,
  //     method: "POST",
  //     headers: {},
  //     data: usernameObj,
  //     signal: cancel
  //       ? cancelApiObject[this.get.name].handleRequestCancellation().signal
  //       : undefined,
  //   });
  //   console.log("response", response);

  //   return response.data;
  // },
  userMobileStatus: async function (obj, cancel = false) {
    console.log(`/v1/user/mobile/status`, api)
    const response = await api.request({
      url: `/v1/user/mobile/status`,
      method: 'POST',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  userVerifyEmail: async function (emailObj, cancel = false) {
    console.log(`/v1/user/verifyEmail`, api)
    const response = await api.request({
      url: `/v1/user/verifyEmail`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  userVerifyMobile: async function (mobileObj, cancel = false) {
    console.log(`/v1/user/verifyMobile`, api)
    const response = await api.request({
      url: `/v1/user/verifyMobile`,
      method: 'POST',
      headers: {},
      data: mobileObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  userEmailStatus: async function (obj, cancel = false) {
    console.log(`/v1/user/email/status`, api)
    const response = await api.request({
      url: `/v1/user/email/status`,
      method: 'POST',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  createProfileTag: async function (obj, cancel = false) {
    console.log(`/v1/user/profile/profileTag/create`, api)
    const response = await api.request({
      url: `/v1/user/profile/profileTag/create`,
      method: 'POST',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  viewProfileTag: async function (obj, cancel = false) {
    console.log(`/v1/user/profile/profileTag`, api)
    const response = await api.request({
      url: `/v1/user/profile/profileTag`,
      method: 'POST',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  updateProfileTag: async function (obj, cancel = false) {
    console.log(`/v1/user/profile/profileTag`, api)
    const response = await api.request({
      url: `/v1/user/profile/profileTag`,
      method: 'PUT',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  deleteProfileTag: async function (obj, cancel = false) {
    console.log(`/v1/user/profile/profileTag/delete`, api)
    const response = await api.request({
      url: `/v1/user/profile/profileTag/delete`,
      method: 'POST',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  // userProfileImage: async function (email, cancel = false) {
  //   console.log(`/v1/profile/image`, api);
  //   const response = await api.request({
  //     url: `/v1/profile/image`,
  //     method: "POST",
  //     headers: {},
  //     data: email,
  //     signal: cancel
  //       ? cancelApiObject[this.get.name].handleRequestCancellation().signal
  //       : undefined,
  //   });
  //   console.log("response", response);

  //   return response.data;
  // },
  userProfileImage: async function (email, cancel = false) {
    console.log(`/v1/profile/image`, api)
    const response = await api.request({
      url: `/v1/profile/image`,
      method: 'POST',
      headers: {},
      data: email,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  buddyProfileImage: async function (userObj, cancel = false) {
    console.log(`/v1/profile/image/{userId}`, api)
    let accesToken = {
      fre8ProAccessToken: userObj?.fre8ProAccessToken,
    }
    const response = await api.request({
      url: `/v1/profile/image/${userObj.userId}`,
      method: 'POST',
      headers: {},
      data: accesToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  // userPassword: async function (email, cancel = false) {
  //   console.log(`/v1/user/password`, api);
  //   const response = await api.request({
  //     url: `/v1/user/password`,
  //     method: "PUT",
  //     headers: {},
  //     data: {
  //       email,
  //     },
  //     signal: cancel
  //       ? cancelApiObject[this.get.name].handleRequestCancellation().signal
  //       : undefined,
  //   });
  //   console.log("response", response);

  //   return response.data;
  // },
  userPassword: async function (email, cancel = false) {
    console.log(`/v1/user/password`, api)
    const response = await api.request({
      url: `/v1/user/password`,
      method: 'PUT',
      headers: {},
      data: {
        email,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  userProfileUploadImage: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/profile/uploadImage`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response?.data
  },
  userCoverUploadImage: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/profile/uploadCoverPhoto`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response?.data
  },
  userVerifyResendMobileCode: async function (mobileObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/verify/resendMobileCode`,
      method: 'POST',
      headers: {},
      data: mobileObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  userVerifyResendEmailCode: async function (emailObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/verify/resendEmailCode`,
      method: 'POST',
      headers: {},
      data: emailObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  getProfilePersonalInformation: async function (token, cancel = false) {
    let accessToken = {
      fre8ProAccessToken: token,
    }
    const response = await api.request({
      url: `/v1/user/profile/personalinfo`,
      method: 'POST',
      headers: {},
      data: accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateProfilePersonalInformation: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/profile/personalinfo`,
      method: 'PUT',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateFleetOwnerUserProfile: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/profile/fo`,
      method: 'PUT',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  //driver apis

  //Follow api
  //Send request to un follow.
  sendUserUnfollowRequest: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/unfollow/request/send`,
      method: 'PUT',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  //Send request to follow.
  sendUserFollowRequest: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/follow/request/send`,
      method: 'PUT',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  //Get all the user whom Logged in User Follows.
  getLoggedInUserFollowingList: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/follow/other`,
      method: 'POST',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  getTargetUserFollowingList: async function (personalObj, cancel = false) {
    let accessToken = {
      fre8ProAccessToken: personalObj?.fre8ProAccessToken,
    }
    const response = await api.request({
      url: `/v1/user/follow/other/${personalObj?.targetUserId}`,
      method: 'POST',
      headers: {},
      data: accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  updateProfileIntersets: async function (route, token, cancel = false) {
    const response = await api.request({
      url: `/v1/user/profile/meta/${route}`,
      method: 'POST',
      headers: {},
      data: {
        fre8ProAccessToken: token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  //Get all the user who are buddies for Logged in User.\
  getLoggedInUserBuddiesList: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/buddy`,
      method: 'POST',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  //Get all the user who follow Logged in User.
  getLoggedInUserFollowersList: async function (personalObj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/follow/me`,
      method: 'POST',
      headers: {},
      data: personalObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  getTargetUserBuddiesList: async function (personalObj, cancel = false) {
    let accessToken = {
      fre8ProAccessToken: personalObj?.fre8ProAccessToken,
    }
    const response = await api.request({
      url: `/v1/buddy/${personalObj?.targetUserId}`,
      method: 'POST',
      headers: {},
      data: accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  getTargetUserFollowersList: async function (personalObj, cancel = false) {
    let accessToken = {
      fre8ProAccessToken: personalObj?.fre8ProAccessToken,
    }
    const response = await api.request({
      url: `/v1/user/follow/me/${personalObj?.targetUserId}`,
      method: 'POST',
      headers: {},
      data: accessToken,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  updateProfileCouch: async function (route, token, cancel = false) {
    const response = await api.request({
      url: `/v1/user/profile/couch/meta/${route}`,
      method: 'POST',
      headers: {},
      data: {
        fre8ProAccessToken: token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  userPersonalInfoEdit: async function (Obj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/profile/personalinfo`,
      method: 'PUT',
      headers: {},
      data: Obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  userPasswordChanger: async function (Obj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/password`,
      method: 'PUT',
      headers: {},
      data: Obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  userMobileUpdate: async function (obj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/mobile`,
      method: 'PUT',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  userEmailUpdate: async function (obj, cancel = false) {
    const response = await api.request({
      url: `/v1/user/email`,
      method: 'PUT',
      headers: {},
      data: obj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  craeteEvent: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  loadEventsPostedByLoggedInUser: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/posted/${reqObj?.userId}`,
      method: 'post',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },
  loadAllSuggestedEventsForLoggedInUser: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event/all/view/${reqObj?.userId}`,
      method: 'post',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  loadAnEventByUsingEventId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event/${uploadImgObj.eventId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  getAllGoingEventsForUser: async function (userobj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event/all/viewgoing/${userobj?.userId}`,
      method: 'post',
      headers: {},
      data: userobj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  setGoingForAnEventForUser: async function (userobj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/going`,
      method: 'put',
      headers: {},
      data: userobj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  setNotGoingForAnEventForUser: async function (userobj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/notgoing`,
      method: 'put',
      headers: {},
      data: userobj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  cancelEventByUsingEventId: async function (reqObj, cancel = false) {
    const response = await api.request({
      url: `/v1/event/cancel`,
      method: 'put',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })
    return response.data
  },

  updateEvent: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event/update`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  //interested selected
  eventSelectInterestedEventId: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/interested`,
      method: 'put',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },

  eventsSavedPastUserId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/saved/past/${uploadImgObj?.userId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  // eventsSavedPastUserId: async function (uploadImgObj, cancel = false) {
  //   console.log(`UpdateImageObject`, uploadImgObj)

  //   // let headerVal = { "content-type": "multipart/form-data" }
  //   const response = await api.request({
  //     url: `/v1/events/saved/past/${uploadImgObj?.userId}`,
  //     method: 'post',
  //     headers: {},
  //     data: uploadImgObj,
  //     signal: cancel
  //       ? cancelApiObject[this.get.name].handleRequestCancellation().signal
  //       : undefined,
  //   })
  //   console.log('response', response)

  //   return response.data
  // },
  eventsSavedUserId: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/event/all/viewsaved/${reqObj.userId}`,
      method: 'post',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventSelectUnsavedEventId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/unsaved`,
      method: 'put',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsGoingPastUserId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/going/past/${uploadImgObj?.userId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsSavedUpcomingUserId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/saved/upcoming/${uploadImgObj?.userId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventSelectSaveEventId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/saved`,
      method: 'put',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsGoingUpcomingUserId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/going/upcoming/${uploadImgObj?.userId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventSelectNotinterestedEventId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/notinterested`,
      method: 'put',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsInterestedUserId: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      // url: `/v1/events/past/interected/${reqObj.userId}`,
      url: `/v1/event/all/viewinterested/${reqObj.userId}`,
      method: 'post',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsPastUserId: async function (reqObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/events/past/interected/${reqObj.userId}`,
      method: 'post',
      headers: {},
      data: reqObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  eventsInterestedUpcomingUserId: async function (uploadImgObj, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `  /v1/events/interested/upcoming/${uploadImgObj?.userId}`,
      method: 'post',
      headers: {},
      data: uploadImgObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
  viewMembershipPlans: async function (bodyObj, userId, cancel = false) {
    // let headerVal = { "content-type": "multipart/form-data" }
    const response = await api.request({
      url: `/v1/ms/plan/all/${userId}`,
      method: 'post',
      headers: {},
      data: bodyObj,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    return response.data
  },
}
// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ProfileApis)
