import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FleetServicesView from './FleetServicesView'
import FleetServicesFormik from './FleetServicesFormik'
import { useSelector } from 'react-redux'
import Toasts from '../../../../Components/Toasts/Toasts'

const FleetServices = ({ setSelection }) => {
  const { frProUser } = useSelector((state) => state.Users)
  const [toasts, setToasts] = useState(null)
  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.wareHouseServiceFlag ? true : false
  )
  const arrPartOfLogisticsYes = [
    'Common Carrier/Bulk Delivery',
    'Construction/Mining',
    'Food Beverage/ Agriculture',
    'Petroleum/Fuel/Oil',
    'Refuse/Recycling',
    'Manufacturing/Processing',
    'Retail/Wholesale delivery',
    'General Freight',
    'Municipal/Govt/Public safety',
    'Utilities',
    'Bus',
    'Truck/Trailor Leasing',
  ]

  const arrPartOfLogisticsNo = ['Manufacturing', 'Retail', 'Service']

  return (
    //This is a wrapper for FleetServicesView and FleetServicesFormik
    <div>
      {saving ? (
        <FleetServicesView
          setToasts={setToasts}
          setSaving={setSaving}
          setSelection={setSelection}
        />
      ) : (
        <>
          <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
            <div className="row">
              <div className="col-md-4">
                <h5 className="fw-bold mb-3">Services offered</h5>
              </div>
              <div className="col-md-7 ms-auto">
                <div className="row g-4">
                  <div className="col-md-12 col-lg-8">
                    <FleetServicesFormik
                      setSaving={setSaving}
                      setSelection={setSelection}
                      arrPartOfLogisticsYes={arrPartOfLogisticsYes}
                      arrPartOfLogisticsNo={arrPartOfLogisticsNo}
                      setToasts={setToasts}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </div>
  )
}

export default FleetServices
FleetServices.propTypes = {
  setSelection: PropTypes.func,
}
