import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import InputField from '../../../../Components/inputField'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import { FormHelperText } from '@mui/material'
import ErrorHandle from '../../../../Components/ErrorHandle'
import { formatPhoneNumber } from '../../../../utils/GeneralUtils'
import { CanadaStates, UsaStates } from '../../../../Data/states-cities'

const FleetContactFormik = ({ setSaving, setSelection, setToasts }) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  const { countriesList, statesList } = useSelector(
    (state) => state.GeneralData
  )
  const emailRules = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

  return (
    <Formik
      initialValues={{
        primaryEmail: frProUser?.updateProfileForm?.primaryEmail,
        primaryMobileNumber: frProUser?.updateProfileForm?.primaryMobileNumber,
        secondaryMobileNumber:
          frProUser?.updateProfileForm?.secondaryMobileNumber,
        website: frProUser?.updateProfileForm?.website,
        country: frProUser?.updateProfileForm?.billingAddress?.country,
        state: frProUser?.updateProfileForm?.billingAddress?.state,
        city: frProUser?.updateProfileForm?.billingAddress?.city,
        zip: frProUser?.updateProfileForm?.billingAddress?.postalCode,
      }}
      validationSchema={Yup.object().shape({
        primaryEmail: Yup.string()
          .matches(emailRules, 'please Enter Proper Email Id')
          .required('Email is required'),
        primaryMobileNumber: Yup.string().required(
          'Invalid phone number format. Please provide phone number in this format: +1 (555) 000-0000'
        ),
        country: Yup.string().required('country required'),
        state: Yup.string().required('state required'),
        city: Yup.string().required('city required'),
        zip: Yup.string().required('zip required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const fleetContactData = {
          primaryEmail: values?.primaryEmail,
          primaryMobileNumber: values?.primaryMobileNumber,
          secondaryMobileNumber: values?.secondaryMobileNumber,
          website: values?.website,
          billingAddress: {
            ownerId: frProUser?.fre8ProUserId,
            profileId: frProUser?.fre8ProUserId,
            addressId: values?.country,
            address: values?.country,
            city: values?.city,
            coordinates: {
              lat: '',
              lng: '',
            },
            postalCode: values?.zip,
            state: values?.state,
            country: values?.country,
          },
        }
        try {
          const data = {
            ...frProUser?.updateProfileForm,
            fre8ProAccessToken: frProUser?.fre8ProAccessToken,
            ...fleetContactData,
          }
          ProfileApis.updateFleetOwnerUserProfile(data).then(() => {
            dispatch(
              setFrProUser({
                frProUser: {
                  ...frProUser,
                  updateProfileForm: {
                    ...frProUser?.updateProfileForm,
                    ...fleetContactData,
                  },
                },
              })
            )
          })
          setSaving(true)
        } catch (err) {
          console.log(err)
          let customMessage = 'Updating Fleet contact failed'
          let type = 'User Profile'
          let color = 'red'
          ErrorHandle(err, setToasts, customMessage, type, color)
        }
      }}
    >
      {({ errors, handleSubmit, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
            <div className="row">
              <div className="col-md-4">
                <h5 className="fw-bold mb-3">Contact</h5>
              </div>
              <div className="col-md-7 ms-auto">
                <div className="row g-4">
                  <div className="col-md-12">
                    <h3 className="font18-black py-3">Basic contact</h3>
                    <label className="form-label">
                      Primary email <span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputField
                      type={'text'}
                      values={values}
                      name={'primaryEmail'}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <label className="form-label">
                      Primary mobile number{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className="phone-list">
                      <div className="input-group phone-input ">
                        <span className="input-group-btn w-60">
                          <select className="form-select rounded-end-0 h-100">
                            <option>US</option>
                            <option>CN</option>
                          </select>
                        </span>
                        <input
                          autoComplete
                          type="string"
                          className="form-control"
                          placeholder="+1 (555) 000-0000"
                          value={values?.primaryMobileNumber}
                          onChange={(e) =>
                            setFieldValue(
                              'primaryMobileNumber',
                              e?.currentTarget?.value,
                              { shouldValidate: true }
                            )
                          }
                          onBlur={(event) => {
                            let number = formatPhoneNumber(
                              event?.currentTarget?.value
                            )
                            setFieldValue('primaryMobileNumber', number, {
                              shouldValidate: true,
                            })

                            console.log(
                              'set primaryMobileNumber:',
                              touched?.primaryMobileNumber,
                              errors?.primaryMobileNumber
                            )
                          }}
                        />
                        {touched?.primaryMobileNumber &&
                          errors?.primaryMobileNumber && (
                            <FormHelperText
                              error
                              id="helper-text-primaryMobileNumber"
                            >
                              {errors?.primaryMobileNumber}
                            </FormHelperText>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <label className="form-label">
                      Secondary mobile number
                    </label>
                    <div className="phone-list">
                      <div className="input-group phone-input ">
                        <span className="input-group-btn w-60">
                          <select className="form-select rounded-end-0 h-100">
                            <option>US</option>
                            <option>CN</option>
                          </select>
                        </span>
                        <input
                          autoComplete
                          type="string"
                          className="form-control"
                          placeholder="+1 (555) 000-0000"
                          value={values?.secondaryMobileNumber}
                          onChange={(e) =>
                            setFieldValue(
                              'secondaryMobileNumber',
                              e?.currentTarget?.value,
                              { shouldValidate: true }
                            )
                          }
                          onBlur={(event) => {
                            let number = formatPhoneNumber(
                              event?.currentTarget?.value
                            )
                            setFieldValue('secondaryMobileNumber', number, {
                              shouldValidate: true,
                            })

                            console.log(
                              'set secondaryMobileNumber:',
                              touched?.secondaryMobileNumber,
                              errors?.secondaryMobileNumber
                            )
                          }}
                        />
                        {touched?.secondaryMobileNumber &&
                          errors?.secondaryMobileNumber && (
                            <FormHelperText
                              error
                              id="helper-text-secondaryMobileNumber"
                            >
                              {errors?.secondaryMobileNumber}
                            </FormHelperText>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">website</label>
                    <InputField
                      type={'text'}
                      values={values}
                      name={'website'}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <h3 className="font18-black">Location</h3>
                  <div className="col-md-12">
                    <label className="form-label">
                      country <span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      id="country"
                      value={values?.country}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setFieldValue('country', e?.currentTarget?.value, {
                          shouldValidate: true,
                        })
                      }}
                    >
                      {values.country ? '' : <option>Please select</option>}
                      {countriesList?.map((country) => {
                        return (
                          country?.countryName !== 'Mexico' && (
                            <option
                              key={country?.countryId}
                              value={country?.countryName}
                            >
                              {country?.countryName}
                            </option>
                          )
                        )
                      })}
                    </select>
                    {touched.country && errors.country && (
                      <FormHelperText error id="helper-text-country">
                        {errors.country}
                      </FormHelperText>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      State/Province <span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                      id="state"
                      value={values?.state}
                      className="form-select"
                      onChange={(e) => {
                        setFieldValue('state', e?.currentTarget?.value, {
                          shouldValidate: true,
                        })
                      }}
                    >
                      {values.state ? '' : <option>Please select</option>}
                      {statesList[values?.country]?.map((state) => (
                        <option key={state?.stateId} value={state?.stateName}>
                          {state?.stateName}
                        </option>
                      ))}
                    </select>

                    {touched.state && errors.state && (
                      <FormHelperText error id="helper-text-state">
                        {errors.state}
                      </FormHelperText>
                    )}
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">
                      City <span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputField
                      tag={'select'}
                      values={values}
                      name={'city'}
                      touched={touched}
                      errors={errors}
                    >
                      {values?.city ? (
                        <option value={values?.city}>{values?.city}</option>
                      ) : (
                        <option>Please select</option>
                      )}
                      {values?.country === 'Canada'
                        ? CanadaStates[values?.state]?.cities?.map(
                          (cites) => (
                            <option key={cites} value={cites}>
                              {cites}
                            </option>
                          )
                        )
                        : UsaStates[values?.state]?.cities?.map((cites) => (
                          <option key={cites} value={cites}>
                            {cites}
                          </option>
                        ))}
                    </InputField>
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      ZIP <span style={{ color: 'red' }}>*</span>
                    </label>
                    <InputField
                      tag={'select'}
                      values={values}
                      name={'zip'}
                      touched={touched}
                      errors={errors}
                    >
                      {values.zip ? '' : <option>Please select</option>}
                      <option value="500045">500045</option>
                      <option value="400023">400023</option>
                      <option value="300078">300078</option>
                    </InputField>
                  </div>
                  <div className="mt-4">
                    <button type="submit" className="btn btn-theme me-2">
                      Save
                    </button>
                    <button
                      onClick={() =>
                        frProUser?.updateProfileForm?.primaryEmail
                          ? setSaving(true)
                          : setSelection('')
                      }
                      type="button"
                      className="btn btn-border"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default FleetContactFormik
FleetContactFormik.propTypes = {
  setSelection: PropTypes.func,
  setSaving: PropTypes.func,
  selectedState: PropTypes.func,
  selectedCountry: PropTypes.func,
  states: PropTypes.array,
  handleCountryChange: PropTypes.func,
  handleStateChange: PropTypes.func,
  countries: PropTypes.array,
  setToasts: PropTypes.func,
}
