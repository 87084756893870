import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
// import { store } from "./store";
// import { store as storePlus } from  './store';
import { PersistGate } from 'redux-persist/integration/react'
import { persiststore, store as storePlus } from './store/reducers/persist'

import './css/bootstrap.css'
import './css/bootstrap.min.css'
import './css/dashboard.css'
import './css/profile.css'
import './css/responsive.css'
import './css/style.css'
import './css/customCSS.css'
import ErrorBoundary from './layout/ErrorLayout/ErrorBoundary'

// const root = ReactDOM.createRoot(document.getElementById('root'));
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  // <StrictMode>
  <ReduxProvider store={storePlus}>
    <PersistGate persistor={persiststore} loading={null}>
      <BrowserRouter basename="/">
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
  // </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
