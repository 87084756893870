// import { Box, Container } from "@mui/material";
import PropTypes from 'prop-types'
import React from 'react'
import StandardImaCustomMessagesList from './CustomSearchFriend'
// import { useTranslation } from "react-i18next";
// import ProfilepageTabs from "../ProfilePage_new";
// import CustomMessages from "./CustomMessages";

const CustomSearchFriendWrapper = (props) => {
  // const { t } = useTranslation();
  return (
    <>
      <StandardImaCustomMessagesList />
    </>
  )
}

CustomSearchFriendWrapper.propTypes = {
  showProfileHeader: PropTypes.bool,
}

export default CustomSearchFriendWrapper
