import { useRoutes } from 'react-router-dom'
import ErrorRoutes from './ErrorRoutes'
import LoginRoutes from './LoginRoutes'
import ProfileRoutes from './ProfileRoutes'
import UnRegisteredUserRoutes from './UnRegisteredUserRoutes'
// const Profilepage = Loadable(
//   lazy(() => import("../Pages/ProfilePage_new/Profile")),
// );

// import Loadable from "../Components/Loadable";
// import BusinessSignIn from "../Pages/authentication/businessSignIn";
// import BusinessSignUp from "../Pages/authentication/businessSignUp";
// import WithoutFormikLogin from '../Pages/authentication/withoutFormikLogin';
// import WithoutFormik from '../Pages/authentication/withoutFormikRegister';
// import Business from "../Pages/business";
// import BusinessBuildProfilePage1 from "../Pages/business/businessBuildProfilePage1";
// import BusinessBuildProfilePage2 from "../Pages/business/businessBuildProfilePage2";
// import CreatePasswordWrapper from "../Pages/changePassword";
// import BusinessCreatePassword from "../Pages/changePassword/businessCreatePassword";
// import ResetPasswordVerificationCode from "../Pages/changePassword/resetPasswordVerificationCode";
// import FirstTimeUser from "../Pages/firstTimeUser";
// import OTPScreenWrapper from "../Pages/otpScreen";
// import Verification from "../Pages/verification";
// import LoginLayout from '../layout/LoginLayout';
// import MainLayout from '../layout/MainLayout';
// import MinimalLayout from '../layout/MinimalLayout';

// const Profilepage = Loadable(
//   lazy(() => import("../Pages/ProfilePage_new/Profile")),
// );
// const HomepageNew = Loadable(lazy(() => import("../Pages/Homepage_new")));
// project import

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  // return <Routes >
  //   <Route path="/" element={<LoginLayout />}>
  //     <Route path="/login" element={<WithoutFormikLogin />} />
  //     <Route path="/register" element={<WithoutFormik />} />
  //   </Route>
  //   <Route path="/" element={<MinimalLayout />}>
  //     <Route path='/otpScreen' element={<OTPScreenWrapper />} />
  //     <Route path='/business' element={<Business />} />
  //     <Route path='/createpassword' element={<CreatePasswordWrapper />} />
  //     <Route path='/firstTimeUser' element={<FirstTimeUser />} />
  //     <Route path='/businesscreatepassword' element={<BusinessCreatePassword />} />
  //     <Route path='/businesssignin' element={<BusinessSignIn />} />
  //     <Route path='/businesssignup' element={<BusinessSignUp />} />
  //     <Route path='/businessbuildprofilepage1' element={<BusinessBuildProfilePage1 />} />
  //     <Route path='/businessbuildprofilepage2' element={<BusinessBuildProfilePage2 />} />
  //     <Route path='/verificationcode' element={<Verification />} />
  //     <Route path='/resetpasswordverificationcode' element={<ResetPasswordVerificationCode />} />
  //   </Route>
  //   <Route path="/" element={<MainLayout />}>
  //     <Route path='/home' element={<HomepageNew />} />
  //     <Route path='/profile' element={<Profilepage />} />
  //   </Route>
  // </Routes >
  return useRoutes([
    ErrorRoutes,
    ProfileRoutes,
    LoginRoutes,
    UnRegisteredUserRoutes,
  ])
}
