import PropTypes from 'prop-types'
import * as React from 'react'

export default function StandardImaCustomMessagesList(props) {
  let data = props?.itemData || itemData
  return (
    <>
      {/* {data?.map((item) => (
        <>
          <div className={props?.loading ? "loading-skeleton col" : "col"}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              className="img-fluid"
            />
          </div>
        </>
      ))} */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="notification">
              <div className="title">
                Messages <span className="badge bg-orange ms-2">5 new</span>
              </div>
              <hr />
              {data?.map((item, i) => (
                <>
                  <div key={i} className="nBar mb-3">
                    <div>
                      <img
                        style={{
                          objectFit: 'cover',
                          height: '50px',
                          width: '50px',
                          borderRadius: '50%',
                          marginRight: '2rem',
                        }}
                        src={item.img}
                        alt={item.name}
                      />
                    </div>
                    <div>
                      <div className="name">
                        <b>{item.name}</b>
                      </div>
                      <p>{item.message}</p>
                    </div>
                    <div className="time ms-auto">{item.time}</div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
StandardImaCustomMessagesList.propTypes = {
  loading: PropTypes.bool,
  itemData: PropTypes.object,
}
const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    name: 'Ompreet Khosla',
    time: '10 mins',
    message: 'Hello! How are you.',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    name: 'Mahinder',
    time: '1hrs',
    message:
      "Hello! I'm going to meet my frend at the department store as soon as possible.",
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    name: 'Ranveer Singh',
    time: '10:00 AM',
    message: 'How are you my friend',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    name: 'Ompreet Khosla',
    time: '2 days ago',
    message: 'when will meet buddy?',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    name: 'Ranveer Singh',
    time: '10:00 AM',
    message: 'where are you',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    name: 'Ompreet Khosla',
    time: '2 days ago',
    message: 'your Dp is nice buddy',
  },
]
