// types
import { createSlice, current } from '@reduxjs/toolkit'

// initial state
const initialState = {
  allEventsListFromReducer: [],
  allPostedListFromReducer: [],
  allGoingListFromReducer: [],
  allSavedListFromReducer: [],
  allInterestedListFromReducer: [],
  imagesArrayForEvents: [],
}

// ==============================|| SLICE - Followers ||============================== //

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    reset: () => initialState,
    getAllEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allEventsListFromReducer
    },
    setAllEventsListInReducer(state, action) {
      console.log('Action palylod', action.payload)
      state.allEventsListFromReducer = action.payload.allEventsList
    },
    getPostedEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allPostedListFromReducer
    },
    setPostedEventsListInReducer(state, action) {
      console.log('Action palylod', action.payload)
      state.allPostedListFromReducer = action.payload.allPostedEventsList
    },
    getGoingEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allGoingListFromReducer
    },
    setGoingEventsListInReducer(state, action) {
      console.log('Action palylod', action.payload)
      state.allGoingListFromReducer = action.payload.allGoingEventsList
    },

    getSavedEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allSavedListFromReducer
    },
    setSavedEventsListInReducer(state, action) {
      console.log('Action palylod', action.payload)
      state.allSavedListFromReducer = action.payload.allSavedList
    },

    getInterestedEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allInterestedListFromReducer
    },
    setInterestedEventsListInReducer(state, action) {
      console.log('Action palylod', action.payload)
      state.allInterestedListFromReducer = action.payload.allInterestedList
    },

    getImagesForAllEventsListFromReducer(state, action) {
      // state.followersList = action.payload.followersList;
      return state.allEventsListFromReducer
    },
    setImagesForAllEventsListFromReducer(state, action) {
      console.log(
        'setImagesForAllEventsListFromReducer Action palylod',
        action.payload.allEventsListImages,
        current(state.imagesArrayForEvents),
        typeof current(state.imagesArrayForEvents)
      )
      // let allEventsListImagesVal = Object.assign({}, state.imagesArrayForEvents, {
      //     emailVerificationList: action.payload.allEventsListImages,
      // })
      // let allEventsListImagesVal = [] //state?.tempValToLocalStorage;

      // console.log("setImagesForAllEventsListFromReducer current(state) ", allEventsListImagesVal, "typeof allEventsListImagesVal", typeof (allEventsListImagesVal));
      // allEventsListImagesVal?.push(action.payload.allEventsListImages || [])
      var newArray =
        [
          ...current(state?.imagesArrayForEvents),
          ...action.payload.allEventsListImages,
        ].filter((value) => Object.keys(value || {})?.length !== 0) ||
        current(state?.imagesArrayForEvents)

      state.imagesArrayForEvents = [...newArray]
    },
  },
})

export default events.reducer

export const {
  getSavedEventsListInReducer,
  setSavedEventsListInReducer,
  setInterestedEventsListInReducer,
  getInterestedEventsListInReducer,
  getImagesForAllEventsListFromReducer,
  setImagesForAllEventsListFromReducer,
  getAllEventsListFromReducer,
  setAllEventsListInReducer,
  getPostedEventsListFromReducer,
  setPostedEventsListInReducer,
  getGoingEventsListFromReducer,
  setGoingEventsListInReducer,
  reset: resetEvents,
} = events.actions
