import PropTypes from 'prop-types'
import React from 'react'

const UserSession = ({ children }) => {
  // const navigate = useNavigate();
  // const [showPopUp, setshowPopUp] = useState(false)
  // const [toasts, setToasts] = useState(null)
  // useEffect(() => {
  // let session = JSON.parse(sessionStorage.getItem("userSession"));

  // if (session?.toLowerCase()?.includes("invalid")) {
  //     let item = [{
  //         name: "Please login again as either session expired or invalidated due to refresh",
  //         type: "Session expired"
  //     }]
  //     // setshowLoading(true)
  //     setToasts(item)
  //     setshowPopUp(true)
  //     setTimeout(() => {
  //         setshowPopUp(false)
  //         navigate("/");
  //     }, 3000);
  // }
  // window.onbeforeunload = beforeUnload;
  //check for Navigation Timing API support

  // if (window?.performance) {
  //     console.info("reload:::window.performance works fine on this browser");
  // }
  // console.info(performance.navigation.type);
  // if (performance?.navigation?.type === performance?.navigation?.TYPE_RELOAD) {
  //     console.info("reload:::This page is reloaded");
  // } else {
  //     console.info("reload:::This page is not reloaded");
  // }
  // const beforeUnload = () => {
  //     // alert("reload::: Dude, are you sure you want to refresh? Think of the kittens!");
  //     // window.localStorage.setItem("userDetails", JSON.parse({ 'key': "value" }))
  //     return false
  // }
  return (
    <>
      {/* {showPopUp && <div className="Content" style={{ width: "100%", height: 56, padding: 16, justifyContent: "flex-start", alignItems: "flex-start", gap: 1, display: "inline-flex" }}>
                <div className="Content" style={{ flex: "1 1 0", paddingLeft: 4, paddingRight: 4, paddingTop: 6, paddingBottom: 6, flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: 12, display: "inline-flex" }}>
                    <div className="This" style={{ alignSelf: "stretch" }}>
                        <span style={{ color: "#6CBC6E", fontSize: 14, fontFamily: "Inter", fontStyle: "italic", fontWeight: "600", lineHeight: 1, wordWrap: "break-word" }}>No buddies found with</span>
                        <span style={{ color: "#292929", fontSize: 14, fontFamily: "Inter", fontStyle: "italic", fontWeight: "600", lineHeight: 1, wordWrap: "break-word" }}> </span>
                        <span style={{ color: "#292929", fontSize: 14, fontFamily: "Inter", fontStyle: "italic", fontWeight: "400", lineHeight: 1, wordWrap: "break-word" }}>Please start typing.</span>
                    </div>
                </div> */}
      {/* <div className="Icon" style={{ width: 24, height: 24, position: "relative" }}>
            <div className="X" style={{ width: 8, height: 8, left: 8, top: 8, position: "absolute", border: "1.50px #80849D solid" }}></div>
        </div> */}
      {/* </div>} */}
      {/* {showPopUp && <Toasts toastsList={toasts}></Toasts>} */}
      <div>{children}</div>
    </>
  )
}

export default UserSession
UserSession.propTypes = {
  children: PropTypes.object,
  props: PropTypes.object,
}
