import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FleetContactView from './FleetContactView'
import FleetContactFormik from './FleetContactFormik'
import { useSelector } from 'react-redux'
import Toasts from '../../../../Components/Toasts/Toasts'
import useCountryAndStatesHook from '../../../../customHooks/useCountryAndStatesHook'

const FleetContact = ({ setSelection }) => {
  const { frProUser } = useSelector((state) => state.Users)
  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.primaryEmail ? true : false
  )
  const [toasts, setToasts] = useState(null)

  useCountryAndStatesHook(setToasts)

  const deleteFleetContactData = {
    ...frProUser?.updateProfileForm,
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    primaryEmail: '',
    primaryMobileNumber: '',
    secondaryMobileNummber: '',
    website: '',
    billingAddress: {
      ownerId: '',
      profileId: '',
      addressId: '',
      address: '',
      city: '',
      coordinates: {
        lat: '',
        lng: '',
      },
      postalCode: '',
      state: '',
      country: '',
    },
  }

  return (
    //This is a wrapper For FleetContactView And FleetContactFormik
    <>
      {saving ? (
        <FleetContactView
          setSaving={setSaving}
          setSelection={setSelection}
          deleteFleetContactData={deleteFleetContactData}
          setToasts={setToasts}
        />
      ) : (
        <FleetContactFormik
          setSaving={setSaving}
          setSelection={setSelection}
          setToasts={setToasts}
        />
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

export default FleetContact

FleetContact.propTypes = {
  setSelection: PropTypes.func,
}
