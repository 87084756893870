import PropTypes from 'prop-types'
import React from 'react'
import ErrorLayoutPage from './ErrorLayoutPage'
// import { useNavigate } from "react-router";

// const navigate = undefined;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorDetail: {
        error: null,
        errorInfo: null,
      },
    }
  }
  // navigate = useNavigate();
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo)
    // alert("error")
    // navigate('/home')
    this.setState({
      errorDetails: {
        error: error,
        errorInfo: errorInfo,
      },
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return <ErrorLayoutPage error={this.state.errorDetail} />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
}

export default ErrorBoundary
