import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import InputField from '../../../../Components/inputField'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import PropTypes from 'prop-types'
import FleetBusinessSummarySkeleton from './FleetBusinessSummaryView'
import Toasts from '../../../../Components/Toasts/Toasts'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetBuisinessSummary = ({ setSelection }) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)
  const [saving, setSaving] = useState(
    frProUser?.updateProfileForm?.businessName?.length > 0 ? true : false
  )

  const [toasts, setToasts] = useState(null)

  const deleteBusinessSummaryData = {
    ...frProUser?.updateProfileForm,
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    businessName: '',
    businessLegalName: '',
    businessNumber: '',
    einNumber: '',
  }

  return (
    <div>
      {saving ? (
        <FleetBusinessSummarySkeleton
          setSaving={setSaving}
          setSelection={setSelection}
          deleteBusinessSummaryData={deleteBusinessSummaryData}
          setToasts={setToasts}
        />
      ) : (
        <Formik
          initialValues={{
            businessName: frProUser?.updateProfileForm?.businessName
              ? frProUser?.updateProfileForm?.businessName
              : '',
            businessLegalName: frProUser?.updateProfileForm?.businessLegalName
              ? frProUser?.updateProfileForm?.businessLegalName
              : '',
            businessNumber: frProUser?.updateProfileForm?.businessNumber
              ? frProUser?.updateProfileForm?.businessNumber
              : '',
            einNumber: frProUser?.updateProfileForm?.einNumber
              ? frProUser?.updateProfileForm?.einNumber
              : '',
          }}
          validationSchema={Yup.object().shape({
            // firstname: Yup.string(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const data = {
                ...frProUser?.updateProfileForm,
                fre8ProAccessToken: frProUser?.fre8ProAccessToken,
                ...values,
              }
              console.log(data)
              ProfileApis.updateFleetOwnerUserProfile(data).then(() => {
                dispatch(
                  setFrProUser({
                    frProUser: {
                      ...frProUser,
                      updateProfileForm: {
                        ...frProUser?.updateProfileForm,
                        ...values,
                      },
                    },
                  })
                )
              })
              setSaving(true)
            } catch (err) {
              console.error(err)
              let customMessage = 'User Business summary update failed'
              let type = 'User Profile'
              let color = 'red'
              ErrorHandle(err, setToasts, customMessage, type, color)
            }
          }}
        >
          {({ errors, handleSubmit, touched, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                  <h5 className="fw-bold mb-3">Business summary</h5>
                  <div className="row">
                    <div className="col-md-7 ms-auto">
                      <div className="row g-4">
                        <div className="col-md-12">
                          <label className="form-label">
                            Business name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'businessName'}
                            maxLength={50}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="form-label">
                            Legal business name{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'businessLegalName'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12 col-lg-8">
                          <label className="form-label">
                            Business number{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'businessNumber'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12 col-lg-8">
                          <label className="form-label">
                            EIN number <span style={{ color: 'red' }}>*</span>
                          </label>
                          <InputField
                            type={'text'}
                            values={values}
                            // editMode={editMode}
                            name={'einNumber'}
                            touched={touched}
                            errors={errors}
                          />
                        </div>

                        <div className="mt-4">
                          <button
                            type={'submit'}
                            className="btn btn-theme me-2"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-border"
                            // data-bs-dismiss="modal"
                            onClick={() =>
                              frProUser?.updateProfileForm?.businessName
                                ? setSaving(true)
                                : setSelection('')
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      {toasts && <Toasts toastsList={toasts} />}
    </div>
  )
}

export default FleetBuisinessSummary

FleetBuisinessSummary.propTypes = {
  setSelection: PropTypes.func,
}
