import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //     en: {
    //         translation: {
    //             "menuItem_home": "Home",
    //             "menuItem_buddies": "Buddies",
    //             "menuItem_photos": "Photos",
    //             "menuItem_messages": "Messages",
    //             "menuItem_notifications": "Notifications"
    //         }
    //     },
    //     fr: {
    //         translation: {
    //             "menuItem_home": "Maison",
    //             "menuItem_buddies": "Copains",
    //             "menuItem_photos": "Galerie",
    //             "menuItem_messages": "Messages",
    //             "menuItem_notifications": "Notifications"
    //         }
    //     }
    // },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    detection: {
      order: [
        'cookie',
        'htmlTag',
        'localStorage',
        'sessionStorage',
        'navigator',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    backend: {
      loadPath: '/Assets/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
  })

function Internationalization({ children }) {
  //   const { t } = useTranslation();

  return children
}

export default Internationalization
