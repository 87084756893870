import PropTypes from 'prop-types'

// material-ui
import { Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const BusinessUserAccountCard = ({ user, loading = true }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={loading ? 'loading-skeleton col-lg-6' : 'col-lg-6'}>
        <div className="userInfo-section">
          <div className="userImg">
            <img src={user?.image} className="img-fluid" alt="" />
          </div>
          <div className="content">
            <div
              className="planbg"
              style={{
                background: `url("/Assets/Images/Latest/plan-star.png") no-repeat center center`,
                backgroundSize: 'cover',
              }}
            >
              <span>
                <b>Gold</b>
              </span>
            </div>
            <div className="cPlan">
              <Typography
                component={Link}
                to={'/membershipplan'}
                variant="h6"
                sx={{ textDecoration: 'none' }}
                color="textSecondary"
              >
                {t('change_plan')}
              </Typography>
            </div>
            <div className="title">{user?.fullName}</div>
            <div className="subTitle">{user?.company?.title}</div>
          </div>
        </div>
      </div>
      {/* <!-- userinfo section end --> */}
    </>
  )
}

BusinessUserAccountCard.propTypes = {
  loading: PropTypes.bool,
  user: PropTypes.object,
}

BusinessUserAccountCard.defaultProps = {
  color: 'primary',
}

export default BusinessUserAccountCard
