import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { useSelector } from 'react-redux'
import ErrorHandle from '../../Components/ErrorHandle'
import Toasts from '../../Components/Toasts/Toasts'

const SearchResultsImageCard = ({ item, addToFollowingList, addBuddyClicked }) => {
  const { frProUser } = useSelector(
    (state) => state.Users
  )

  const [hostProfileImg, setHostProfileImg] = useState('')
  const [targetUserFollowers, setTargetUserFollowers] = useState(false)
  const [profileImageLoading, setProfileImageLoading] = useState(false)
  const [toasts, setToasts] = useState(null)

  const getSingleProfileImage = () => {
    setProfileImageLoading(true)
    const userObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      userId: item?.userId,
    }
    ProfileApis.buddyProfileImage(userObj)
      .then((res) => {
        setHostProfileImg(`data:image/jpg;base64,  ${res}`)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setProfileImageLoading(false)
      })
  }

  const getFollowings = () => {
    const obj = {
      targetUserId: item?.userId,
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    }
    ProfileApis?.getTargetUserFollowersList(obj)
      .then((res) => {
        setTargetUserFollowers(res?.data[0]?.buddyRelationForm?.iamFollowing)
      })
      .catch((err) => {
        console.log(err)
        let customMessage = 'No followers found'
        let type = 'User followers'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
  }

  useEffect(() => {
    if (frProUser?.fre8ProAccessToken) {
      getSingleProfileImage();
      getFollowings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frProUser])

  return (
    <>
      <div className={`buddies-card placeholder-glow`}>
        <div className="rounded position-relative">
          <div className="position-absolute buddy-status">
            {item?.userCategory === 'Driver' && (
              <img src="images/notif-driver-b.svg" alt="img" />
            )}
            {item?.userCategory === 'Couch Provider' && (
              <img
                src="images/couch-provider-b.svg"
                alt="img"
              />
            )}
            {item?.userCategory === 'Fleet Owner' && (
              <img
                src="images/notif-fleet-owner.svg"
                alt="img"
              />
            )}
            {item?.userCategory === 'Fleet Business' && (
              <img
                src="images/notif-fleet-business.svg"
                alt="img"
              />
            )}
            {item?.userCategory === 'Own Operator' && (
              <img
                src="images/notif-owner-operator.svg"
                alt="img"
              />
            )}
          </div>
          {profileImageLoading ? <img
            src={'images/video_avatar.png'}
            className={`img-fluid w-100 mt-2 placeholder`}
            alt="img"
            style={{ minHeight: 157, minWidth: 157, borderRadius: '100%' }}
          /> : <img
            src={hostProfileImg}
            className={`img-fluid w-100 mt-2 ${hostProfileImg === null ||
              hostProfileImg === undefined
              ? 'placeholder'
              : ''
              }`}
            alt="img"
            style={{ minHeight: 157, minWidth: 157 }}
          />}
        </div>
        <h3>
          {item?.businessName || item?.fullNameOfTheUser}
        </h3>
        <div className="buddies-tagline iffyTip hideText2">
          {item?.profileTagLine}
        </div>

        {targetUserFollowers ? (
          <a
            href="#"
            className="btn w-100 btn-light-theme mt-3 following-btn"
          >
            Following
          </a>
        ) : (
          <a
            href="#"
            className="btn w-100 btn-light-theme mt-3 follow-btn"
            name={item?.userId}
            onClick={async () => {
              await addToFollowingList(item?.userId)
              getFollowings();
            }}
          >
            Follow
          </a>
        )}

        {item?.buddyStatus === 'sent' && (
          <a
            href="#"
            className="btn w-100 btn-light-theme mt-3 btn-requested"
          >
            Requested
          </a>
        )}

        {(item?.buddyStatus === 'none' ||
          item?.buddyStatus === 'deny' ||
          item?.buddyStatus === 'unbuddy' ||
          item?.buddyStatus === null ||
          item?.buddyStatus === undefined) && (
            <a
              href="#"
              className="btn w-100 btn-light-theme mt-3 add-buddies"
              name={item?.jabberId}
              onClick={addBuddyClicked}
            >
              Add Buddies
            </a>
          )}
        {item?.buddyStatus === 'buddies' && (
          <a
            href="#"
            className="btn w-100 btn-light-theme mt-3"
          >
            Buddies
          </a>
        )}
      </div>
      {toasts && <Toasts toastsList={toasts} />}
    </>
  )
}

export default SearchResultsImageCard
SearchResultsImageCard.propTypes = {
  item: PropTypes.any,
  addBuddyClicked: PropTypes.func,
  addToFollowingList: PropTypes.func
}