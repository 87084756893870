import React from 'react'
import { ProfileApis } from '../../../../API/Login/ProfileApi'
import { useDispatch, useSelector } from 'react-redux'
import { setFrProUser } from '../../../../store/reducers/Users/users'
import propTypes from 'prop-types'
import CustomPencilSvg from '../../../../Components/CustomPencilSvg/CustomPencilSvg'
import CustomDeleteSvg from '../../../../Components/CustomDeleteSvg/CustomDeleteSvg'
import ErrorHandle from '../../../../Components/ErrorHandle'

const FleetServicesView = ({ setSaving, setSelection, setToasts }) => {
  const dispatch = useDispatch()
  const { frProUser } = useSelector((state) => state.Users)

  const deleteFleetServiceData = {
    ...frProUser?.updateProfileForm,
    fre8ProAccessToken: frProUser?.fre8ProAccessToken,
    wareHouseServiceFlag: '',
    serviceOfferred: [],
    partOfLogisticsString: '',
    fleetSegment: '',
    fleetType: '',
    serviceList: [],
  }
  return (
    <div className="mb-4  shadow-sm rounded-4 bg-white">
      <div className="d-flex justify-content-between px-4 pt-4">
        <h5 className="fw-bold">Services offered</h5>
        <div>
          <button onClick={() => setSaving(false)} className=" p-0">
            <CustomPencilSvg />
          </button>
          <button
            onClick={() => {
              ProfileApis.updateFleetOwnerUserProfile(deleteFleetServiceData)
                .then(() => {
                  dispatch(
                    setFrProUser({
                      frProUser: {
                        ...frProUser,
                        updateProfileForm: {
                          ...frProUser?.updateProfileForm,
                          ...deleteFleetServiceData,
                        },
                      },
                    })
                  )
                })
                .catch((err) => {
                  console.log(err)
                  let customMessage = 'User  Fleet Serives delete failed'
                  let type = 'User Profile'
                  let color = 'red'
                  ErrorHandle(err, setToasts, customMessage, type, color)
                })
              setSelection('')
            }}
          >
            <CustomDeleteSvg />
          </button>
        </div>
      </div>

      <div className="p-4 pt-0">
        <dl className="row business_list mb-0 gy-2">
          {frProUser?.updateProfileForm?.wareHouseServiceFlag === 'Yes' && (
            <>
              <dt className="col-sm-3"> Warehousing services </dt>
              <dd
                className="col-sm-9"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                {frProUser?.updateProfileForm?.serviceOfferred !== ''
                  ? frProUser?.updateProfileForm?.serviceOfferred?.map(
                    (item, inx) => <span key={inx}>{item}</span>
                  )
                  : []}
              </dd>
            </>
          )}

          {frProUser?.updateProfileForm?.partOfLogisticsString && (
            <>
              <dt className="col-sm-3">
                Logistics and transportation category
              </dt>
              <dd className="col-sm-9"> Manufacturing and processing</dd>
            </>
          )}
          <dt className="col-sm-3">Fleet Segment</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.fleetSegment}
          </dd>
          <dt className="col-sm-3">Fleet type</dt>
          <dd className="col-sm-9">
            {frProUser?.updateProfileForm?.fleetType}
          </dd>
          <dt className="col-sm-3">Related services</dt>
          <dd
            className="col-sm-9"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            {frProUser?.updateProfileForm?.serviceList !== ''
              ? frProUser?.updateProfileForm?.serviceList?.map((item, inx) => (
                <span key={inx}>{item}</span>
              ))
              : []}
          </dd>

          <dl></dl>
        </dl>
      </div>
    </div>
  )
}

export default FleetServicesView
FleetServicesView.propTypes = {
  setSelection: propTypes.func,
  setSaving: propTypes.func,
  setToasts: propTypes.func,
}
