import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LoginApis } from '../../API/Login/LoginApi'
import Loader from '../../Components/Loader/Loader'
import Toasts from '../../Components/Toasts/Toasts'
import { setLoggedInUserBuddiesList, setSearchFriends } from '../../store/reducers/Users/users'
import { dispatch } from '../../store/reducers/persist'
import { ProfileApis } from '../../API/Login/ProfileApi'
import { StropheContext } from '../ChatClient'
import { UsersApis } from '../../API/Login/UsersApi'
import ErrorHandle from '../../Components/ErrorHandle'
import SearchResultsImageCard from './SearchResultsImageCard'
const SearchResults = (props) => {
  const searchData = localStorage.getItem("search");

  const { frProUser, buddyList } = useSelector(
    (state) => state.Users
  )
  const stropheContext = useContext(StropheContext)
  const { state } = useLocation()
  const [showLoading, setshowLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [friendsList, setfriendsList] = React.useState([])
  const [sendReqStatus, setsendReqStatus] = useState(false)
  const [toasts, setToasts] = useState(null)

  const SearchDataList = () => {
    setfriendsList([]);
    let searchFriendObj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      searchText: searchData,
      searchType: 'buddy',
    }
    LoginApis.searchFriend(searchFriendObj)
      .then((res) => {
        setfriendsList(res?.data?.userList)
      })
      .catch((err) => {
        console.log('Search::Error seach friend', err)
        let customMessage = 'Search request not found'
        let type = 'Search'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
      .finally(() => {
        setshowLoading(false)
      })
  }

  useEffect(() => {
    SearchDataList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData])

  const addBuddyClicked = (event) => {
    let item = [
      {
        name: event?.currentTarget?.name,
        type: 'Request sent',
      },
    ]

    setToasts(item)

    let obj = {
      fre8ProAccessToken: frProUser?.fre8ProAccessToken,
      requestToUser: event?.currentTarget?.name?.split('@')[0],
      requestTime: new Date().toISOString(),
    }
    let buddyObj = {
      jid: event?.currentTarget?.name,
      type: 'subscribe',
    }

    UsersApis.userBuddyRequestSend(obj)
      .then((res) => {
        const buddyListNew = [...buddyList, buddyObj]
        SearchDataList();
        setTimeout(() => {
          dispatch(
            setLoggedInUserBuddiesList({ buddiesList: buddyListNew || [] })
          )
        }, 1000)
      })
      .catch((err) => {
        let customMessage = 'Buddy request send failed'
        let type = 'User Profile'
        let color = 'red'
        ErrorHandle(err, setToasts, customMessage, type, color)
      })
    stropheContext?.subscribePresence(event?.currentTarget?.name)
    setsendReqStatus(true)
    setTimeout(() => {
      setsendReqStatus(false)
      setRefresh(!refresh)
    }, 2000)
  }

  React.useEffect(() => {
    let promiseObjToLoadImage = friendsList?.map((friend) => {
      let data = loadImage(friend)

      friend = data
      // frndList.push(data);
      return data
    })
    setTimeout(() => {
      Promise.all(promiseObjToLoadImage)
        .then((res) => {
          if (res) {
            dispatch(
              setSearchFriends({
                searchFriendsResults: res,
              })
            )
          }
        })
        .catch((err) => {
          if (!err?.response?.data?.success) {
            // let item = [
            //   {
            //     name: err?.response?.data?.errorDetail?.developerMessage,
            //     type: err?.response?.data?.errorDetail?.errorType,
            //   },
            // ];
            // setShowSuccess(true)
            // setToasts(item);
            // setTimeout(() => {
            //   navigate('/')
            // }, 4000);
          }
        })

    }, 1000)
  }, []) //eslint-disable-line

  const loadImage = (friendVal) => {
    let loogedInUser = JSON.parse(localStorage.getItem('user'))
    return new Promise((resolve, reject) => {
      let friend = friendVal
      let objSearchImg = {
        url: friend?.userProfileImgUrl
          ?.replace('{userId}', friend?.userId)
          .replace('//', '/'),
        accessToken: {
          fre8ProAccessToken:
            frProUser?.fre8ProAccessToken || loogedInUser?.fre8ProAccessToken,
        },
      }
      LoginApis.fetchsearchFriendImage(objSearchImg)
        .then((res) => {

          let imageData = {
            userProfileImgUrlData: `data:image/jpg;base64, ${res}`,
          }
          let merged = { ...friend, ...imageData }
          resolve(merged)

        })
        .catch((err) => {
          resolve(err?.developerMessage)
        })
    })
  }

  const addToFollowingList = async (event) => {
    try {
      let otherUser = friendsList.filter((item) => {
        return item?.userId === event
      })
      let obj = {
        fre8ProAccessToken: frProUser?.fre8ProAccessToken,
        requestToUser: otherUser && otherUser[0]?.userId,
        requestTime: new Date().toISOString(),
      }
      await ProfileApis.sendUserFollowRequest(obj)
        .then((res) => {
          let item = [
            {
              name: event?.currentTarget?.name,
              type: 'Following started ',
            },
          ]
          setshowLoading(true)
          SearchDataList();
          setToasts(item)
          stropheContext?.subscribePresence(event?.currentTarget?.name)
          setsendReqStatus(true)
          setTimeout(() => {
            setshowLoading(false)
            // props?.setshowSearchResults(false)
            setsendReqStatus(false)
          }, 2000)
        })
        .catch((err) => {
          // console.log("Response ", res)
          let item = [
            {
              name: err?.response?.data?.errorDetail?.developerMessage,
              type: err?.response?.data?.errorDetail?.errorType,
              color: 'red',
            },
          ]
          setshowLoading(true)
          setToasts(item)
          stropheContext?.subscribePresence(event?.currentTarget?.name)
          setsendReqStatus(true)
          setTimeout(() => {
            setshowLoading(false)
            // props?.setshowSearchResults(false)
            // navigate('/')
            setsendReqStatus(false)
          }, 2000)
          // return err
        })
    } catch (error) {
      console.log('------------error---------', error)
    }
  }

  return (
    <>
      <main className="main users chart-page" id="skip-target">
        <div className="container-fluid p-lg-4">
          <div className="p-0 mb-4">
            <h2 className="main-title">
              Search Results For {state?.searchFriend}
            </h2>
          </div>

          {showLoading && <Loader />}
          <div className="row">
            <div className="col-md-3">
              <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                <div className="Filters">
                  <div className="filterheading">Filters</div>
                  <p>Currently Showing all results</p>
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="applied">1 filter applied</div>
                    <button
                      type="button"
                      className="btn Clear border-0 fw-bold"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="check">
                    <h4>My network</h4>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        My buddies
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        People I follow
                      </label>
                    </div>
                  </div>
                  <div className="check">
                    <h4>People</h4>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Drivers
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck4"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck4"
                      >
                        Owner operators
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Business-owners"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Business-owners"
                      >
                        Business owners
                      </label>
                    </div>
                  </div>
                  <div className="check">
                    <h4>Business</h4>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck5"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck5"
                      >
                        Fleet business
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck6"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck6"
                      >
                        Couch provider
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck7"
                      >
                        Eatery
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck8"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck8"
                      >
                        Fuel supplier
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck9"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck9"
                      >
                        Event organiser
                      </label>
                    </div>
                  </div>
                  <div className="check">
                    <h4>Fr8 Pro category</h4>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck10"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck10"
                      >
                        Posts
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck11"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck11"
                      >
                        Jobs
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck12"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck12"
                      >
                        Meetup events
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck13"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck13"
                      >
                        LMS courses
                      </label>
                    </div>
                    <div className="form-check mt-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck14"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck14"
                      >
                        Couch services
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="box-2">
                <div className="filterheadung-2 mb-2">
                  {friendsList?.length || 0} results found
                </div>
                {friendsList?.length === 0 && (
                  <div className="filterheadung-2 mb-2">
                    0 results found, try again or,
                    <span className="clear">clear your filters</span>
                  </div>
                )}
                <div className="People">
                  <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                    <p className="text-center heading-according-to-info">
                      People and businesses
                    </p>
                    <div className="row g-3">
                      {friendsList?.map((item, i) => (
                        <div className="col-sm-6 col-md-4 col-6" key={i}>
                          <SearchResultsImageCard item={item} addBuddyClicked={addBuddyClicked} addToFollowingList={addToFollowingList} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sendReqStatus && <Toasts toastsList={toasts} />}
      </main>
    </>
  )
}

export default SearchResults
