import React from 'react'
// import { Formik } from "formik";
import PropTypes from 'prop-types'

const FleetWareHouses = ({ setSelection }) => {
  return (
    <div>
      <div className="row">
        <button className="back-btn text-start" id="backwareHouse">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
              stroke="#667085"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{' '}
          Go back
        </button>
        <h5 className="fw-bold my-4">Add a WareHouse</h5>
        <div className="row">
          <div className="col-lg-5 col-md-12 mx-auto">
            <h4 className="fw-4 my-4">How it work</h4>
            <ol className="custom-counter">
              <li>
                <h6>Download the wareHouses template</h6>
                <p>
                  You can download the{' '}
                  <button className="underline p-0">template here,</button> it
                  will be in the form of a spreadsheet.
                </p>
              </li>
              <li>
                <h6>Populate template with location of wareHouses. </h6>
                <p>
                  This includes first line of address, city, state and country.
                  Maximum 1000 wareHouses can be added.{' '}
                </p>
              </li>
              <li>
                <h6>Save and upload your spreadsheet here</h6>
                <p>
                  Once the spreadsheet is completed, save it as .CSV and upload
                  it here.{' '}
                </p>
              </li>
              <li>
                <h6>We’ll populate your wareHouses on a map</h6>
                <p>
                  Once upload is done, easily search and view the locations of
                  wareHouses on a map
                </p>
              </li>
            </ol>

            <h4 className=" my-4 font16">Upload .CSV</h4>
            <button className="border  p-3 rounded-3 text-center w-100">
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="3"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#F2F4F7"
                ></rect>
                <rect
                  x="3"
                  y="3"
                  width="40"
                  height="40"
                  rx="20"
                  stroke="#F9FAFB"
                  strokeWidth="6"
                ></rect>
                <g clipPath="url(#clip0_2236_1419)">
                  <path
                    d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                    stroke="#475467"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_2236_1419">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(13 13)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>

              <p className="mb-1">
                <strong>Click to upload</strong> or drag and drop
              </p>
              <p className="mb-0 small">.CSV. Max 5MB file size.</p>
            </button>
            <div className=" border  d-flex my-3 p-3 rounded-3 mb-3">
              <div className="me-3">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="32"
                    height="32"
                    rx="16"
                    fill="#B3F0FF"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="32"
                    height="32"
                    rx="16"
                    stroke="#E6FAFF"
                    strokeWidth="4"
                  />
                  <path
                    d="M18.6665 11.333H13.9998C13.6462 11.333 13.3071 11.4735 13.057 11.7235C12.807 11.9736 12.6665 12.3127 12.6665 12.6663V23.333C12.6665 23.6866 12.807 24.0258 13.057 24.2758C13.3071 24.5259 13.6462 24.6663 13.9998 24.6663H21.9998C22.3535 24.6663 22.6926 24.5259 22.9426 24.2758C23.1927 24.0258 23.3332 23.6866 23.3332 23.333V15.9997M18.6665 11.333L23.3332 15.9997M18.6665 11.333V15.9997H23.3332"
                    stroke="#054757"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="flex-grow-1">
                <div className="fw-medium mb-1 text-dark ">
                  wareHouseslocations.csv
                </div>
                <p className="mb-2 text-muted">200 KB</p>
                <div className="align-items-center d-flex">
                  <div
                    className="progress w-100"
                    style={{ height: '8px' }}
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: '50%',
                        backgroundColor: '#054757',
                      }}
                    ></div>
                  </div>
                  <span className="ms-2 small">40%</span>
                </div>
              </div>
              <div>
                <button className=" p-0">
                  <img src="images/verif-done.svg" alt="" />
                </button>
              </div>
            </div>
            <button
              onClick={() => setSelection('wareHouseAdded')}
              className="btn btn-theme w-100 mt-4"
            >
              Continue to upload
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FleetWareHouses

FleetWareHouses.propTypes = {
  user: PropTypes.object,
  setCategory: PropTypes.string,
  setSelection: PropTypes.string,
}
