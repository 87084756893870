import React from 'react'

const FleetWareHousesAdded = () => {
  return (
    <div>
      <div className="tab-panex">
        <div className="row" id="warehousesAdded">
          <h5 className="fw-bold my-4">999 warehouses available</h5>
          <div className="col-md-5 mb-0 mb-lg-0">
            <div className="mb-4 p-4 shadow-sm rounded-0 bg-white  h-100">
              <div className="position-relative">
                <span className="ms-3 position-absolute top-50 translate-middle-y">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control ps-5"
                />
              </div>

              <ul className="mt-2 text-dark">
                <li className="d-flex justify-content-between p-2">
                  warehouse 1
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#083945"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
                <li className="d-flex justify-content-between p-2">
                  warehouse 2
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#083945"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
                <li className="d-flex justify-content-between p-2">
                  warehouse 3
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#083945"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <img src="images/map.jpg" className="w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FleetWareHousesAdded
