import React from 'react'
import PropTypes from 'prop-types'
import FleetWareHouses from './FleetWareHouses'
import FleetWareHousesAdded from './FleetWareHousesAdded'
import FleetTerminalsAdded from './FleetTerminalsAdded'
import FleetTerminal from './FleetTerminal'
import { useSelector } from 'react-redux'

const FleetTerminalsAndWareHouses = ({ setSelection, select, changeTabs }) => {
  const { frProUser } = useSelector((state) => state.Users)
  return (
    <>
      {changeTabs === 'TerminalsAndWareHousing' && (
        <div>
          <div className=" row">
            <div className="col-md-12">
              {/* <button className="p-0 mb-3">
                <img src="images/back-profile-button.svg" />
              </button> */}
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-Profile"
                  role="tabpanel"
                  aria-labelledby="v-Profile-tab"
                  tabIndex="0"
                >
                  <div className="mb-4 p-4 shadow-sm rounded-4 bg-white">
                    <div className="d-flex justify-content-between  mb-3">
                      <ul
                        className="nav nav-pills vehicles-group"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link bg-white active"
                            id="v-Business"
                            data-bs-toggle="pill"
                            href="#home"
                            aria-selected="true"
                            role="tab"
                            // autoC
                            onClick={() =>
                              frProUser?.updateProfileForm?.terminal?.length ===
                              0
                                ? setSelection('Terminals')
                                : setSelection('terminalAdded')
                            }
                          >
                            Terminals
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link bg-white "
                            id="vehicles-b"
                            data-bs-toggle="pill"
                            href="#profile"
                            aria-selected="false"
                            tabIndex="-1"
                            role="tab"
                            onClick={() => setSelection('WareHouses')}
                          >
                            Warehouses
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* Terminals */}

                    {(select === 'Terminals' ||
                      select === 'showTerminal' ||
                      select === 'terminalAdded') && (
                      <div className="tab-content mt-2">
                        <div className="tab-pane fade show active" id="home">
                          <div className="text-end">
                            <button
                              className="btn btn-theme mtm"
                              id="addterminals"
                              onClick={() => setSelection('showTerminal')}
                            >
                              Add terminals
                            </button>
                            <h5
                              className="fw-bold mb-3 text-start"
                              id="onterminalsAdded"
                            >
                              {frProUser?.updateProfileForm?.terminal?.length}{' '}
                              terminals available
                            </h5>
                          </div>
                          {select === 'showTerminal' && (
                            <FleetTerminal setSelection={setSelection} />
                          )}
                          {select === 'terminalAdded' && (
                            <FleetTerminalsAdded />
                          )}
                        </div>
                      </div>
                    )}
                    {/* Terminals End */}

                    {/* WareHouses */}
                    {(select === 'WareHouses' ||
                      select === 'showWareHouse' ||
                      select === 'wareHouseAdded') && (
                      <div className="tab-content mt-2">
                        <div className="tab-pane fade show active" id="home">
                          <div className="text-end">
                            <button
                              className="btn btn-theme mtm"
                              id="addterminals"
                              onClick={() => setSelection('showWareHouse')}
                            >
                              Add WareHouses
                            </button>
                            <h5
                              className="fw-bold mb-3 text-start"
                              id="onterminalsAdded"
                            >
                              0 WareHouses available
                            </h5>
                          </div>
                          {select === 'showWareHouse' && (
                            <FleetWareHouses setSelection={setSelection} />
                          )}
                          {select === 'wareHouseAdded' && (
                            <FleetWareHousesAdded />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FleetTerminalsAndWareHouses
FleetTerminalsAndWareHouses.propTypes = {
  setSelection: PropTypes.func,
  select: PropTypes.string,
  changeTabs: PropTypes.string,
}
