import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Buddies from './Buddies/buddies'
import Events from './Events/events'
import Followers from './Followers/Followers'
import Following from './Following/Following'
import GeneralData from './GenericData/generalData'
import Posts from './Posts/posts'
import Users from './Users/users'
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  Followers: Followers,
  Following: Following,
  Buddies: Buddies,
  Posts: Posts,
  Users: Users,
  GeneralData: GeneralData,
  Events: Events,
})
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: window.location.href.includes('http://localhost') ? true : false,
})
const { dispatch } = store
// store.dispatch({
//     type: "persist/REHYDRATE",
//   });
const persiststore = persistStore(store)

export { dispatch, persiststore }
